<template>
  <div class="groups">
    <CommonComponentHeader title="Группы" :items="breadcrumbsItems" />

    <div class="groups__header">
      <CommonButton
        label="Новая группа"
        @click="showCreateGroup = true"
        color-type="green-light"
      />
    </div>

    <div class="groups__container">
      <CommonTable
        :actions="actions"
        :head-list="headList"
        :body-list="bodyList"
        @edit="editLesson"
        @statements="goToCreateLecture"
        @archive="deleteLesson"
      />
    </div>

    <ModalCreateGroup v-model="showCreateGroup" />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";

import { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { ModalCreateGroup } from "@/modals/ModalCreateGroup";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "GroupsPage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonTable,
    ModalCreateGroup,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const programId = computed(() => route.query?.program_id);

    const showCreateGroup = ref<boolean>(false);
    const showChat = ref<boolean>(false);

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const themeName = ref<string>(
      "Общественное здоровье и здравоохранение (Б1.О.1)"
    );

    const actions = [
      "statements",
      "attendance",
      "manage-participants",
      "participants",
      "edit",
      "delete",
    ];

    const headList = reactive([
      "Название",
      "Программа",
      "Курс / Семестр",
      "Участники",
      "Дата начала",
      "Дата окончания",
      "Статус",
      "Действия",
    ]);

    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "Группа 1 (МиКМ-1-24)" },
        { title: "Математическое и компьютерное моделирование" },
        { title: "1 курс / 1 семестр" },
        { title: "25" },
        { title: "10.01.2024" },
        { title: "20.04.2025" },
        { title: "Активная", hasStatus: true, statusColor: "green" },
        {
          title: "",
          hasAction: true,
          id: 1,
        },
      ],
      [
        { title: "Группа 2" },
        { title: "Математическое и компьютерное моделирование" },
        { title: "1 курс / 2 семестр" },
        { title: "30" },
        { title: "10.01.2024" },
        { title: "20.04.2025" },
        { title: "Неактивная", hasStatus: true, statusColor: "red" },
        {
          title: "",
          hasAction: true,
          id: 2,
        },
      ],
      [
        { title: "Группа 3" },
        { title: "Математическое и компьютерное моделирование" },
        { title: "2 курс / 1 семестр" },
        { title: "20" },
        { title: "10.01.2024" },
        { title: "20.04.2025" },
        { title: "Идет набор", hasStatus: true, statusColor: "orange" },
        {
          title: "",
          hasAction: true,
          id: 3,
        },
      ],
      [
        { title: "Группа 4" },
        { title: "Математическое и компьютерное моделирование" },
        { title: "2 курс / 2 семестр" },
        { title: "18" },
        { title: "10.01.2024" },
        { title: "20.04.2025" },
        { title: "Активная", hasStatus: true, statusColor: "green" },
        {
          title: "",
          hasAction: true,
          id: 4,
        },
      ],
      [
        { title: "Группа 5" },
        { title: "Математическое и компьютерное моделирование" },
        { title: "3 курс / 1 семестр" },
        { title: "28" },
        { title: "10.01.2024" },
        { title: "20.04.2025" },
        { title: "Активная", hasStatus: true, statusColor: "green" },
        {
          title: "",
          hasAction: true,
          id: 5,
        },
      ],
    ]);

    const goToCreateLecture = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE_CREATE,
      });

    const editLesson = (id: number) => {
      console.log(`Редактирование аттестации с id: ${id}`);
    };

    const deleteLesson = (id: number) => {
      console.log(`Удаление аттестации с id: ${id}`);
    };

    const opneLessonChat = () => {
      showChat.value = true;
    };

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value)
    );

    return {
      actions,
      breadcrumbsItems,
      programId,
      headList,
      bodyList,
      programName,
      themeName,
      showCreateGroup,
      showChat,
      editLesson,
      deleteLesson,
      opneLessonChat,
      goToCreateLecture,
    };
  },
});
</script>

<style scoped lang="stylus">
.groups {
  &__header {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
  }
}
</style>
