import { RouteName } from "@/constants";

export const generateBreadcrumbs = (
  programName: string,
  groupName: string,
  userName: string
) => [
  { title: "Программы", to: { name: RouteName.CURATOR_PROGRAMS } },
  { title: programName },
  {
    title: "Группы",
    to: { name: RouteName.CURATOR_PROGRAMS_GROUPS },
  },
  {
    title: groupName,
    to: { name: RouteName.CURATOR_PROGRAMS_GROUPS },
  },
  { title: userName },
  { title: "Тестирования" },
];
