import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-285efdb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "groups" }
const _hoisted_2 = { class: "groups__header" }
const _hoisted_3 = { class: "groups__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_CommonTable = _resolveComponent("CommonTable")!
  const _component_ModalCreateGroup = _resolveComponent("ModalCreateGroup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonComponentHeader, {
      title: "Группы",
      items: _ctx.breadcrumbsItems
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CommonButton, {
        label: "Новая группа",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showCreateGroup = true)),
        "color-type": "green-light"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CommonTable, {
        actions: _ctx.actions,
        "head-list": _ctx.headList,
        "body-list": _ctx.bodyList,
        onEdit: _ctx.editLesson,
        onStatements: _ctx.goToCreateLecture,
        onArchive: _ctx.deleteLesson
      }, null, 8, ["actions", "head-list", "body-list", "onEdit", "onStatements", "onArchive"])
    ]),
    _createVNode(_component_ModalCreateGroup, {
      modelValue: _ctx.showCreateGroup,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showCreateGroup) = $event))
    }, null, 8, ["modelValue"])
  ]))
}