<template>
  <CommonFormContainer title="Опыт работы">
    <div v-for="(form, index) in forms" :key="index" class="form-wrapper">
      <CommonSelect
        label="Место работы"
        v-model="form.work_place"
        :options="jobOptions"
        placeholder="Выберите место"
        @update:model-value="$emit('updateValues', forms)"
      />

      <CommonSelect
        label="Должность"
        v-model="form.post"
        :options="postOptions"
        placeholder="Выберите должность"
        @update:model-value="$emit('updateValues', forms)"
      />

      <div class="form-container">
        <CommonDatePicker
          label="Начало работы"
          v-model="form.date_from"
          placeholder="00/00/000"
        />

        <CommonDatePicker
          label="Окончание работы"
          v-model="form.date_to"
          placeholder="00/00/000"
        />
      </div>

      <button
        class="form-button-delete"
        type="button"
        @click="removeForm(index)"
      >
        Удалить
      </button>
      <button class="form-button-plus" type="button" @click="addForm">
        <img src="./icons/plus.svg" />
      </button>
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonDatePicker } from "@/components/NewComponents/CommonDatePicker";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";

export default defineComponent({
  name: "ExperienceForm",

  emits: ["updateValues"],

  components: {
    CommonFormContainer,
    CommonDatePicker,
    CommonSelect,
  },

  setup(props, { emit }) {
    const forms = ref([
      {
        work_place: "",
        post: "",
        date_from: "",
        date_to: "",
      },
    ]);

    const addForm = () => {
      forms.value.push({
        work_place: "",
        post: "",
        date_from: "",
        date_to: "",
      });
      emit("updateValues", forms.value);
    };

    const removeForm = (index: number) => {
      forms.value.splice(index, 1);
      emit("updateValues", forms.value);
    };

    const jobOptions = reactive<string[]>([]);
    const postOptions = reactive<string[]>([]);

    return {
      forms,
      jobOptions,
      postOptions,
      addForm,
      removeForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.form-button-delete {
  width: 64px;
  getFontSmall();
  color: $as-dark-grey;
  line-height: 20px;
  font-weight: 500;
}

.form-button-plus {
  width: 24px;

  img {
    width: 24px;
    height: 24px;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
