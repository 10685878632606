import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2d845fa6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "lecture" }
const _hoisted_2 = { class: "lecture__container" }
const _hoisted_3 = { class: "lecture__container__section" }
const _hoisted_4 = { class: "lecture__container__section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_FormDescription = _resolveComponent("FormDescription")!
  const _component_FormVideoTranscription = _resolveComponent("FormVideoTranscription")!
  const _component_FormVideo = _resolveComponent("FormVideo")!
  const _component_CommonFormContainer = _resolveComponent("CommonFormContainer")!
  const _component_FormMaterial = _resolveComponent("FormMaterial")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_CommonChat = _resolveComponent("CommonChat")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonComponentHeader, {
      title: "Создание новой темы/лекции",
      items: _ctx.breadcrumbsItems
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FormDescription),
        _createVNode(_component_FormVideoTranscription),
        _createVNode(_component_CommonFormContainer, { title: "123" }, {
          default: _withCtx(() => [
            _createVNode(_component_FormVideo)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FormMaterial, {
          onOpenChat: _ctx.openChat,
          title: "Презентации"
        }, null, 8, ["onOpenChat"]),
        _createVNode(_component_FormMaterial, {
          onOpenChat: _ctx.openChat,
          title: "Дополнительные материалы"
        }, null, 8, ["onOpenChat"]),
        _createVNode(_component_FormMaterial, {
          onOpenChat: _ctx.openChat,
          title: "Литература"
        }, null, 8, ["onOpenChat"])
      ])
    ]),
    _createVNode(_component_CommonButton, {
      label: "Сохранить",
      "color-type": "green-light"
    }),
    _createVNode(_component_CommonModal, {
      modelValue: _ctx.showChat,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showChat) = $event)),
      title: "КОММЕНТАРИИ"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CommonChat, {
          chat: _ctx.testChatData,
          title: "Домашнее задание №1"
        }, null, 8, ["chat"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}