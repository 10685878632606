<template>
  <CommonFormContainer title="Транскрибация видео">
    <CommonFileUpload
      label="Файл"
      button-text="Выберите файл"
      v-model="formModel.file"
      background-color="#fff"
      height="40px"
    />
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, watch, reactive } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonFileUpload } from "@/components/NewComponents/CommonFileUpload";

export default defineComponent({
  name: "FormVideoTranscription",

  emits: ["updateValues"],

  props: ["form"],

  components: {
    CommonFormContainer,
    CommonFileUpload,
  },

  setup(props) {
    const formModel = reactive({
      file: [],
    });

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
