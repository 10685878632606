<template>
  <CommonFormContainer title="Прикрепленные файлы">
    <CommonFileUpload v-model="formModel.files" background-color="#fff" />
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonFileUpload } from "@/components/NewComponents/CommonFileUpload";

export default defineComponent({
  name: "FilesForm",

  emits: ["updateValues"],

  components: {
    CommonFormContainer,
    CommonFileUpload,
  },

  setup() {
    const formModel = reactive({
      files: [],
    });

    return {
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
