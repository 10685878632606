<template>
  <CommonComponentHeader title="Календарный план" :items="breadcrumbsItems" />

  <div class="calendarPlan__wrap">
    <div class="calendarPlan__scroll-content">
      <div class="calendarPlan__container">
        <table class="calendarPlan">
          <thead>
            <tr>
              <th colspan="100" class="calendarPlan__titleCol">
                Календарный план
              </th>
            </tr>

            <tr class="calendarPlan__head">
              <th scope="col" rowspan="2">№ п/п</th>

              <th scope="col" rowspan="2">Наименование программы</th>

              <th scope="col" rowspan="2">Кол-во часов</th>

              <th
                scope="col"
                :colspan="item.list.length"
                v-for="item in tableHeadList"
                :key="item.title"
              >
                {{ item.title }}
              </th>
            </tr>

            <template v-for="item in tableHeadList" :key="item.title">
              <th
                scope="col"
                v-for="(elem, i) in item.list"
                :key="`table-head-${i}`"
              >
                {{ elem }}
              </th>
            </template>
          </thead>

          <tbody>
            <template
              v-for="(item, indx) in tableBodyList"
              :key="`table-body-item-${indx}`"
            >
              <tr>
                <th colspan="100" class="calendarPlan__titleCol">
                  {{ item.title }}
                </th>
              </tr>

              <tr
                v-for="(elem, i) in item.list"
                :key="`table-body-item-${indx}-${i}`"
              >
                <th
                  scope="col"
                  :colspan="
                    (typeof col === 'object' && Number(col?.colWidth)) || 1
                  "
                  v-for="(col, index) in elem"
                  :key="`col-item-${indx}-${i}-${index}`"
                  :style="
                    typeof col === 'object' && { backgroundColor: col.color }
                  "
                >
                  {{ typeof col === "object" ? col.title : col }}
                </th>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="scroll-left">
      <svg class="scroll-icon">
        <use :href="`#arrow-scroll-left--sprite`" />
      </svg>
    </div>
    <div class="scroll-right">
      <svg class="scroll-icon">
        <use :href="`#arrow-scroll-right--sprite`" />
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { breadcrumbsItems, tableHeadList, tableBodyList } from "./constants";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";

import "@/assets/img/icons/arrow-scroll-left.svg?sprite";
import "@/assets/img/icons/arrow-scroll-right.svg?sprite";

export default defineComponent({
  name: "CalendarPlanPage",

  components: {
    CommonComponentHeader,
  },

  setup() {
    const columnNumber = ref(0);

    onMounted(() => {
      const table = document.querySelector(".calendarPlan") as HTMLTableElement;

      const scrollContainer = document.querySelector(
        ".calendarPlan__scroll-content"
      );
      const scrollLeft = document.querySelector(".scroll-left");
      const scrollRight = document.querySelector(".scroll-right");

      scrollLeft?.addEventListener("click", () => {
        scrollContainer?.scrollBy({ left: -50, behavior: "smooth" });
      });

      scrollRight?.addEventListener("click", () => {
        scrollContainer?.scrollBy({ left: 50, behavior: "smooth" });
      });

      columnNumber.value =
        table?.rows[table?.rows.length - 1]?.childNodes?.length;
      console.log(table?.rows);
    });

    return {
      breadcrumbsItems,
      tableHeadList,
      tableBodyList,
      columnNumber,
    };
  },
});
</script>

<style lang="stylus" scoped>
.calendarPlan {
  width: 100%;
  border-radius: 10px;

  &__wrap {
    position: relative;
    overflow: hidden;
    margin: 20px;
    padding: 24px;
    background-color: $color-white;
    border: 1px solid $magistracy-medium-grey;
    border-radius: 10px;
  }

  &__scroll-content {
    position: relative;
    overflow: auto;
    padding-bottom: 16px;

    &::-webkit-scrollbar {
        height: 16px; /* Ширина полосы прокрутки */
    }

    &::-webkit-scrollbar-thumb {
        background: $magistracy-medium-grey; /* Цвет полосы прокрутки */
        border-radius: 0; /* Убираем закругления */
    }

    &::-webkit-scrollbar-track {
        background: $as-light-grey; /* Цвет фона полосы прокрутки */
    }
  }

  &__container {
    position: relative;
    border-radius: 10px;
    width: fit-content;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 10px;
        border: 1px solid #ccc;
        pointer-events: none;
    }
  }

  &__titleCol {
    background-color: $as-light-grey;
    border-right: none;
    font-weight: 500;
  }

  &__head {
    text-transform: uppercase;
  }
}
th,
td {
  font-weight: 400;
  padding: 8px;
  border: 1px solid $magistracy-medium-grey;
}

.scroll-left, .scroll-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 24px;
  width: 16px;
  height: 16px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
  background-color: $as-light-grey;
}

.scroll-left {
  left: 8px;
}

.scroll-right {
  right: 8px;
}

.scroll-left:hover {
  background: $magistracy-medium-grey;
}
.scroll-right:hover {
  background: $magistracy-medium-grey;
}
.scroll-icon {
  width: 90%;
  height: 90%;
}
</style>
