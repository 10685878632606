<template>
  <div class="commonList">
    <span class="commonList__label">{{ label }}</span>
    <div class="commonList__container">
      <div
        v-for="item in normalizedValue"
        :key="item"
        class="commonList__container__item"
      >
        {{ item }}
        <button @click="removeItem(item)">
          <img src="./icons/close.svg" alt="close" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from "vue";

export default defineComponent({
  name: "CommonList",

  props: {
    modelValue: {
      type: [String, Array] as PropType<string | string[] | null>,
      required: true,
    },

    label: String,
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const normalizedValue = computed(() =>
      props.modelValue
        ? Array.isArray(props.modelValue)
          ? props.modelValue
          : [props.modelValue]
        : []
    );

    const removeItem = (item: string) => {
      if (Array.isArray(props.modelValue)) {
        const updatedList = props.modelValue.filter((i) => i !== item);
        emit("update:modelValue", updatedList.length ? updatedList : null);
      } else {
        emit("update:modelValue", null);
      }
    };

    return {
      normalizedValue,
      removeItem,
    };
  },
});
</script>

<style scoped lang="stylus">
.commonList {
  &__label {
    display: block;
    font-size: 14px;
    color: $as-dark-grey;
    margin-bottom: 4px;
    padding: 0;
    line-height: 16px;
  }

  &__container {
    background-color: $as-extra-light-bg ;
    border: 1px solid $magistracy-medium-grey;
    border-radius: 8px;
    padding: 0 8px;

    &__item {
      padding: 12px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $as-dark;
      getFontSmall()
      line-height: 20px;

      &:not(:last-child) {
        border-bottom: 1px solid  $magistracy-medium-grey;
      }

      button {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
