<template>
  <div class="commonSelect" v-click-outside="onClickOutside">
    <label class="commonSelect__label">
      <slot>{{ label }}</slot>
    </label>
    <div
      class="commonSelect__select"
      :class="{ active: isOpen }"
      :style="{ 'background-color': backgroundColor }"
      @click="toggleDropdown"
      tabindex="0"
    >
      <span
        class="commonSelect__select-placeholder"
        v-if="placeholder && isPlaceholderVisible"
        >{{ placeholder }}</span
      >
      <span v-else-if="!multiple">{{ displaySelectedValue }}</span>
      <span v-else-if="multiple" class="commonSelect__select-count">{{
        "Выбрано " + selectedValues.length
      }}</span>
      <img
        src="./icons/Icon_Arrow.svg"
        class="commonSelect__select-icon"
        :class="{ rotated: isOpen }"
      />
    </div>
    <div v-if="isOpen" class="commonSelect__dropdown">
      <CommonInput
        v-if="search"
        v-model="searchText"
        height="44px"
        placeholder="Поиск"
        backgroundColor="#fff"
        class="commonSelect__dropdown__search"
      >
        <template #prefix>
          <svg class="commonSelect__dropdown__search-icon">
            <use xlink:href="#search-icon--sprite" />
          </svg>
        </template>
      </CommonInput>

      <ul class="commonSelect__dropdown__list">
        <li
          v-for="option in filteredSelectOptions"
          :key="option"
          class="commonSelect__dropdown__list-item"
          :class="[
            {
              'commonSelect__dropdown__list-item-selected':
                !multiple &&
                (selectedValues.includes(option) || modelValue === option),
            },
          ]"
        >
          <CommonCheckbox
            v-if="multiple"
            :label="getOptionText(option)"
            :modelValue="selectedValues.includes(getOptionValue(option))"
            @update:modelValue="
              (isChecked) => updateSelectedOptions(option, isChecked)
            "
            @click.stop
          />
          <div v-else @click="selectOption(getOptionValue(option))">
            {{ getOptionText(option) }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType } from "vue";

import { CommonCheckbox } from "../CommonCheckbox";
import { CommonInput } from "../CommonInput";

export default defineComponent({
  name: "CommonSelect",
  components: { CommonCheckbox, CommonInput },

  props: {
    label: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    options: {
      type: Array as () => string[],
      required: true,
    },

    modelValue: {
      type: [String, Array, null] as PropType<string | string[] | null>,
      required: true,
    },

    search: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    itemValue: {
      type: String,
      default: "",
    },

    itemText: {
      type: String,
      default: "",
    },

    backgroundColor: {
      type: String,
      default: "#F8F8F8",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const searchText = ref<string>("");

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const closeDropdown = () => {
      isOpen.value = false;
    };

    const onClickOutside = () => {
      closeDropdown();
    };

    const checked = ref(false);

    const selectOption = (option: string) => {
      emit("update:modelValue", option);
      closeDropdown();
    };

    const getOptionValue = (option: any): string => {
      if (typeof option === "string") return option;
      else return option[props.itemValue] || option[props.itemText] || "";
    };

    const getOptionText = (option: any): string => {
      if (typeof option === "string") return option;
      return option[props.itemText] || option[props.itemValue] || "";
    };

    const filteredSelectOptions = computed(() => {
      const searchTextLower = searchText.value.toLowerCase();

      return props.options.filter((option: string | Record<string, string>) => {
        if (typeof option === "string") {
          return option.toLowerCase().includes(searchTextLower);
        } else if (typeof option === "object" && props.itemText) {
          const textValue = option[props.itemText] || "";
          return textValue.toLowerCase().includes(searchTextLower);
        }
        return false;
      });
    });

    const displaySelectedValue = computed(() => {
      if (!props.multiple) {
        const option = props.options.find(
          (opt) => getOptionValue(opt) === props.modelValue
        );
        return option ? getOptionText(option) : "";
      }
      return "";
    });

    const isPlaceholderVisible = computed(() => {
      return (
        (props.multiple && selectedValues.value.length === 0) ||
        (!props.multiple && !props.modelValue)
      );
    });

    const selectedOption = computed<string | null>({
      get: () => (props.multiple ? null : (props.modelValue as string)),
      set: (value: string | null) => {
        emit("update:modelValue", value);
      },
    });

    const selectedValues = computed<string[]>({
      get: () =>
        Array.isArray(props.modelValue) ? (props.modelValue as string[]) : [],
      set: (value: string[]) => {
        emit("update:modelValue", value);
      },
    });

    const updateSelectedOptions = (option: string, isChecked: boolean) => {
      const optionValue = getOptionValue(option);
      const values = [...selectedValues.value];

      if (isChecked) {
        values.push(optionValue);
      } else {
        const index = values.indexOf(optionValue);
        if (index >= 0) {
          values.splice(index, 1);
        }
      }
      selectedValues.value = values;
    };

    return {
      isOpen,
      selectedOption,
      selectedValues,
      checked,
      isPlaceholderVisible,
      displaySelectedValue,
      searchText,
      filteredSelectOptions,
      toggleDropdown,
      closeDropdown,
      updateSelectedOptions,
      selectOption,
      getOptionText,
      getOptionValue,
      onClickOutside,
    };
  },
});
</script>

<style scoped lang="stylus">
.commonSelect {
  position: relative;
  min-width: 160px;
  margin: 0;
  padding: 0;

  &__label {
    display: block;
    font-size: 14px;
    color: $as-dark-grey;
    margin-bottom: 4px;
    padding: 0;
    line-height: 16px;
  }

  &__select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border: 1px solid $magistracy-medium-grey;
    border-radius: 6px;
    height: 44px;
    cursor: pointer;
    outline: none;
    position: relative;
    transition: border-color 0.2s ease;
    getFontSmall()
    line-height: 20px;

    &.active {
      border-color: $as-active-green;
    }

    &-placeholder {
      color: $as-grey-system;
    }

    &-count {
      color: $as-active-green;
    }

    &-icon {
      transition: transform 0.2s ease;
      transform: rotate(180deg);
      margin-left: 10px;
      width: 16px;
      height: 16px;

      &.rotated {
        transform: rotate(0deg);
      }
    }
  }

  &__dropdown {
    border-top: none;
    margin-top: 4px;
    border-radius: 10px;
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: calc(100% - 1px);
    z-index: 10;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &__search {
      margin: 8px 16px;
      position: relative;
      width: calc(100% - 32px);

      &-icon {
        width 16px;
        height: 16px;
      }
    }

    &__list {
      list-style: none;
      margin: 0;
      padding: 6px 8px;
      max-height: 150px;
      overflow-y: auto;

      &-item {
        padding: 0 12px;
        color: $as-black;
        margin: 2px 0;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.2s, color 0.2s;
        getFontSmall()

        > div {
          padding: 8px 12px;
          line-height: 16px;
        }

        &:hover {
          background-color: $as-extra-light-bg;
          color: $as-black;
        }

        &-selected {
          background-color: $as-active-green;
          color: #fff !important;

          &:hover {
            background-color: $as-active-green;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
