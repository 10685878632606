import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-f88bd7de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonDatePicker = _resolveComponent("CommonDatePicker")!
  const _component_CommonFormContainer = _resolveComponent("CommonFormContainer")!

  return (_openBlock(), _createBlock(_component_CommonFormContainer, { title: "Учетные данные" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CommonInput, {
          label: "Фамилия",
          modelValue: _ctx.formModel.surname,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.surname) = $event)),
            _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
          ],
          placeholder: "Введите фамилию"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_CommonInput, {
          label: "Имя",
          modelValue: _ctx.formModel.name,
          "onUpdate:modelValue": [
            _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.name) = $event)),
            _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
          ],
          placeholder: "Введите имя"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_CommonInput, {
          label: "Отчество",
          modelValue: _ctx.formModel.father_name,
          "onUpdate:modelValue": [
            _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.father_name) = $event)),
            _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
          ],
          placeholder: "Введите отчество"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_CommonDatePicker, {
          label: "Дата рождения",
          modelValue: _ctx.formModel.birth_date,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.birth_date) = $event)),
          placeholder: "00/00/000"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_CommonInput, {
          label: "Телефон",
          mask: "+# (###) ### ## ##",
          modelValue: _ctx.formModel.phone,
          "onUpdate:modelValue": [
            _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formModel.phone) = $event)),
            _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
          ],
          placeholder: "Введите значение"
        }, null, 8, ["modelValue"]),
        _createVNode(_component_CommonInput, {
          label: "E-mail",
          modelValue: _ctx.formModel.email,
          "onUpdate:modelValue": [
            _cache[9] || (_cache[9] = ($event: any) => ((_ctx.formModel.email) = $event)),
            _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
          ],
          placeholder: "Введите e-mail"
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }))
}