<template>
  <CommonFormContainer title="Список научных работ за последние 5 лет">
    <div v-for="(form, index) in forms" :key="index" class="form-wrapper">
      <CommonInput
        label="Наименование публикации"
        v-model="form.name"
        placeholder="Наименование публикации"
        @update:model-value="$emit('updateValues', forms)"
      />

      <CommonDatePicker
        label="Дата публикации"
        v-model="form.date"
        placeholder="дд/мм/гггг"
        @update:model-value="$emit('updateValues', forms)"
      />

      <button
        class="form-button-delete"
        type="button"
        @click="removeForm(index)"
      >
        Удалить
      </button>
      <button class="form-button-plus" type="button" @click="addForm">
        <img src="./icons/plus.svg" />
      </button>
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonDatePicker } from "@/components/NewComponents/CommonDatePicker";

export default defineComponent({
  name: "ScienceWorkForm",

  emits: ["updateValues"],

  components: {
    CommonFormContainer,
    CommonInput,
    CommonDatePicker,
  },

  setup(props, { emit }) {
    const forms = ref([
      {
        name: "",
        date: "",
      },
    ]);

    const addForm = () => {
      forms.value.push({
        name: "",
        date: "",
      });
      emit("updateValues", forms.value);
    };

    const removeForm = (index: number) => {
      forms.value.splice(index, 1);
      emit("updateValues", forms.value);
    };

    return {
      forms,
      addForm,
      removeForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-button-delete {
  width: 64px;
  getFontSmall();
  line-height: 20px;
  font-weight: 500;
  color: $as-dark-grey;
}

.form-button-plus {
  width: 24px;

  img {
    width: 24px;
    height: 24px;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
