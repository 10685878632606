import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_overlay = _resolveComponent("v-overlay")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_layout = _resolveComponent("v-layout")!

  return (_openBlock(), _createBlock(_component_v_layout, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_overlay, {
        value: _ctx.modelValue,
        opacity: "0.1",
        color: "#000000",
        "z-index": "1",
        scrim: ""
      }, null, 8, ["value"]),
      _createVNode(_component_v_navigation_drawer, {
        temporary: "",
        "model-value": _ctx.modelValue,
        location: "right",
        width: _ctx.width,
        "z-index": "2"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", { closeDrawer: _ctx.closeDrawer }, undefined, true)
        ]),
        _: 3
      }, 8, ["model-value", "width"])
    ]),
    _: 3
  }))
}