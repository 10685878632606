<template>
  <CommonModal v-model="isVisible" title="Назначение темы преподавателю">
    <template #content>
      <v-form class="form-container" @submit.prevent="submitForm">
        <CommonSelect
          placeholder="Выберите из списка"
          v-model="formModel.topics"
          multiple
          search
          :options="topicsOptions"
          label="Темы"
        />

        <CommonList
          v-if="formModel.topics && formModel.topics.length > 0"
          label="Выбранные темы"
          v-model="formModel.topics"
        />

        <CommonButton
          class="form-container__button"
          type="submit"
          colorType="green-light"
          label="Сохранить"
          :disabled="isButtonDisabled"
        />
      </v-form>
    </template>
  </CommonModal>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";
import { CommonModal } from "@/components/NewComponents/CommonModal";
import { CommonList } from "@/components/NewComponents/CommonList";

export default defineComponent({
  name: "ModalThemeToTeacher",

  components: {
    CommonButton,
    CommonSelect,
    CommonModal,
    CommonList,
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["update:modelValue", "submitForm"],

  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const formModel = reactive({
      topics: [] as string[],
    });

    //TO DO доработать типы для списка тем
    const topicsOptions = reactive<string[]>([]);

    const isButtonDisabled = computed(() => !formModel.topics?.length);

    const submitForm = (): void => {
      emit("submitForm", formModel);
      isVisible.value = false;
    };

    return {
      isVisible,
      formModel,
      topicsOptions,
      isButtonDisabled,
      submitForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  &__button {
    width: 130px;
  }
}
</style>
