import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-45d9795e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-container__dates" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonSelect = _resolveComponent("CommonSelect")!
  const _component_CommonDatePicker = _resolveComponent("CommonDatePicker")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isVisible) = $event)),
    title: _ctx.title
  }, {
    content: _withCtx(() => [
      _createVNode(_component_v_form, {
        class: "form-container",
        onSubmit: _withModifiers(_ctx.submitForm, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CommonInput, {
            height: "44px",
            placeholder: "Название",
            label: "Название",
            modelValue: _ctx.formModel.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.name) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_CommonInput, {
            height: "44px",
            type: "number",
            placeholder: "Код",
            label: "Код",
            modelValue: _ctx.formModel.code,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.code) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_CommonSelect, {
            label: "Программа",
            modelValue: _ctx.formModel.program,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.program) = $event)),
            options: _ctx.programOptions,
            placeholder: "Выберите из списка"
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_CommonSelect, {
            label: "Курс",
            modelValue: _ctx.formModel.course,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel.course) = $event)),
            options: _ctx.courseOptions,
            placeholder: "Выберите из списка"
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_CommonSelect, {
            label: "Семестр",
            modelValue: _ctx.formModel.semester,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.semester) = $event)),
            options: _ctx.semesterOptions,
            placeholder: "Выберите из списка"
          }, null, 8, ["modelValue", "options"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_CommonDatePicker, {
              label: "Начало обучения",
              modelValue: _ctx.formModel.dateFrom,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formModel.dateFrom) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_CommonDatePicker, {
              label: "Окончание обучения",
              modelValue: _ctx.formModel.dateTo,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.dateTo) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createVNode(_component_CommonSelect, {
            label: "Статус",
            modelValue: _ctx.formModel.status,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formModel.status) = $event)),
            options: _ctx.statusOptions,
            placeholder: "Выберите из списка"
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_CommonInput, {
            height: "44px",
            placeholder: "https://",
            label: "Ссылка на pruffme",
            modelValue: _ctx.formModel.link,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formModel.link) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_CommonButton, {
            class: "form-container__button",
            type: "submit",
            colorType: "green-light",
            label: "Сохранить",
            disabled: _ctx.isButtonDisabled
          }, null, 8, ["disabled"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}