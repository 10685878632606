import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4d205005"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "participants" }
const _hoisted_2 = { class: "participants__header" }
const _hoisted_3 = { class: "participants__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_CommonTable = _resolveComponent("CommonTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonComponentHeader, {
      title: "Список участников",
      items: _ctx.breadcrumbsItems
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CommonButton, {
        label: "Ведомости",
        onClick: _ctx.goToStatements,
        "color-type": "green-light"
      }, null, 8, ["onClick"]),
      _createVNode(_component_CommonButton, {
        label: "Посещаемость",
        onClick: _ctx.goToAttendance,
        "color-type": "green-light"
      }, null, 8, ["onClick"]),
      _createVNode(_component_CommonButton, {
        label: "Управление участниками",
        onClick: _ctx.goToControl,
        "color-type": "green-light"
      }, null, 8, ["onClick"]),
      _createVNode(_component_CommonButton, {
        label: "Выгрузить в Excel",
        "color-type": "green-light"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CommonTable, {
        "head-list": _ctx.headList,
        "body-list": _ctx.bodyList
      }, null, 8, ["head-list", "body-list"])
    ])
  ]))
}