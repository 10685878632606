import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-c9846464"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "participants" }
const _hoisted_2 = { class: "participants__header" }
const _hoisted_3 = { class: "participants__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_CommonFileUpload = _resolveComponent("CommonFileUpload")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_CommonTable = _resolveComponent("CommonTable")!
  const _component_ModalDocuments = _resolveComponent("ModalDocuments")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonComponentHeader, {
      title: "Управление участниками",
      items: _ctx.breadcrumbsItems
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CommonFileUpload, {
        buttonText: "Выберите файл со списком слушателей",
        "background-color": "#FFF",
        modelValue: _ctx.formModel.file,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.file) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_CommonButton, {
        label: "Загрузить",
        "color-type": "green-light"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CommonTable, {
        actions: _ctx.actions,
        "head-list": _ctx.headList,
        "body-list": _ctx.bodyList
      }, null, 8, ["actions", "head-list", "body-list"])
    ]),
    _createVNode(_component_ModalDocuments, {
      modelValue: _ctx.showDocumentModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDocumentModal) = $event)),
      subtitle: "Иванов Иван Иванович"
    }, null, 8, ["modelValue"])
  ]))
}