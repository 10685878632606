<template>
  <CommonFormContainer title="Описание темы/лекции">
    <CommonInput
      label="Название"
      v-model="formModel.title"
      placeholder="Название"
      @update:model-value="$emit('updateValues', formModel)"
    />

    <CommonTextEditor
      label="Описание"
      v-model="formModel.desc"
      @update:model-value="$emit('updateValues', formModel)"
    />

    <CommonSelect
      label="Преподаватель"
      v-model="formModel.teacher"
      :options="teacherOptions"
      placeholder="Выберите из списка"
    />

    <CommonSelect
      label="Статус"
      v-model="formModel.status"
      :options="formatOptions"
      placeholder="Выберите из списка"
    />

    <CommonSelect
      label="Формат"
      v-model="formModel.format"
      :options="statuseOptions"
      placeholder="Выберите из списка"
    />

    <CommonSelect
      label="Порядок: после лекции"
      v-model="formModel.lecture"
      :options="lectureOptions"
      placeholder="Выберите из списка"
    />

    <CommonInput
      label="Часы"
      type="number"
      v-model="formModel.hours"
      placeholder="Введите значение"
      @update:model-value="$emit('updateValues', formModel)"
    />
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonTextEditor } from "@/components/NewComponents/CommonTextEditor";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";

export default defineComponent({
  name: "FormDescription",

  emits: ["updateValues"],

  props: ["form"],

  components: {
    CommonFormContainer,
    CommonInput,
    CommonTextEditor,
    CommonSelect,
  },

  setup(props) {
    const formModel = reactive({
      title: "",
      desc: "",
      teacher: "",
      format: "",
      status: "",
      lecture: "",
      hours: "",
    });

    //TO DO: доработать массивы под нужные типы
    const teacherOptions = reactive<string[]>([]);
    const formatOptions = reactive<string[]>([]);
    const statuseOptions = reactive<string[]>([]);
    const lectureOptions = reactive<string[]>([]);

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
      teacherOptions,
      formatOptions,
      statuseOptions,
      lectureOptions,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
