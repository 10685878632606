import type { RouteRecordRaw } from "vue-router";

import { RouteName } from "@/constants";
import { ProfilePage } from "@/pages/ProfilePage/index";
import { EducationPage } from "@/pages/EducationPage/index";
import { HomeworksPage } from "@/pages/HomeworksPage/index";
import { LessonPage } from "@/pages/LessonPage/index";
import { TestPage } from "@/pages/TestPage/index";
import { PortfolioPage } from "@/pages/MagistracyPage/components/PortfolioPage/index";
import { EducationMaterialsPage } from "@/pages/EducationMaterialsPage/index";
import { ChatsPage } from "@/pages/ChatsPage/index";
import { InformationPage } from "@/pages/InformationPage/index";
import { AdvertPage } from "@/pages/InformationPage/components/AdvertPage";
import { DocsPage } from "@/pages/InformationPage/components/DocsPage";
import { DpoPage } from "@/pages/DpoPage";
import { EducationDpoPage } from "@/pages/DpoPage/components/EducationDpoPage";
import { LessonDpoPage } from "@/pages/DpoPage/components/EducationDpoPage/components/LessonDpoPage";
import { LectionDpoPage } from "@/pages/DpoPage/components/EducationDpoPage/components/LectionDpoPage";
import { HomeworksDpoPage } from "@/pages/DpoPage/components/EducationDpoPage/components/HomeworksDpoPage";
import { RecommendedCoursesDpo } from "@/pages/DpoPage/components/RecommendedCoursesDpo";
import { HomePage } from "@/pages/HomePage";
import { HomePlanPage } from "@/pages/HomePlanPage";
import { HomeCertPage } from "@/pages/HomeCertPage";
import { HomeDocsPage } from "@/pages/HomeDocsPage";
import { HomeFaqPage } from "@/pages/HomeFaqPage";
import { HomeReviewsPage } from "@/pages/HomeReviewsPage";
import { HomeAdmissionPage } from "@/pages/HomeAdmissionPage";
import { HomeProgramsListPage } from "@/pages/HomeProgramsListPage";
import { HomePageMain } from "@/pages/HomePageMain";
import { HomeMagistracyPage } from "@/pages/HomeMagistracyPage";
import { HomeMagistracyAboutPage } from "@/pages/HomeMagistracyAboutPage";
import { HomeMagistracyDocumentsPage } from "@/pages/HomeMagistracyDocumentsPage";
import { HomeMagistracyContactsPage } from "@/pages/HomeMagistracyContactsPage";
import { HomeMagistracyEntrancePage } from "@/pages/HomeMagistracyEntrancePage";
import { HomeMagistracyProgramsPage } from "@/pages/HomeMagistracyProgramsPage";
import { GeneralPage } from "@/pages/NewPages/Curator/GeneralPage";
import { ProgramsPage } from "@/pages/NewPages/Curator/ProgramsPage";
import { ProgramsCreatePage } from "@/pages/NewPages/Curator/ProgramsPage/components/ProgramsCreatePage";
import { ProgramsArchivePage } from "@/pages/NewPages/Curator/ProgramsPage/components/ProgramsArchivePage";
import { CalendarPlanPage } from "@/pages/NewPages/Curator/ProgramsPage/components/CalendarPlanPage";
import { EducationPlan } from "@/pages/NewPages/Curator/ProgramsPage/components/EducationPlan";
import { AttestationPage } from "@/pages/NewPages/Curator/ProgramsPage/components/AttestationPage";
import { LecturePage } from "@/pages/NewPages/Curator/ProgramsPage/components/LecturePage";
import { TeachersPage } from "@/pages/NewPages/Curator/ProgramsPage/components/TeachersPage";
import { LectureCreatePage } from "@/pages/NewPages/Curator/ProgramsPage/components/LectureCreatePage";
import { LessonsPage } from "@/pages/NewPages/Curator/ProgramsPage/components/LessonsPage";
import { GroupsPage } from "@/pages/NewPages/Curator/ProgramsPage/components/GroupsPage";
import { StatementsPage } from "@/pages/NewPages/Curator/ProgramsPage/components/StatementsPage";
import { AttendancePage } from "@/pages/NewPages/Curator/ProgramsPage/components/AttendancePage";
import { AttendanceTablePage } from "@/pages/NewPages/Curator/ProgramsPage/components/AttendanceTablePage";
import { NotificationsPage } from "@/pages/NewPages/NotificationsPage";
import { ParticipantsPage } from "@/pages/NewPages/Curator/ProgramsPage/components/ParticipantsPage";
import { ControlParticipantsPage } from "@/pages/NewPages/Curator/ProgramsPage/components/ControlParticipantsPage";
import { UserPage } from "@/pages/NewPages/Curator/ProgramsPage/components/UserPage";
import { HomeworkPage } from "@/pages/NewPages/Curator/ProgramsPage/components/HomeworkPage";
import { TestingPage } from "@/pages/NewPages/Curator/ProgramsPage/components/TestingPage";
import { ExamsPage } from "@/pages/NewPages/Curator/ProgramsPage/components/ExamsPage";
import { HomeworkEditPage } from "@/pages/NewPages/Curator/ProgramsPage/components/HomeworkEditPage";
import { TestingEditPage } from "@/pages/NewPages/Curator/ProgramsPage/components/TestingEditPage";
import { ExamEditPage } from "@/pages/NewPages/Curator/ProgramsPage/components/ExamEditPage";
import { PerfomancePage } from "@/pages/NewPages/Curator/ProgramsPage/components/PerfomancePage";
import { UsersPage } from "@/pages/NewPages/Curator/ProgramsPage/components/UsersPage";
import { SchedulePage } from "@/pages/NewPages/Curator/SchedulePage";
import { LibraryPage } from "@/pages/NewPages/Curator/ProgramsPage/components/LibraryPage";

export const routes: RouteRecordRaw[] = [
  {
    name: RouteName.HOME,
    path: "/",
    component: HomePageMain,
  },
  // {
  //   name: RouteName.CURATOR,
  //   path: "/curator",
  //   component: CuratorPage,
  //   children: [
  //     {
  //       name: RouteName.CURATOR_CONTENT,
  //       path: "content",
  //       component: CuratorContentPage,
  //     },
  //   ],
  // },
  {
    name: RouteName.HOME_ORDINAT,
    path: "/ordinat",
    component: HomePage,
  },
  {
    name: RouteName.HOME_ORDINAT_PLAN,
    path: "/ordinat/plan",
    component: HomePlanPage,
  },
  {
    name: RouteName.HOME_ORDINAT_CERT,
    path: "/ordinat/cert",
    component: HomeCertPage,
  },
  {
    name: RouteName.HOME_ORDINAT_DOCS,
    path: "/ordinat/docs",
    component: HomeDocsPage,
  },
  {
    name: RouteName.HOME_ORDINAT_FAQ,
    path: "/ordinat/faq",
    component: HomeFaqPage,
  },
  {
    name: RouteName.HOME_ORDINAT_ADMISSION,
    path: "/ordinat/admission",
    component: HomeAdmissionPage,
  },
  {
    name: RouteName.HOME_ORDINAT_REVIEWS,
    path: "/ordinat/reviews",
    component: HomeReviewsPage,
  },
  {
    name: RouteName.COURSES_DPO,
    path: "/dpo",
    component: HomeProgramsListPage,
    children: [
      {
        name: RouteName.HOME_COURSES,
        path: ":id",
        component: HomePage,
        children: [
          {
            name: RouteName.HOME_COURSES_PLAN,
            path: "plan",
            component: HomePlanPage,
          },
          {
            name: RouteName.HOME_COURSES_CERT,
            path: "cert",
            component: HomeCertPage,
          },
          {
            name: RouteName.HOME_COURSES_FAQ,
            path: "faq",
            component: HomeFaqPage,
          },
          {
            name: RouteName.HOME_COURSES_ADMISSION,
            path: "admission",
            component: HomeAdmissionPage,
          },
          {
            name: RouteName.HOME_COURSES_REVIEWS,
            path: "reviews",
            component: HomeReviewsPage,
          },
        ],
      },
    ],
  },
  {
    name: RouteName.COURSES_ASPIRANT,
    path: "/aspirant",
    component: HomeProgramsListPage,
    children: [
      {
        name: RouteName.HOME_ASPIRANT,
        path: ":id",
        component: HomePage,
        children: [
          {
            name: RouteName.HOME_ASPIRANT_PLAN,
            path: "plan",
            component: HomePlanPage,
          },
          {
            name: RouteName.HOME_ASPIRANT_CERT,
            path: "cert",
            component: HomeCertPage,
          },
          {
            name: RouteName.HOME_ASPIRANT_DOCS,
            path: "docs",
            component: HomeDocsPage,
          },
          {
            name: RouteName.HOME_ASPIRANT_FAQ,
            path: "faq",
            component: HomeFaqPage,
          },
          {
            name: RouteName.HOME_ASPIRANT_ADMISSION,
            path: "admission",
            component: HomeAdmissionPage,
          },
          {
            name: RouteName.HOME_ASPIRANT_REVIEWS,
            path: "reviews",
            component: HomeReviewsPage,
          },
        ],
      },
    ],
  },
  {
    name: RouteName.HOME_MAGISTRACY,
    path: "/magistracy",
    component: HomeMagistracyPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY_ABOUT,
    path: "/magistracy/about",
    component: HomeMagistracyAboutPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY_DOCUMENTS,
    path: "/magistracy/documents",
    component: HomeMagistracyDocumentsPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY_CONTACTS,
    path: "/magistracy/contacts",
    component: HomeMagistracyContactsPage,
  },
  {
    name: RouteName.HOME_MAGISTRACY__ENTRANCE,
    path: "/magistracy/entrance",
    component: HomeMagistracyEntrancePage,
  },
  {
    name: RouteName.HOME_MAGISTRACY_PROGRAM,
    path: "/magistracy/program",
    component: HomeMagistracyProgramsPage,
  },
  {
    name: RouteName.NOTIFICATIONS,
    path: "/notifications",
    component: NotificationsPage,
  },
  {
    name: RouteName.CHAT_NOTIFICATIONS,
    path: "/chat",
    component: NotificationsPage,
  },
  {
    name: RouteName.CURATOR_GENERAL,
    path: "/curator",
    component: GeneralPage,
    children: [
      {
        name: RouteName.CURATOR_PROGRAMS,
        path: "programs",
        component: ProgramsPage,
        children: [
          {
            name: RouteName.CURATOR_PROGRAMS_ARCHIVE,
            path: "archive",
            component: ProgramsArchivePage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_CREATE,
            path: "create",
            component: ProgramsCreatePage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_CALENDAR_PLAN,
            path: "calendar-plan",
            component: CalendarPlanPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_USERS,
            path: "users",
            component: UsersPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_USERS_EDIT,
            path: "users/edit",
            component: UserPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS,
            path: "groups",
            component: GroupsPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_STATEMENTS,
            path: "groups/statements",
            component: StatementsPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_ATTENDANCE,
            path: "groups/attendance",
            component: AttendancePage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_ATTENDANCE_TABLE,
            path: "groups/attendance/table",
            component: AttendanceTablePage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_PARTICIPANTS,
            path: "groups/participants",
            component: ParticipantsPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_PARTICIPANTS_CONTROL,
            path: "groups/participants/control",
            component: ControlParticipantsPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_PARTICIPANTS_CONTROL_USER,
            path: "groups/participants/control/user",
            component: ParticipantsPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_HOMEWORK,
            path: "groups/homework",
            component: HomeworkPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_HOMEWORK_EDIT,
            path: "groups/homework/edit",
            component: HomeworkEditPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_TESTING,
            path: "groups/testing",
            component: TestingPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_TESTING_EDIT,
            path: "groups/testing/edit",
            component: TestingEditPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_EXAMS,
            path: "groups/exams",
            component: ExamsPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_EXAMS_EDIT,
            path: "groups/exams/edit",
            component: ExamEditPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_GROUPS_PERFOMANCE,
            path: "groups/perfomance",
            component: PerfomancePage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN,
            path: "education-plan",
            component: EducationPlan,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_ATTESTATION,
            path: "education-plan/attestation",
            component: AttestationPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_TEACHERS,
            path: "education-plan/teachers",
            component: TeachersPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_LESSONS,
            path: "education-plan/lessons",
            component: LessonsPage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE,
            path: "education-plan/lecture",
            component: LecturePage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE_CREATE,
            path: "education-plan/lecture/create",
            component: LectureCreatePage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_EDIT,
            path: "edit",
            component: ProgramsCreatePage,
          },
          {
            name: RouteName.CURATOR_PROGRAMS_LIBRARY,
            path: "library",
            component: LibraryPage,
          },
        ],
      },
      {
        name: RouteName.CURATOR_SCHEDULE,
        path: "schedule",
        component: SchedulePage,
      },
    ],
  },
  // {
  //   name: RouteName.PROFILE,
  //   path: "/profile",
  //   component: ProfilePage,
  // },
  // {
  //   name: RouteName.EDUCATION,
  //   path: "/education",
  //   component: EducationPage,
  //   children: [
  //     {
  //       name: RouteName.HOMEWORKS,
  //       path: "homeworks",
  //       component: HomeworksPage,
  //     },
  //     {
  //       name: RouteName.LESSON,
  //       path: "lesson",
  //       component: LessonPage,
  //     },
  //     {
  //       name: RouteName.TEST,
  //       path: "test",
  //       component: TestPage,
  //     },
  //   ],
  // },
  // {
  //   name: RouteName.PORTFOLIO,
  //   path: "/portfolio",
  //   component: PortfolioPage,
  // },
  // {
  //   name: RouteName.EDUCATION_MATERIALS,
  //   path: "/education-materials",
  //   component: EducationMaterialsPage,
  // },
  // {
  //   name: RouteName.CHATS,
  //   path: "/chats",
  //   component: ChatsPage,
  // },
  // {
  //   name: RouteName.INFORMATION,
  //   path: "/information",
  //   component: InformationPage,
  //   children: [
  //     {
  //       name: RouteName.ADVERT,
  //       path: "advert",
  //       component: AdvertPage,
  //     },
  //     {
  //       name: RouteName.DOCS,
  //       path: "docs",
  //       component: DocsPage,
  //     },
  //   ],
  // },

  // {
  //   name: RouteName.DPO,
  //   path: "/dpo",
  //   component: DpoPage,
  //   children: [
  //     {
  //       name: RouteName.PROFILE_DPO,
  //       path: "profile",
  //       component: ProfilePage,
  //     },
  //     {
  //       name: RouteName.EDUCATION_DPO,
  //       path: "education",
  //       component: EducationDpoPage,
  //       children: [
  //         {
  //           name: RouteName.LESSON_DPO,
  //           path: "lesson",
  //           component: LessonDpoPage,
  //         },
  //         {
  //           name: RouteName.LECTION_DPO,
  //           path: "lection",
  //           component: LectionDpoPage,
  //         },
  //         {
  //           name: RouteName.HOMEWORK_DPO,
  //           path: "homework",
  //           component: HomeworksDpoPage,
  //         },
  //         {
  //           name: RouteName.TEST_DPO,
  //           path: "test",
  //           component: TestPage,
  //         },
  //       ],
  //     },
  //     {
  //       name: RouteName.COURSES_DPO,
  //       path: "courses",
  //       component: RecommendedCoursesDpo,
  //     },
  //   ],
  // },
];
