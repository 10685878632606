import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonTextEditor = _resolveComponent("CommonTextEditor")!
  const _component_CommonSelect = _resolveComponent("CommonSelect")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isVisible) = $event)),
    title: _ctx.title
  }, {
    content: _withCtx(() => [
      _createVNode(_component_v_form, {
        class: "form-container",
        onSubmit: _withModifiers(_ctx.submitForm, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CommonInput, {
            height: "44px",
            placeholder: "Название",
            label: "Название",
            class: "full-width",
            modelValue: _ctx.formModel.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.name) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_CommonTextEditor, {
            label: "Описание",
            modelValue: _ctx.formModel.description,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.description) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_CommonSelect, {
            label: "Преподаватель",
            modelValue: _ctx.formModel.teacher,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.teacher) = $event)),
            options: _ctx.teachersOptions,
            placeholder: "Выберите из списка"
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_CommonSelect, {
            label: "Статус",
            modelValue: _ctx.formModel.status,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formModel.status) = $event)),
            options: _ctx.statusesOptions,
            placeholder: "Выберите из списка"
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_CommonSelect, {
            label: "Формат",
            modelValue: _ctx.formModel.format,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.format) = $event)),
            options: _ctx.formatOptions,
            placeholder: "Выберите из списка"
          }, null, 8, ["modelValue", "options"]),
          (_ctx.formModel.format === 'тестирование')
            ? (_openBlock(), _createBlock(_component_CommonSelect, {
                key: 0,
                label: "Тестирование",
                modelValue: _ctx.formModel.test,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formModel.test) = $event)),
                options: _ctx.testOptions,
                placeholder: "Выберите из списка"
              }, null, 8, ["modelValue", "options"]))
            : _createCommentVNode("", true),
          _createVNode(_component_CommonInput, {
            height: "44px",
            placeholder: "Введите значение",
            type: "number",
            label: "Часы",
            modelValue: _ctx.formModel.hours,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.hours) = $event))
          }, null, 8, ["modelValue"]),
          _createVNode(_component_CommonButton, {
            class: "form-container__button",
            type: "submit",
            colorType: "green-light",
            label: "Сохранить",
            disabled: _ctx.isButtonDisabled
          }, null, 8, ["disabled"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["modelValue", "title"]))
}