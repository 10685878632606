import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-50e764a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "attendance" }
const _hoisted_2 = { class: "attendance__header" }
const _hoisted_3 = { class: "attendance__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_PerfomanceTable = _resolveComponent("PerfomanceTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonComponentHeader, {
      title: "Успеваемость",
      items: _ctx.breadcrumbsItems
    }, null, 8, ["items"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CommonButton, {
        label: "Скачать в xlsx",
        "color-type": "green-light"
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_PerfomanceTable, {
        title: _ctx.title,
        students: _ctx.students,
        subjects: _ctx.subjects
      }, null, 8, ["title", "students", "subjects"])
    ])
  ]))
}