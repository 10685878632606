<template>
  <header class="header dpo">
    <div class="header__wrap">
      <div class="header__logoContainer">
        <a href="/">
          <svg class="header__logo">
            <use xlink:href="#logo-icon--sprite" />
          </svg>
        </a>
      </div>

      <div class="header__content">
        <div class="header__inputContainer">
          <CommonInput class="header__input" placeholder="Поиск">
            <template #prefix>
              <svg class="header__searchIcon">
                <use xlink:href="#search-icon--sprite" />
              </svg>
            </template>
          </CommonInput>
        </div>

        <div class="header__personContainer">
          <div class="header__iconsBox">
            <v-menu>
              <template v-slot:activator="{ props }">
                <div class="header__iconWrap" v-bind="props">
                  <svg class="header__bellIcon">
                    <use xlink:href="#header-bell-icon--sprite" />
                  </svg>

                  <div
                    class="header__circle"
                    v-if="unViewedNotificationsCount"
                  ></div>
                </div>
              </template>

              <div class="notificationsMenu">
                <div class="notificationsMenu__titleBox">
                  <div class="notificationsMenu__title">Уведомления</div>

                  <div class="notificationsMenu__unViewed">
                    ({{ unViewedNotificationsCount }})

                    <div class="header__iconWrap">
                      <svg class="header__bellIcon">
                        <use xlink:href="#header-bell-icon--sprite" />
                      </svg>

                      <div class="header__circle"></div>
                    </div>
                  </div>
                </div>

                <div
                  v-for="(item, i) in slicedNotificationsList"
                  :key="`notification-item-${i}`"
                  class="notificationsMenu__item"
                >
                  <div class="notificationsMenu__itemTitle">
                    {{ item.title }}
                  </div>

                  <div class="notificationsMenu__itemTime">
                    {{ item.time }}
                  </div>
                </div>

                <CommonButton
                  class="notificationsMenu__button"
                  colorType="green-light"
                  @click="goToNotifications"
                >
                  Все уведомления
                </CommonButton>
              </div>
            </v-menu>

            <v-menu>
              <template v-slot:activator="{ props }">
                <div class="header__iconWrap" v-bind="props">
                  <svg class="header__chatIcon">
                    <use xlink:href="#header-chat-icon--sprite" />
                  </svg>

                  <div class="header__circle"></div>
                </div>
              </template>

              <div class="notificationsMenu">
                <div class="notificationsMenu__titleBox">
                  <div class="notificationsMenu__title">Новые сообщения</div>

                  <div class="notificationsMenu__unViewed">
                    ({{ unViewedChatNotificationsCount }})

                    <div class="header__iconWrap">
                      <svg class="header__chatIcon">
                        <use xlink:href="#header-chat-icon--sprite" />
                      </svg>

                      <div class="header__circle"></div>
                    </div>
                  </div>
                </div>

                <div
                  v-for="(item, i) in slicedChatNotificationsList"
                  :key="`notification-item-${i}`"
                  class="notificationsMenu__item"
                >
                  <div class="notificationsMenu__itemTitle">
                    {{ item.title }}
                  </div>

                  <div class="notificationsMenu__itemTime">
                    {{ item.time }}
                  </div>
                </div>

                <CommonButton
                  class="notificationsMenu__button"
                  colorType="green-light"
                  @click="goToChatNotifications"
                >
                  Все cообщения
                </CommonButton>
              </div>
            </v-menu>
          </div>

          <CommonButton
            colorType="green-light"
            @click="openSignInModal"
            v-if="!userFullName"
          >
            Войти
          </CommonButton>

          <div class="header__person bold-text" v-else>{{ userFullName }}</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from "vue";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { useVfm } from "vue-final-modal";
import { useRouter } from "vue-router";
import { RouteName } from "@/constants";
import { menu, notificationsList, chatNotificationsList } from "./constants";
import store from "@/store";

import "@/assets/img/icons/logo-icon.svg?sprite";
import "@/assets/img/icons/header-bell-icon.svg?sprite";
import "@/assets/img/icons/header-chat-icon.svg?sprite";

export default defineComponent({
  name: "LayoutHeader",
  components: {
    CommonInput,
    CommonButton,
  },

  setup() {
    const vfm = useVfm();
    const router = useRouter();

    const me = computed(() => store.getters.userStore.me);

    const userFullName = computed(() => me.value?.fullname);

    const slicedNotificationsList = computed(() =>
      notificationsList.slice(0, 6)
    );
    const slicedChatNotificationsList = computed(() =>
      chatNotificationsList.slice(0, 6)
    );

    const unViewedNotificationsCount = computed(
      () => notificationsList?.filter((item) => !item.hasViewed)?.length
    );
    const unViewedChatNotificationsCount = computed(
      () => chatNotificationsList?.filter((item) => !item.hasViewed)?.length
    );

    const isShownNotifications = ref(false);

    const openSignInModal = () => vfm.open("signInModal");

    const goToNotifications = () =>
      router.push({ name: RouteName.NOTIFICATIONS });

    const goToChatNotifications = () =>
      router.push({ name: RouteName.CHAT_NOTIFICATIONS });

    onMounted(() => {
      store.dispatch.userStore.getMe();
    });

    return {
      userFullName,
      menu,
      RouteName,
      isShownNotifications,
      slicedNotificationsList,
      slicedChatNotificationsList,
      unViewedNotificationsCount,
      unViewedChatNotificationsCount,
      openSignInModal,
      goToNotifications,
      goToChatNotifications,
    };
  },
});
</script>

<style lang="stylus" scoped>
.notificationsMenu {
  padding: 16px;
  max-width: 380px;
  background-color: $color-white;
  border: 1px solid $magistracy-medium-grey
  box-shadow: 0 4px 12px 0 $color-shadow;
  border-radius: 10px;

  &__titleBox {
    padding-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $magistracy-medium-grey;
  }

  &__title {
    font-size: 20px;
  }

  &__unViewed {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $as-active-orange;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    max-height: 50px;
    margin-top: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid $magistracy-medium-grey;
  }

  &__itemTitle {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__itemTime {
    color: $grey-sistems;
  }

  &__button {
    margin-top: 20px;
    width: 100%;
  }
}
.header {
  position: sticky;
  width: 100%;
  display: flex;
  justify-content: center;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: $color-white;
  border-bottom: 1px solid $magistracy-medium-grey;

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  &__logo {
    height: 50px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;
    flex-grow: 1;
  }

  &__inputContainer {
    flex-grow: 1;
  }

  &__input {
    max-width: 620px;
  }

  &__searchIcon {
    width: 16px;
    height: 16px;
  }

  &__personContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    flex-grow: 1;
    justify-content: flex-end;
  }

  &__person {
    font-weight: bold;
  }

  &__iconsBox {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__iconWrap {
    position: relative;
    cursor: pointer;
    &:hover {
      opacity: .6;
    }
  }

  &__bellIcon {
    width: 24px;
    height: 24px;
  }

  &__chatIcon {
    width: 21px;
    height: 21px;
  }

  &__circle {
    position: absolute;
    top: 2px;
    right: 3px;
    width: 6px;
    height: 6px;
    background-color: $as-active-orange;
    border-radius: 100px;
    z-index: 2;
  }

  +mediaTablet() {
    padding: 20px;

    &__logo {
      width: 15vw;
    }

    &__icon {
      width: 30px;
      height: 30px;
    }
  }

  +mediaGiant() {
    &__link {
      font-size: 20px;
    }
  }

  @media (min-width:1400px) {
    &__wrap {
      max-width: 1400px;
    }
  }
}
</style>
