<template>
  <div class="participants">
    <CommonComponentHeader
      title="Управление участниками"
      :items="breadcrumbsItems"
    />

    <div class="participants__header">
      <CommonFileUpload
        buttonText="Выберите файл со списком слушателей"
        background-color="#FFF"
        v-model="formModel.file"
      />

      <CommonButton label="Загрузить" color-type="green-light" />
    </div>

    <div class="participants__container">
      <CommonTable
        :actions="actions"
        :head-list="headList"
        :body-list="bodyList"
      />
    </div>

    <ModalDocuments
      v-model="showDocumentModal"
      subtitle="Иванов Иван Иванович"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";

import type { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { CommonFileUpload } from "@/components/NewComponents/CommonFileUpload";
import { ModalDocuments } from "@/modals/ModalDocuments";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "ControlParticipantsPage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonTable,
    CommonFileUpload,
    ModalDocuments,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const programId = computed(() => route.query?.program_id);
    const showDocumentModal = ref<boolean>(false);

    const formModel = reactive({
      file: [],
    });

    const actions = ["copy", "profile"];

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const groupName = ref<string>("Группа 1");

    const headList = reactive([
      "ФИО",
      "Документы",
      "Сертификаты",
      "Аттестации",
      "Действия",
    ]);

    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "Иванов Иван Иванович", hasLink: true, link: "#" },
        { title: "Загружено: 3", hasDocument: true },
        { title: "Загружено: 0", hasDocument: true },
        {
          title: "",
          hasTags: true,
          tags: [
            {
              id: 1,
              title: "Домашние задания",
              routerName: "CURATOR_PROGRAMS_GROUPS",
            },
            {
              id: 2,
              title: "Тестирование",
              routerName: "CURATOR_PROGRAMS_GROUPS",
            },
            { id: 3, title: "Экзамены", routerName: "CURATOR_PROGRAMS_GROUPS" },
          ],
        },
        { title: "", hasAction: true },
      ],
      [
        { title: "Петров Виктор Андреевич", hasLink: true, link: "#" },
        { title: "Загружено: 2", hasDocument: true },
        { title: "Загружено: 1", hasDocument: true },
        {
          title: "",
          hasTags: true,
          tags: [
            {
              id: 1,
              title: "Домашние задания",
              routerName: "CURATOR_PROGRAMS_GROUPS",
            },
            {
              id: 2,
              title: "Тестирование",
              routerName: "CURATOR_PROGRAMS_GROUPS",
            },
            { id: 3, title: "Экзамены", routerName: "CURATOR_PROGRAMS_GROUPS" },
          ],
        },
        { title: "", hasAction: true },
      ],
    ]);

    const goToAttendance = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_GROUPS_ATTENDANCE,
      });

    const goToStatements = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_GROUPS_STATEMENTS,
      });

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value, groupName.value)
    );

    return {
      actions,
      breadcrumbsItems,
      programId,
      headList,
      bodyList,
      showDocumentModal,
      formModel,
      goToStatements,
      goToAttendance,
    };
  },
});
</script>

<style scoped lang="stylus">
.participants {
  &__header {
    display: flex;
    gap: 8px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
  }
}
</style>
