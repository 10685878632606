<template>
  <LayoutBase>
    <CommonComponentHeader
      :title="hasChatNotificationsPage ? 'Чат' : 'Уведомления'"
    />

    <div class="notificationsPage__buttonsBox notificationsPage__header">
      <CommonButton colorType="green-light" class="notificationsPage__button">
        Прочитать все
      </CommonButton>

      <CommonButton
        colorType="green-light"
        class="notificationsPage__button"
        v-if="!hasShowNotificationsConstructor"
        @click="toggleHasShowNotificationsConstructor"
      >
        Выбрать
      </CommonButton>
      <div v-else class="notificationsPage__buttonsBox">
        <CommonButton
          colorType="green-light"
          class="notificationsPage__button"
          @click="toggleChoiseAll"
        >
          Выбрать все
        </CommonButton>

        <CommonButton
          colorType="green-light"
          class="notificationsPage__button"
          :disabled="hasAllNotificationsNotChecked"
        >
          Удалить
        </CommonButton>
      </div>
    </div>

    <div class="notificationsPage">
      <NotificationBox
        v-for="(notification, i) in notificationsList"
        :key="`notification-${i}`"
        :date="notification.date"
        :prevDate="(i && notificationsList[i - 1]?.date) || ''"
        :title="notification.title"
        :time="notification.time"
        :hasViewed="notification.hasViewed"
        :hasShowNotificationsConstructor="hasShowNotificationsConstructor"
      >
        <template #checkbox>
          <CommonCheckbox v-model="checkedList[i]" @click.stop />
        </template>
      </NotificationBox>
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import { LayoutBase } from "@/layouts/NewLayouts/LayoutBase";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";
import { NotificationBox } from "./components/NotificationBox";
import { notificationsList } from "./constants";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonCheckbox } from "@/components/NewComponents/CommonCheckbox";
import { CommonButton } from "@/components/NewComponents/CommonButton";

export default defineComponent({
  name: "NotificationsPage",

  components: {
    LayoutBase,
    NotificationBox,
    CommonComponentHeader,
    CommonCheckbox,
    CommonButton,
  },

  setup() {
    const route = useRoute();

    const hasShowNotificationsConstructor = ref(false);

    const checkedList = ref<boolean[]>(
      new Array(notificationsList.length).fill(false)
    );

    const hasNotificationsPage = computed(
      () => route.name === RouteName.NOTIFICATIONS
    );
    const hasChatNotificationsPage = computed(
      () => route.name === RouteName.CHAT_NOTIFICATIONS
    );

    const hasAllNotificationsNotChecked = computed(
      () => !checkedList.value.find((item) => item === true)
    );

    const toggleHasShowNotificationsConstructor = () => {
      hasShowNotificationsConstructor.value = true;
    };

    const toggleChoiseAll = () => {
      checkedList.value = checkedList.value.map(() => true);
    };

    return {
      hasNotificationsPage,
      hasChatNotificationsPage,
      hasShowNotificationsConstructor,
      hasAllNotificationsNotChecked,
      notificationsList,
      checkedList,
      toggleHasShowNotificationsConstructor,
      toggleChoiseAll,
    };
  },
});
</script>

<style lang="stylus" scoped>
.notificationsPage {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__header {
    padding: 16px 20px;
    border-bottom: 1px solid $magistracy-medium-grey;
  }

  &__buttonsBox {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__button {
    font-weight: 500;
  }
}
</style>
