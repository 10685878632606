<template>
  <CommonFormContainer title="Образование">
    <div v-for="(form, index) in forms" :key="index" class="form-wrapper">
      <CommonSelect
        label="Учебное заведение"
        v-model="form.level"
        :options="levelOptions"
        placeholder="Выберите значение"
        @update:model-value="$emit('updateValues', forms)"
      />

      <CommonSelect
        label="Специальность"
        v-model="form.speciality"
        :options="specialityOptions"
        placeholder="Выберите значение"
        @update:model-value="$emit('updateValues', forms)"
      />

      <CommonSelect
        label="Квалификация"
        v-model="form.speciality"
        :options="specialityOptions"
        placeholder="Выберите значение"
        @update:model-value="$emit('updateValues', forms)"
      />

      <CommonSelect
        label="Ученая степень"
        v-model="form.speciality"
        :options="specialityOptions"
        placeholder="Выберите значение"
        @update:model-value="$emit('updateValues', forms)"
      />

      <CommonSelect
        label="Ученое звание"
        v-model="form.speciality"
        :options="specialityOptions"
        placeholder="Выберите значение"
        @update:model-value="$emit('updateValues', forms)"
      />

      <CommonInput
        label="Год окончания"
        type="number"
        v-model="form.speciality"
        :options="specialityOptions"
        placeholder="Выберите значение"
        @update:model-value="$emit('updateValues', forms)"
      />

      <button
        class="form-button-delete"
        type="button"
        @click="removeForm(index)"
      >
        Удалить
      </button>
      <button class="form-button-plus" type="button" @click="addForm">
        <img src="./icons/plus.svg" />
      </button>
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, ref, reactive } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";
import { CommonInput } from "@/components/NewComponents/CommonInput";

export default defineComponent({
  name: "EducationForm",

  emits: ["updateValues"],

  components: {
    CommonFormContainer,
    CommonSelect,
    CommonInput,
  },

  setup(props, { emit }) {
    const forms = ref([
      {
        level: "",
        speciality: "",
      },
    ]);

    const addForm = () => {
      forms.value.push({
        level: "",
        speciality: "",
      });
      emit("updateValues", forms.value);
    };

    const removeForm = (index: number) => {
      forms.value.splice(index, 1);
      emit("updateValues", forms.value);
    };

    const levelOptions = reactive<string[]>([]);
    const specialityOptions = reactive<string[]>([]);

    return {
      forms,
      levelOptions,
      specialityOptions,
      addForm,
      removeForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-button-delete {
  width: 64px;
  getFontSmall();
  line-height: 20px;
  font-weight: 500;
  color: $as-dark-grey;
}

.form-button-plus {
  width: 24px;

  img {
    width: 24px;
    height: 24px;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
