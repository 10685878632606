import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7c984286"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user" }
const _hoisted_2 = { class: "user__container" }
const _hoisted_3 = { class: "user__container__section" }
const _hoisted_4 = { class: "user__container__section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_PersonalDataForm = _resolveComponent("PersonalDataForm")!
  const _component_RoleForm = _resolveComponent("RoleForm")!
  const _component_ExperienceForm = _resolveComponent("ExperienceForm")!
  const _component_DocumentsForm = _resolveComponent("DocumentsForm")!
  const _component_QualificationForm = _resolveComponent("QualificationForm")!
  const _component_InterestsForm = _resolveComponent("InterestsForm")!
  const _component_EducationForm = _resolveComponent("EducationForm")!
  const _component_TeacherEducationForm = _resolveComponent("TeacherEducationForm")!
  const _component_ScienceWorkForm = _resolveComponent("ScienceWorkForm")!
  const _component_FilesForm = _resolveComponent("FilesForm")!
  const _component_CommonButton = _resolveComponent("CommonButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonComponentHeader, {
      title: _ctx.userName,
      items: _ctx.breadcrumbsItems
    }, null, 8, ["title", "items"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PersonalDataForm),
        _createVNode(_component_RoleForm),
        _createVNode(_component_ExperienceForm),
        (_ctx.hasStudentRole)
          ? (_openBlock(), _createBlock(_component_DocumentsForm, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.hasCuratorRole || _ctx.hasTeacherRole)
          ? (_openBlock(), _createBlock(_component_QualificationForm, { key: 1 }))
          : _createCommentVNode("", true),
        _createVNode(_component_InterestsForm)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.hasStudentRole)
          ? (_openBlock(), _createBlock(_component_EducationForm, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.hasCuratorRole || _ctx.hasTeacherRole)
          ? (_openBlock(), _createBlock(_component_TeacherEducationForm, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.hasStudentRole)
          ? (_openBlock(), _createBlock(_component_QualificationForm, { key: 2 }))
          : _createCommentVNode("", true),
        (_ctx.hasCuratorRole || _ctx.hasTeacherRole)
          ? (_openBlock(), _createBlock(_component_ScienceWorkForm, { key: 3 }))
          : _createCommentVNode("", true),
        (_ctx.hasStudentRole)
          ? (_openBlock(), _createBlock(_component_FilesForm, { key: 4 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_CommonButton, {
      label: "Сохранить",
      "color-type": "green-light"
    })
  ]))
}