<template>
  <div class="chat-message" :class="{ sender: message.is_sender === 1 }">
    <div class="chat-message__avatar">
      <img :src="message.avatar" alt="avatar" />
    </div>

    <div class="chat-message__content">
      <div class="chat-message__header">
        <span class="chat-message__author">{{ message.author }}</span>
        <span class="chat-message__time">{{ message.time }}</span>
      </div>
      <div class="chat-message__bubble" v-html="message.text"></div>
    </div>

    <div class="chat-message__actions" v-if="message.is_sender === 1">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

interface Message {
  author: string;
  time: string;
  text: string;
  avatar: string;
  is_sender: number;
}

export default defineComponent({
  name: "ChatMessage",
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    is_sender: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="stylus" scoped>
.chat-message {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 8px;

  &.sender {
    flex-direction: row-reverse;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 24px;
    cursor: pointer;
    margin-top: 44px;

    span {
      width: 4px;
      height: 4px;
      background: $as-dark-grey;
      border-radius: 50%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 60%;
  }

  &__header {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: $as-black;
    margin-bottom: 4px;

    .chat-message.sender & {
      justify-content: flex-end;
    }

    .chat-message:not(.sender) & {
      justify-content: flex-start;
    }
  }

  &__time {
    font-size: 14px;
    margin-left: 8px;
    font-weight: 400;
    color: $as-grey-system;
  }

  &__bubble {
    background: $as-extra-light-bg;
    min-width: 290px;
    padding: 16px;
    border-radius: 20px;
    font-size: 16px;
    color: $as-black;
    line-height: 1.4;

    .chat-message.sender & {
      border-top-right-radius: 0;
    }

    .chat-message:not(.sender) & {
      border-top-left-radius: 0;
    }
  }

  &__avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
