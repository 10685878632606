import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-241b42c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "formVideo" }
const _hoisted_2 = { class: "formVideo__section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonTextEditor = _resolveComponent("CommonTextEditor")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonInput, {
      label: "URL Rutube",
      modelValue: _ctx.formModel.url,
      "onUpdate:modelValue": [
        _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.url) = $event)),
        _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
      ],
      placeholder: "https://..."
    }, null, 8, ["modelValue"]),
    _createVNode(_component_CommonInput, {
      label: "Заголовок",
      modelValue: _ctx.formModel.title,
      "onUpdate:modelValue": [
        _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.title) = $event)),
        _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
      ],
      placeholder: "Введите значение"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CommonInput, {
        label: "Время начала",
        modelValue: _ctx.formModel.time_start,
        "onUpdate:modelValue": [
          _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.time_start) = $event)),
          _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ],
        placeholder: "00:00:00",
        mask: "##:##:##"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_CommonInput, {
        label: "Время окончания",
        modelValue: _ctx.formModel.time_end,
        "onUpdate:modelValue": [
          _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.time_end) = $event)),
          _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ],
        placeholder: "00:00:00",
        mask: "##:##:##"
      }, null, 8, ["modelValue"])
    ]),
    _createVNode(_component_CommonInput, {
      label: "Текст",
      modelValue: _ctx.formModel.text,
      "onUpdate:modelValue": [
        _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formModel.text) = $event)),
        _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
      ],
      placeholder: "Введите значение"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_CommonTextEditor, {
      label: "Конспект",
      modelValue: _ctx.formModel.abstract,
      "onUpdate:modelValue": [
        _cache[10] || (_cache[10] = ($event: any) => ((_ctx.formModel.abstract) = $event)),
        _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
      ]
    }, null, 8, ["modelValue"])
  ]))
}