<template>
  <div class="exam">
    <CommonComponentHeader :title="examName" :items="breadcrumbsItems" />

    <div class="exam__container">
      <div class="exam__container__section">
        <div class="exam__container__section inner">
          <div class="exam__container__section__title">Тестирование</div>
          <div v-html="htmlContent"></div>
        </div>

        <div class="exam__container__date">
          <span>Срок сдачи</span> 15 июля 2024
        </div>
        <div class="exam__container__date">
          <span>Отправка студентом</span> 16 июля 2024
        </div>
      </div>

      <div class="exam__container__section">
        <div class="exam__container__section__chat-header">
          <div class="exam__container__section__chat-header__title">
            Чат по тестированию
          </div>
          <div class="exam__container__section__chat-header__notify">
            ({{ notifyCount }})
            <img src="./icons/chat.svg" />
          </div>
        </div>
        <CommonChat :chat="[]" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import { useRoute } from "vue-router";

import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonChat } from "@/components/NewComponents/CommonChat";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "LectureCreatePage",

  components: {
    CommonComponentHeader,
    CommonChat,
  },

  setup() {
    const route = useRoute();
    const programId = computed(() => route.query?.program_id);

    const showChat = ref<boolean>(false);
    const userName = ref<string>("Иванов Иван Иванович");
    const examName = ref<string>("Экзамен итоговый");
    const notifyCount = ref<number>(20);

    const htmlContent = `
          <span style="font-weight: bold;">Вопрос 1</span>
          <p>Какое слово лишнее в тексте: Thus, the new model of organizational activity entails a process of implementation and modernization. Thus, the new model of organizational activity entails a process of implementation and modernization. Thus, the new model of organizational activity entails a process of implementation and modernization</p>
          <p style="font-weight: bold;">Ответ к вопросу 1
          </br>
          <span style="color: #037265; font-weight: normal;">В (правильный ответ)</span></p>

          <span style="font-weight: bold;">Вопрос 2</span>
          <p>Какое слово лишнее в тексте: Thus, the new model of organizational activity entails a process of implementation and modernization. Thus, the new model of organizational activity entails a process of implementation and modernization. Thus, the new model of organizational activity entails a process of implementation and modernization</p>
          <p style="font-weight: bold;">Ответ к вопросу 2</p>
          <p style="color: red;">В (неверный ответ)</p>

          <span style="font-weight: bold;">Вопрос 3</span>
          <p>Какое слово лишнее в тексте: Thus, the new model of organizational activity entails a process of implementation and modernization. Thus, the new model of organizational activity entails a process of implementation and modernization. Thus, the new model of organizational activity entails a process of implementation and modernization</p>
        `;

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const groupName = ref<string>("Группа 1");

    const openChat = () => {
      showChat.value = true;
    };

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(
        programName.value,
        groupName.value,
        userName.value,
        examName.value
      )
    );

    return {
      notifyCount,
      breadcrumbsItems,
      showChat,
      programId,
      programName,
      examName,
      htmlContent,
      openChat,
    };
  },
});
</script>

<style scoped lang="stylus">
.exam {
  &__container {
    display: flex;
    column-gap: 18px;
    padding: 20px;

    &__section {
      background: #FFF;
      border: 1px solid $magistracy-medium-grey;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      column-gap: 16px;
      flex-grow: 1;
      width: 50%;

      &.inner {
        width: 100%;
        row-gap: 16px;
        margin-bottom: 16px;
      }

      &__title {
        color: $as-black;
        text-transform: uppercase;
        font-weight: 500;
        line-height: 20px;
      }

      &__theme {
        getFontSmall();
        color: $as-dark-grey;
        font-weight: 400;
      }

      &__chat-header {
        display: flex;
        padding-bottom: 16px;
        border-bottom: 1px solid $magistracy-medium-grey;
        justify-content: space-between;
        align-items: center;

        &__title {
          getFontH6();
        }

        &__notify {
          color: $as-dark-grey;
          display: flex;
          align-items: center;
          gap: 5px;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    &__date {
      font-size: 14px;
      color: $as-dark-grey;

      span {
        color: $as-grey-system;
      }
    }
  }

  > button {
    margin-left: 20px;
  }
}
</style>
