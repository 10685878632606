export const notificationsList = [
  {
    date: "23.03.2025",
    time: "11:40",
    title: "Программа “Образовательная программа 1” требует модерации",
    hasViewed: true,
  },
  {
    date: "23.03.2025",
    time: "11:40",
    title: "Программа “Образовательная программа 1” требует модерации",
    hasViewed: false,
  },
  {
    date: "22.03.2025",
    time: "11:40",
    title: "Программа “Образовательная программа 1” требует модерации",
    hasViewed: true,
  },
  {
    date: "21.03.2025",
    time: "11:40",
    title: "Программа “Образовательная программа 1” требует модерации",
    hasViewed: true,
  },
  {
    date: "20.03.2025",
    time: "11:40",
    title: "Программа “Образовательная программа 1” требует модерации",
    hasViewed: true,
  },
  {
    date: "19.03.2025",
    time: "11:40",
    title: "Программа “Образовательная программа 1” требует модерации",
    hasViewed: true,
  },
  {
    date: "18.03.2025",
    time: "11:40",
    title: "Программа “Образовательная программа 1” требует модерации",
    hasViewed: true,
  },
  {
    date: "17.03.2025",
    time: "11:40",
    title: "Программа “Образовательная программа 1” требует модерации",
    hasViewed: true,
  },
];

export const chatNotificationsList = [
  {
    date: "23.03.2025",
    time: "11:40",
    title:
      "Получено новое сообщение от Преподавателя Степанова Валентина Ивановна по теме/лекции 'Основы анатомии...'",
    hasViewed: true,
  },
  {
    date: "23.03.2025",
    time: "11:40",
    title:
      "Получено новое сообщение от Преподавателя Степанова Валентина Ивановна по теме/лекции 'Основы анатомии...'",
    hasViewed: false,
  },
  {
    date: "22.03.2025",
    time: "11:40",
    title:
      "Получено новое сообщение от Преподавателя Степанова Валентина Ивановна по теме/лекции 'Основы анатомии...'",
    hasViewed: true,
  },
  {
    date: "21.03.2025",
    time: "11:40",
    title:
      "Получено новое сообщение от Преподавателя Степанова Валентина Ивановна по теме/лекции 'Основы анатомии...'",
    hasViewed: true,
  },
  {
    date: "20.03.2025",
    time: "11:40",
    title:
      "Получено новое сообщение от Преподавателя Степанова Валентина Ивановна по теме/лекции 'Основы анатомии...'",
    hasViewed: true,
  },
  {
    date: "19.03.2025",
    time: "11:40",
    title:
      "Получено новое сообщение от Преподавателя Степанова Валентина Ивановна по теме/лекции 'Основы анатомии...'",
    hasViewed: true,
  },
  {
    date: "18.03.2025",
    time: "11:40",
    title:
      "Получено новое сообщение от Преподавателя Степанова Валентина Ивановна по теме/лекции 'Основы анатомии...'",
    hasViewed: true,
  },
  {
    date: "17.03.2025",
    time: "11:40",
    title:
      "Получено новое сообщение от Преподавателя Степанова Валентина Ивановна по теме/лекции 'Основы анатомии...'",
    hasViewed: true,
  },
];
