import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d9c7629e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notificationsPage__buttonsBox notificationsPage__header" }
const _hoisted_2 = {
  key: 1,
  class: "notificationsPage__buttonsBox"
}
const _hoisted_3 = { class: "notificationsPage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_CommonCheckbox = _resolveComponent("CommonCheckbox")!
  const _component_NotificationBox = _resolveComponent("NotificationBox")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!

  return (_openBlock(), _createBlock(_component_LayoutBase, null, {
    default: _withCtx(() => [
      _createVNode(_component_CommonComponentHeader, {
        title: _ctx.hasChatNotificationsPage ? 'Чат' : 'Уведомления'
      }, null, 8, ["title"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CommonButton, {
          colorType: "green-light",
          class: "notificationsPage__button"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Прочитать все ")
          ]),
          _: 1
        }),
        (!_ctx.hasShowNotificationsConstructor)
          ? (_openBlock(), _createBlock(_component_CommonButton, {
              key: 0,
              colorType: "green-light",
              class: "notificationsPage__button",
              onClick: _ctx.toggleHasShowNotificationsConstructor
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Выбрать ")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_CommonButton, {
                colorType: "green-light",
                class: "notificationsPage__button",
                onClick: _ctx.toggleChoiseAll
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Выбрать все ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_CommonButton, {
                colorType: "green-light",
                class: "notificationsPage__button",
                disabled: _ctx.hasAllNotificationsNotChecked
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Удалить ")
                ]),
                _: 1
              }, 8, ["disabled"])
            ]))
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationsList, (notification, i) => {
          return (_openBlock(), _createBlock(_component_NotificationBox, {
            key: `notification-${i}`,
            date: notification.date,
            prevDate: (i && _ctx.notificationsList[i - 1]?.date) || '',
            title: notification.title,
            time: notification.time,
            hasViewed: notification.hasViewed,
            hasShowNotificationsConstructor: _ctx.hasShowNotificationsConstructor
          }, {
            checkbox: _withCtx(() => [
              _createVNode(_component_CommonCheckbox, {
                modelValue: _ctx.checkedList[i],
                "onUpdate:modelValue": ($event: any) => ((_ctx.checkedList[i]) = $event),
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1032, ["date", "prevDate", "title", "time", "hasViewed", "hasShowNotificationsConstructor"]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}