<template>
  <div class="moduleContainer">
    <div class="moduleContainer__header">
      <div class="moduleContainer__header__title">
        Модуль/Дисциплина: <span>{{ title }}</span>
      </div>
      <div class="moduleContainer__header__control">
        <CommonButton text color-type="green-light" label="Редактировать" />
        <CommonButton text color-type="grey" label="Удалить" />
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { CommonButton } from "@/components/NewComponents/CommonButton";
export default defineComponent({
  name: "EducationPlan",

  components: {
    CommonButton,
  },

  props: {
    title: String,
    id: [String, Number],
  },

  setup() {
    return {};
  },
});
</script>

<style lang="stylus" scoped>
.moduleContainer {
  background: $as-extra-light-bg;
  border-radius: 10px;
  padding: 20px;

  &__header {
    display: flex;
    justify-content: space-between;
    getFontText()
    color: $as-grey-system;
    font-weight: 500;

    span {
      color: $dpo-black;
    }

    &__control {
      display: flex;
      column-gap: 24px;
    }
  }
}
</style>
