import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonSelect = _resolveComponent("CommonSelect")!
  const _component_CommonFormContainer = _resolveComponent("CommonFormContainer")!

  return (_openBlock(), _createBlock(_component_CommonFormContainer, { title: "Профессиональные интересы" }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonSelect, {
        label: "Профессиональные интересы",
        placeholder: "Выберите из списка",
        multiple: "",
        options: _ctx.interestOptions,
        modelValue: _ctx.formModel.interests,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.interests) = $event))
      }, null, 8, ["options", "modelValue"])
    ]),
    _: 1
  }))
}