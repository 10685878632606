<template>
  <CommonModal v-model="isVisible" :title="title">
    <template #content>
      <v-form class="form-container" @submit.prevent="submitForm">
        <div class="form-container__subttl">{{ subtitle }}</div>

        <div class="form-container__file-block">
          <div class="form-container__file-block__uploaded">
            <div class="form-container__file-block__uploaded__title">
              Загруженные {{ title }}: {{ formModel.file.length }}
            </div>

            <div
              class="form-container__file-block__uploaded__text"
              v-if="formModel.file.length === 0"
            >
              Список пуст
            </div>

            <div v-else class="form-container__file-block__uploaded__list">
              <CommonFileItem
                v-for="(file, index) in formModel.file"
                :key="index"
                background-color="#FFF"
                :label="file"
                height="44px"
                :delete-button="false"
              />
            </div>
          </div>

          <hr class="form-container__file-block__divider" />

          <div class="form-container__file-block__load">
            <CommonFileUpload
              class="form-container__file-block__load__file"
              background-color="#FFF"
              v-model="formModel.file"
              button-text="Выберите файл"
              hide-files
            />

            <CommonButton
              class="form-container__file-block__load__button"
              type="submit"
              colorType="green-light"
              label="Загрузить"
              :disabled="isButtonDisabled"
            />
          </div>
        </div>

        <CommonButton
          class="form-container__file-block__load__button"
          type="submit"
          colorType="green-light"
          label="Сохранить"
          :disabled="isButtonDisabled"
        />
      </v-form>
    </template>
  </CommonModal>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonModal } from "@/components/NewComponents/CommonModal";
import { CommonFileUpload } from "@/components/NewComponents/CommonFileUpload";
import { CommonFileItem } from "@/components/NewComponents/CommonFileItem";

export default defineComponent({
  name: "ModalDocuments",

  components: {
    CommonButton,
    CommonModal,
    CommonFileUpload,
    CommonFileItem,
  },

  props: {
    modelValue: { type: Boolean, required: true },
    title: { type: String, default: "Документы" },
    subtitle: { type: String },
    files: { type: Array as () => string[], default: () => [] },
  },

  emits: ["update:modelValue", "submitForm"],

  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const formModel = reactive({
      file: [],
    });

    const submitForm = (): void => {
      emit("submitForm", formModel);
      isVisible.value = false;
    };

    const isButtonDisabled = computed(() => !formModel.file?.length);

    return {
      isVisible,
      formModel,
      isButtonDisabled,
      submitForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  &__subttl {
    color: $as-black;
    getFontSmall();
    line-height: 20px;
    font-weight: 500;
  }

  &__file-block {
    padding: 20px;
    border: 1px solid $magistracy-medium-grey;
    border-radius: 6px;

    &__uploaded {
      &__title {
        font-size: 14px;
        font-weight: 500;
        text-transform: lowercase;
        margin-bottom: 8px;
          &::first-letter {
            text-transform: uppercase;
        }
      }

      &__text {
        color: $as-dark-grey;
        margin-bottom: 16px;
      }

      &__list {
        display: flex;
        gap: 8px;
        align-self: flex-end;
        margin-bottom: 20px;
      }
    }

    &__divider {
      border: none;
      height: 2px;
      background-color: $magistracy-medium-grey;
      margin: 0 0 20px;
    }

    &__load {
      display: flex;
      gap: 8px;

      &__button {
        width: 130px;
      }

      &__file {
        width: 100%;
      }
    }
  }
}

::v-deep {
  .commonFile__upload,
  .commonFile__button {
    width: 100%;
  }
}
</style>
