import { RouteName } from "@/constants";

export const generateBreadcrumbs = (
  programName: string,
  groupName: string,
  disciplineName: string
) => [
  { title: "Программы", to: { name: RouteName.CURATOR_PROGRAMS } },
  { title: programName },
  {
    title: "Группы",
    to: { name: RouteName.CURATOR_PROGRAMS_GROUPS },
  },
  { title: groupName },
  {
    title: "Посещаемость",
    to: { name: RouteName.CURATOR_PROGRAMS_GROUPS_ATTENDANCE },
  },
  {
    title: disciplineName,
  },
];
