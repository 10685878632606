<template>
  <CommonFormContainer title="Профессиональные интересы">
    <CommonSelect
      label="Профессиональные интересы"
      placeholder="Выберите из списка"
      multiple
      :options="interestOptions"
      v-model="formModel.interests"
    />
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";

export default defineComponent({
  name: "InterestsForm",

  emits: ["updateValues"],

  components: {
    CommonFormContainer,
    CommonSelect,
  },

  setup() {
    const formModel = reactive({
      interests: [],
    });

    const interestOptions = reactive<string[]>([]);

    return {
      interestOptions,
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
