import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonInput = _resolveComponent("CommonInput")!
  const _component_CommonTextEditor = _resolveComponent("CommonTextEditor")!
  const _component_CommonSelect = _resolveComponent("CommonSelect")!
  const _component_CommonFormContainer = _resolveComponent("CommonFormContainer")!

  return (_openBlock(), _createBlock(_component_CommonFormContainer, { title: "Описание темы/лекции" }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonInput, {
        label: "Название",
        modelValue: _ctx.formModel.title,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.title) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ],
        placeholder: "Название"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_CommonTextEditor, {
        label: "Описание",
        modelValue: _ctx.formModel.desc,
        "onUpdate:modelValue": [
          _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formModel.desc) = $event)),
          _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ]
      }, null, 8, ["modelValue"]),
      _createVNode(_component_CommonSelect, {
        label: "Преподаватель",
        modelValue: _ctx.formModel.teacher,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formModel.teacher) = $event)),
        options: _ctx.teacherOptions,
        placeholder: "Выберите из списка"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_CommonSelect, {
        label: "Статус",
        modelValue: _ctx.formModel.status,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formModel.status) = $event)),
        options: _ctx.formatOptions,
        placeholder: "Выберите из списка"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_CommonSelect, {
        label: "Формат",
        modelValue: _ctx.formModel.format,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formModel.format) = $event)),
        options: _ctx.statuseOptions,
        placeholder: "Выберите из списка"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_CommonSelect, {
        label: "Порядок: после лекции",
        modelValue: _ctx.formModel.lecture,
        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formModel.lecture) = $event)),
        options: _ctx.lectureOptions,
        placeholder: "Выберите из списка"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_CommonInput, {
        label: "Часы",
        type: "number",
        modelValue: _ctx.formModel.hours,
        "onUpdate:modelValue": [
          _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formModel.hours) = $event)),
          _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('updateValues', _ctx.formModel)))
        ],
        placeholder: "Введите значение"
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}