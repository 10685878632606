
import { defineComponent, computed } from "vue";

const __default__ = defineComponent({
  name: "CommonFileItem",
  emits: ["delete"],

  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
    deleteButton: { type: Boolean, default: false },
    height: { type: String, default: "44px" },
    backgroundColor: { String, default: "#D9D9D9" },
  },

  setup(props, { emit }) {
    const formattedFileName = computed(() => {
      const extensionIndex = props.label.lastIndexOf(".");
      if (extensionIndex === -1) return props.label;

      const extension = props.label.slice(extensionIndex);
      const nameWithoutExtension = props.label.slice(0, extensionIndex);

      if (props.label.length <= 11) {
        return props.label;
      }

      const remainingLength = 11 - extension.length - 2;
      const startPart = nameWithoutExtension.slice(0, remainingLength);
      const endPart = nameWithoutExtension.slice(-1);

      return `${startPart}..${endPart}${extension}`;
    });

    const deleteFile = () => {
      emit("delete");
    };

    return {
      formattedFileName,
      deleteFile,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "e7590b02": (_ctx.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__