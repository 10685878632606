<template>
  <div class="attendance">
    <CommonComponentHeader title="Посещаемость" :items="breadcrumbsItems" />

    <div class="attendance__header">
      <CommonInput
        backgroundColor="#fff"
        style="max-width: 620px"
        placeholder="Поиск"
        v-model="searchText"
      >
        <template #prefix>
          <svg class="attendance__header__icon">
            <use xlink:href="#search-icon--sprite" />
          </svg>
        </template>
      </CommonInput>
      <CommonButton
        label="Фильтры"
        @click="showDrawer = true"
        color-type="green-light"
      />
    </div>

    <div class="attendance__container">
      <CommonTable
        :actions="actions"
        :head-list="headList"
        :body-list="bodyList"
      />
    </div>

    <CommonDrawer
      v-if="showDrawer"
      v-model="showDrawer"
      @update:modelValue="showDrawer = $event"
    >
      <template #default="{ closeDrawer }">
        <FilterForm @submit-form="closeDrawer" @close="showDrawer = false" />
      </template>
    </CommonDrawer>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from "vue";
import { useRoute } from "vue-router";

import { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { FilterForm } from "./components/FilterForm";
import { CommonDrawer } from "@/components/NewComponents/CommonDrawer";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "AttendancePage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonTable,
    CommonInput,
    CommonDrawer,
    FilterForm,
  },

  setup() {
    const route = useRoute();

    const programId = computed(() => route.query?.program_id);

    const showDrawer = ref<boolean>(false);
    const searchText = ref<string>("");

    const actions = ["download", "copy", "viewing"];

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const groupName = ref<string>("Группа 1");
    const headList = reactive([
      "Модуль/Дисциплина",
      "Курс / Семестр",
      "Действия",
    ]);

    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "Общественное здоровье и здравоохранение" },
        { title: "1 курс / 1 семестр" },
        { title: "", hasAction: true },
      ],
      [
        { title: "Экспертиза временной нетрудоспособности" },
        { title: "1 курс / 2 семестр" },
        { title: "", hasAction: true },
      ],
      [
        { title: "Общественное здоровье и здравоохранение" },
        { title: "2 курс / 1 семестр" },
        { title: "", hasAction: true },
      ],
    ]);

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value, groupName.value)
    );

    return {
      actions,
      breadcrumbsItems,
      programId,
      headList,
      bodyList,
      programName,
      groupName,
      searchText,
      showDrawer,
    };
  },
});
</script>

<style scoped lang="stylus">
.attendance {

  &__header {
    display: flex;
    justify-content:space-between;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;

    &__icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
