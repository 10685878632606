<template>
  <div class="user">
    <CommonComponentHeader :title="userName" :items="breadcrumbsItems" />

    <div class="user__container">
      <div class="user__container__section">
        <PersonalDataForm />
        <RoleForm />
        <ExperienceForm />
        <DocumentsForm v-if="hasStudentRole" />
        <QualificationForm v-if="hasCuratorRole || hasTeacherRole" />
        <InterestsForm />
      </div>

      <div class="user__container__section">
        <EducationForm v-if="hasStudentRole" />
        <TeacherEducationForm v-if="hasCuratorRole || hasTeacherRole" />
        <QualificationForm v-if="hasStudentRole" />
        <ScienceWorkForm v-if="hasCuratorRole || hasTeacherRole" />
        <FilesForm v-if="hasStudentRole" />
      </div>
    </div>

    <CommonButton label="Сохранить" color-type="green-light" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import { useRoute } from "vue-router";

import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { PersonalDataForm } from "./conponents/PersonalDataForm";
import { DocumentsForm } from "./conponents/DocumentsForm";
import { ExperienceForm } from "./conponents/ExperienceForm";
import { RoleForm } from "./conponents/RoleForm";
import { EducationForm } from "./conponents/EducationForm";
import { QualificationForm } from "./conponents/QualificationForm";
import { FilesForm } from "./conponents/FilesForm";
import { InterestsForm } from "./conponents/InterestsForm";
import { TeacherEducationForm } from "./conponents/TeacherEducationForm";
import { ScienceWorkForm } from "./conponents/ScienceWorkForm";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "ParticipantPage",

  components: {
    CommonComponentHeader,
    CommonButton,
    PersonalDataForm,
    DocumentsForm,
    ExperienceForm,
    EducationForm,
    QualificationForm,
    InterestsForm,
    FilesForm,
    RoleForm,
    TeacherEducationForm,
    ScienceWorkForm,
  },

  setup() {
    const route = useRoute();
    const programId = computed(() => route.query?.program_id);

    const userName = ref<string>("Миронова Анастасия Игоревна");

    const roles = [
      // {
      //   name: "Обучающийся",
      //   value: "student",
      // },
      {
        name: "Куратор",
        value: "curator",
      },
      {
        name: "Преподаватель",
        value: "teacher",
      },
    ];

    const hasStudentRole = computed(() =>
      roles.some((role) => role.value === "student")
    );

    const hasTeacherRole = computed(() =>
      roles.some((role) => role.value === "teacher")
    );

    const hasCuratorRole = computed(() =>
      roles.some((role) => role.value === "curator")
    );

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(userName.value)
    );

    return {
      breadcrumbsItems,
      programId,
      userName,
      roles,
      hasStudentRole,
      hasTeacherRole,
      hasCuratorRole,
    };
  },
});
</script>

<style scoped lang="stylus">
.user {
  &__container {
    display: flex;
    gap: 18px;
    padding: 20px;

    &__section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-grow: 1;
      width: 50%;
    }
  }

  > button {
      margin-left: 20px;
    }
}
</style>
