import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonFileUpload = _resolveComponent("CommonFileUpload")!
  const _component_CommonFormContainer = _resolveComponent("CommonFormContainer")!

  return (_openBlock(), _createBlock(_component_CommonFormContainer, { title: "Прикрепленные файлы" }, {
    default: _withCtx(() => [
      _createVNode(_component_CommonFileUpload, {
        modelValue: _ctx.formModel.files,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.files) = $event)),
        "background-color": "#fff"
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }))
}