<template>
  <button
    :class="[
      'commonDropdownActions',
      { commonDropdownActions_active: hasOpenDropdown },
    ]"
    @click="toggleDropdown"
  >
    <svg
      :class="[
        'commonDropdownActions__menuIcon',
        {
          commonDropdownActions__menuIcon_dark: !hasOpenDropdown,
          commonDropdownActions__menuIcon_active: hasOpenDropdown,
        },
      ]"
    >
      <use :href="`#menu-icon--sprite`" />
    </svg>

    <v-menu activator="parent">
      <v-list>
        <v-list-item
          v-for="(item, index) in filteredActionsList"
          :key="index"
          :value="index"
        >
          <button @click="item.action" ref="elemRef">
            <v-list-item-title>
              <div class="commonDropdownActions__elementBox">
                <svg class="commonDropdownActions__elementIcon">
                  <use :href="`#action-icon-${item.iconId}--sprite`" />
                </svg>

                <span
                  :class="`commonDropdownActions__elementTitle commonDropdownActions__elementTitle-${item.iconId}`"
                >
                  {{ item.title }}
                </span>
              </div>
            </v-list-item-title>
          </button>
        </v-list-item>
      </v-list>
    </v-menu>
  </button>
</template>

<script lang="ts">
import { defineComponent, defineExpose, ref, computed } from "vue";

import "@/assets/img/icons/menu-icon.svg?sprite";
import "@/assets/img/icons/action-icon-1.svg?sprite";
import "@/assets/img/icons/action-icon-2.svg?sprite";
import "@/assets/img/icons/action-icon-3.svg?sprite";
import "@/assets/img/icons/action-icon-4.svg?sprite";
import "@/assets/img/icons/action-icon-5.svg?sprite";
import "@/assets/img/icons/action-icon-6.svg?sprite";
import "@/assets/img/icons/action-icon-7.svg?sprite";
import "@/assets/img/icons/action-icon-8.svg?sprite";
import "@/assets/img/icons/action-icon-9.svg?sprite";
import "@/assets/img/icons/action-icon-10.svg?sprite";
import "@/assets/img/icons/action-icon-11.svg?sprite";
import "@/assets/img/icons/action-icon-12.svg?sprite";

export default defineComponent({
  name: "CommonDropdownActions",
  components: {},

  emits: [
    "toggleEdit",
    "toggleArchive",
    "toggleView",
    "toggleCopy",
    "toggleRestore",
    "toggleDelete",
    "toggleComments",
    "toggleTopics",
    "toggleProfile",
    "toggleStatements",
    "toggleAttendance",
    "toggleParticipants",
    "toggleManageParticipants",
    "toggleDownload",
    "toggleViewing",
    "toggleForm",
    "toggleProcessed",
  ],

  props: {
    list: Array,
  },

  setup(props, { emit }) {
    const elemRef = ref<Array<HTMLElement | null>>([]);

    const actionsList = [
      {
        iconId: 1,
        title: "Редактировать",
        value: "edit",
        action: () => emit("toggleEdit"),
      },
      {
        iconId: 2,
        title: "Архивировать",
        value: "archive",
        action: () => emit("toggleArchive"),
      },
      {
        iconId: 3,
        title: "Просмотр",
        value: "view",
        action: () => emit("toggleView"),
      },
      {
        iconId: 4,
        title: "Копировать",
        value: "copy",
        action: () => emit("toggleCopy"),
      },
      {
        iconId: 2,
        title: "Восстановить",
        value: "restore",
        action: () => emit("toggleRestore"),
      },
      {
        iconId: 5,
        title: "Удалить",
        value: "delete",
        action: () => emit("toggleDelete"),
      },
      {
        iconId: 6,
        title: "Комментарии",
        value: "comments",
        action: () => emit("toggleComments"),
      },
      {
        iconId: 7,
        title: "Назначить темы",
        value: "topics",
        action: () => emit("toggleTopics"),
      },
      {
        iconId: 3,
        title: "Посмотреть профиль",
        value: "profile",
        action: () => emit("toggleProfile"),
      },
      {
        iconId: 8,
        title: "Перейти к ведомостям",
        value: "statements",
        action: () => emit("toggleStatements"),
      },
      {
        iconId: 9,
        title: "Журнал посещаемости",
        value: "attendance",
        action: () => emit("toggleAttendance"),
      },
      {
        iconId: 3,
        title: "Список участников",
        value: "participants",
        action: () => emit("toggleParticipants"),
      },
      {
        iconId: 10,
        title: "Управлять участниками",
        value: "manage-participants",
        action: () => emit("toggleManageParticipants"),
      },
      {
        iconId: 11,
        title: "Cкачать",
        value: "download",
        action: () => emit("toggleDownload"),
      },
      {
        iconId: 3,
        title: "Просмотр",
        value: "viewing",
        action: () => emit("toggleViewing"),
      },
      {
        iconId: 12,
        title: "Сформировать",
        value: "form",
        action: () => emit("toggleForm"),
      },
      {
        iconId: 3,
        title: "Перейти",
        value: "processed",
        action: () => emit("toggleProcessed"),
      },
    ];

    const filteredActionsList = computed(() =>
      actionsList?.filter((item: any) =>
        props.list?.find((actionName: any) => actionName === item.value)
      )
    );

    const hasOpenDropdown = computed(() => elemRef.value?.length);

    const toggleDropdown = () => console.log(elemRef.value);

    defineExpose({ elemRef });

    return {
      elemRef,
      filteredActionsList,
      hasOpenDropdown,
      toggleDropdown,
    };
  },
});
</script>

<style scoped lang="stylus">
.commonDropdownActions {
  padding: 4px;
  width: 32px;
  height: 32px;
  background-color: $magistracy-light-grey;
  border-radius: 10px;

  &_active {
    background-color: $as-green-light;
  }

  &__menuIcon {
    width: 100%;
    height: 100%;

    &_dark {
      fill: $as-dark-grey;
    }

    &_active {
      fill: $as-active-green;
    }
  }

  &__elementBox {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__elementIcon {
    width: 16px;
    height: 16px;
  }

  &__elementTitle {
    font-size: 14px;
    font-weight: 500;

    &-1 {
      color: $as-active-green;
    }
    &-2 {
      color: $as-gold;
    }
    &-3 {
      color: $as-blue;
    }
    &-4 {
      color: $as-green-magistr;
    }
    &-5 {
      color: $as-red-aspirant;
    }
    &-6 {
      color: $as-gold;
    }
    &-7 {
      color: $as-active-green;
    }
    &-8 {
      color: $as-gold;
    }
    &-9 {
      color: $as-green-magistr;
    }
    &-10 {
      color: $purpure-zachet;
    }
    &-11 {
      color: $as-green-magistr;
    }
    &-12 {
      color: $as-gold;
    }
  }
}
</style>
