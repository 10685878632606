<template>
  <CommonFormContainer title="Повышение квалификации">
    <div v-for="(form, index) in forms" :key="index" class="form-wrapper">
      <CommonInput
        label="Программа"
        v-model="form.program"
        placeholder="Выберите значение"
        @update:model-value="$emit('updateValues', forms)"
      />

      <div class="form-container">
        <CommonInput
          label="Количество часов"
          v-model="form.hours"
          placeholder="Выберите значение"
          @update:model-value="$emit('updateValues', forms)"
        />

        <CommonInput
          label="Год окончания"
          v-model="form.year"
          placeholder="Выберите значение"
          @update:model-value="$emit('updateValues', forms)"
        />
      </div>
      <button
        class="form-button-delete"
        type="button"
        @click="removeForm(index)"
      >
        Удалить
      </button>
      <button class="form-button-plus" type="button" @click="addForm">
        <img src="./icons/plus.svg" />
      </button>
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";

export default defineComponent({
  name: "QualificationForm",

  emits: ["updateValues"],

  components: {
    CommonFormContainer,
    CommonInput,
  },

  setup(props, { emit }) {
    const forms = ref([
      {
        program: "",
        hours: "",
        year: "",
      },
    ]);

    const addForm = () => {
      forms.value.push({
        program: "",
        hours: "",
        year: "",
      });
      emit("updateValues", forms.value);
    };

    const removeForm = (index: number) => {
      forms.value.splice(index, 1);
      emit("updateValues", forms.value);
    };

    return {
      forms,
      addForm,
      removeForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-button-delete {
  width: 64px;
  getFontSmall();
  line-height: 20px;
  color: $as-dark-grey;
  font-weight: 500;
}

.form-button-plus {
  width: 24px;

  img {
    width: 24px;
    height: 24px;
  }
}

.form-container {
  display: flex;
  gap: 16px;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
