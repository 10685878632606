
import { defineComponent, ref, computed } from "vue";
import { CommonFileItem } from "../CommonFileItem";

const __default__ = defineComponent({
  name: "CommonFileUpload",
  components: { CommonFileItem },
  emits: ["update:modelValue", "fileUploaded"],

  props: {
    label: String,
    accept: { type: String, default: () => "" },
    buttonText: String,
    backgroundColor: { type: String, default: "#D9D9D9" },
    height: { type: String, default: "44px" },
    hideFiles: { type: Boolean, default: false },
    modelValue: { type: Array as () => string[], default: () => [] }, // Добавляем пропс modelValue
  },

  setup(props, { emit }) {
    const fileInput = ref<HTMLInputElement | null>(null);
    const errorMessage = ref("");

    const showFiles = computed(() => {
      return props.modelValue;
    });

    const selectFiles = () => {
      fileInput.value?.click();
    };

    const onSelectFile = (e: Event) => {
      const input = e.target as HTMLInputElement;
      const files = Array.from(input.files || []);
      handleFileSelection(files);
    };

    const handleFileSelection = (files: File[]) => {
      if (files.length) {
        uploadFiles(files);
      }
    };

    const formattedFileName = (fileName: string): string => {
      const extensionIndex = fileName.lastIndexOf(".");
      if (extensionIndex === -1) return fileName;

      const extension = fileName.slice(extensionIndex);
      const nameWithoutExtension = fileName.slice(0, extensionIndex);

      if (fileName.length <= 11) {
        return fileName;
      }

      const remainingLength = 11 - extension.length - 2;
      const startPart = nameWithoutExtension.slice(0, remainingLength);
      const endPart = nameWithoutExtension.slice(-1);

      return `${startPart}..${endPart}${extension}`;
    };

    const uploadFiles = (files: File[]) => {
      const newFiles = files.map((file) => file.name);
      const updatedFiles = [...props.modelValue, ...newFiles];
      emit("update:modelValue", updatedFiles); // Обновляем modelValue
      emit("fileUploaded", updatedFiles);
    };

    const deleteFile = (index: number) => {
      const updatedFiles = props.modelValue.filter((_, i) => i !== index);
      emit("update:modelValue", updatedFiles); // Обновляем modelValue
    };

    return {
      fileInput,
      showFiles,
      errorMessage,
      formattedFileName,
      selectFiles,
      onSelectFile,
      deleteFile,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "51d2a330": (_ctx.height)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__