<template>
  <div class="participants">
    <CommonComponentHeader
      title="Список участников"
      :items="breadcrumbsItems"
    />

    <div class="participants__header">
      <CommonButton
        label="Ведомости"
        @click="goToStatements"
        color-type="green-light"
      />

      <CommonButton
        label="Посещаемость"
        @click="goToAttendance"
        color-type="green-light"
      />

      <CommonButton
        label="Управление участниками"
        @click="goToControl"
        color-type="green-light"
      />

      <CommonButton label="Выгрузить в Excel" color-type="green-light" />
    </div>

    <div class="participants__container">
      <CommonTable :head-list="headList" :body-list="bodyList" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";

import { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "ParticipantsPage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonTable,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const programId = computed(() => route.query?.program_id);

    const showHomeworkModal = ref<boolean>(false);
    const showPracticeModal = ref<boolean>(false);
    const showIndIvidualWorkModal = ref<boolean>(false);
    const showChat = ref<boolean>(false);

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const groupName = ref<string>("Группа 1");

    const headList = reactive([
      "ФИО",
      "EMAIL",
      "Место работы",
      "Должность",
      "Действия",
    ]);

    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "Иванов Иван Иванович" },
        {
          title: "ivanov@gmail.com",
          hasLink: true,
          link: "mailto:ivanov@gmail.com",
        },
        { title: "ГКБ №1 им. Н. И. Пирогова Первая Градская больница" },
        { title: "Старший менеджер по работе с медкадрами" },
        {
          title: "",
          hasAction: true,
        },
      ],
      [
        { title: "Петров Виктор Андреевич" },
        {
          title: "petrov@gmail.com",
          hasLink: true,
          link: "mailto:petrov@gmail.com",
        },
        { title: "ГКБ №1 им. Н. И. Пирогова Первая Градская больница" },
        { title: "Старший менеджер по работе с медкадрами" },
        {
          title: "",
          hasAction: true,
        },
      ],
      [
        { title: "Сидоров Алексей Иванович" },
        {
          title: "sidorov@gmail.com",
          hasLink: true,
          link: "mailto:sidorov@gmail.com",
        },
        { title: "ГКБ №1 им. Н. И. Пирогова Первая Градская больница" },
        { title: "Старший менеджер по работе с медкадрами" },
        {
          title: "",
          hasAction: true,
        },
      ],
    ]);

    const goToAttendance = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_GROUPS_ATTENDANCE,
      });

    const goToStatements = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_GROUPS_STATEMENTS,
      });

    const goToControl = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_GROUPS_PARTICIPANTS_CONTROL,
      });

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value, groupName.value)
    );

    return {
      breadcrumbsItems,
      programId,
      headList,
      bodyList,
      showHomeworkModal,
      showPracticeModal,
      showIndIvidualWorkModal,
      showChat,
      goToStatements,
      goToAttendance,
      goToControl,
    };
  },
});
</script>

<style scoped lang="stylus">
.participants {
  &__header {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
  }
}
</style>
