import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2814a56c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "schedule" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_ScheduleXCalendar = _resolveComponent("ScheduleXCalendar")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_LayoutBase = _resolveComponent("LayoutBase")!

  return (_openBlock(), _createBlock(_component_LayoutBase, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CommonComponentHeader, { title: "Расписание" }),
        _createElementVNode("div", null, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_sheet, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createVNode(_component_ScheduleXCalendar, {
                          "calendar-app": _ctx.calendarApp,
                          events: _ctx.filteredEvents
                        }, null, 8, ["calendar-app", "events"])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}