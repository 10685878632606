<template>
  <div class="lecture">
    <CommonComponentHeader title="Темы/лекции" :items="breadcrumbsItems" />

    <CommonInfoBanner class="lecture__banner">
      <div class="lecture__banner__title">
        <img src="./icons/chart.svg" />Статистика прогресса
      </div>
      <div class="lecture__banner__statistic">
        <span>Всего Тем: </span>
        <span>Опубликовано:</span>
        <span>На Модерации: </span>
      </div>
    </CommonInfoBanner>

    <div class="lecture__header">
      <CommonButton
        label="Новая тема"
        @click="goToCreateLecture"
        color-type="green-light"
      />
    </div>

    <div class="lecture__container">
      <CommonTable
        :actions="actions"
        :head-list="headList"
        :body-list="bodyList"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";

import { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { CommonInfoBanner } from "@/components/NewComponents/CommonInfoBanner";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "LecturePage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonTable,
    CommonInfoBanner,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const programId = computed(() => route.query?.program_id);
    const actions = ["edit", "comments", "delete"];

    const headList = reactive([
      "№",
      "Название",
      "Описание",
      "Кол-во часов",
      "Преподаватель",
      "Статус",
      "Действия",
    ]);

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const themeName = ref<string>(
      "Общественное здоровье и здравоохранение (Б1.О.1)"
    );

    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "1" },
        { title: "Основы анатомии человека" },
        { title: "Введение в анатомию и основные…" },
        { title: "8" },
        { title: "Сидоров Сергей Сергеевич" },
        { title: "Опубликовано", hasStatus: true, statusColor: "green" },
        { title: "", hasAction: true, id: 1 },
      ],
      [
        { title: "2" },
        { title: "Физиология дыхательной системы" },
        { title: "Изучение функций дыхательно…" },
        { title: "4" },
        { title: "Иванов Дмитрий Анатольевич" },
        { title: "Черновик", hasStatus: true, statusColor: "orange" },
        { title: "", hasAction: true, id: 2 },
      ],
      [
        { title: "3" },
        { title: "Основы биохимии" },
        { title: "Основные химические процесс…" },
        { title: "3" },
        { title: "Сидоров Сергей Сергеевич" },
        { title: "На модерации", hasStatus: true, statusColor: "blue" },
        { title: "", hasAction: true, id: 3 },
      ],
    ]);

    const goToCreateLecture = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE_CREATE,
      });

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value, themeName.value)
    );

    return {
      actions,
      breadcrumbsItems,
      programId,
      headList,
      bodyList,
      programName,
      themeName,
      goToCreateLecture,
    };
  },
});
</script>

<style scoped lang="stylus">
.lecture {

  &__banner {
    border-bottom: 1px solid $magistracy-medium-grey;

    > *:nth-child(1) {
      display: flex;
      gap: 0;

      > img {
        margin-right: 8px;
      }
    }

    > *:nth-child(2) {
      display: flex;
      gap: 40px;
    }

    > *:nth-child(1) + *:nth-child(2) {
      margin-left: 68px;
    }
  }

  &__header {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
  }
}
</style>
