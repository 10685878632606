<template>
  <div :class="{ dpo: hasDpo }">
    <ModalTestRules />
    <ModalTrajectory />
    <ModalGoToEducation />
    <ModalSuccess />
    <ModalFail />

    <div class="base-wrapper">
      <div class="base-container">
        <LayoutHeader />

        <div class="base-content-wrap">
          <LayoutSide />

          <div class="base-content">
            <slot />

            <div class="footer-wrap">
              <LayoutFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, type PropType } from "vue";
import { LayoutHeader } from "./components/LayoutHeader";
import { LayoutFooter } from "./components/LayoutFooter";
import { LayoutSide } from "./components/LayoutSide";
import { ModalTestRules } from "@/modals/ModalTestRules/index";
import { ModalTrajectory } from "@/modals/ModalTrajectory/index";
import { ModalGoToEducation } from "@/modals/ModalGoToEducation/index";
import { ModalSuccess } from "@/modals/ModalSuccess/index";
import { ModalFail } from "@/modals/ModalFail/index";
import { useRoute } from "vue-router";
import { RouteName } from "@/constants";
import type { LayoutHomepageProps } from "./types";

export default defineComponent({
  name: "LayoutBase",
  components: {
    LayoutFooter,
    LayoutHeader,
    LayoutSide,
    ModalTestRules,
    ModalTrajectory,
    ModalGoToEducation,
    ModalSuccess,
    ModalFail,
  },

  props: {
    emailsList: {
      type: Array as PropType<LayoutHomepageProps["emailsList"]>,
    },
    phonesList: {
      type: Array as PropType<LayoutHomepageProps["phonesList"]>,
    },
    isHigher: {
      type: Boolean as PropType<LayoutHomepageProps["isHigher"]>,
    },
  },

  setup() {
    const route = useRoute();

    const hasDpo = computed(
      () => route.matched[0]?.name === RouteName.DPO || route.path === "/"
    );

    return {
      hasDpo,
    };
  },
});
</script>

<style lang="stylus" scoped>
.base-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $magistracy-light-grey;
}

.base-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100vh;
  width: 100%;
}

.base-content-wrap {
  display: flex;
  flex-grow: 1;
  width: 100%;
  // padding: 20px 15px 40px;
  +mediaTablet() {
    // padding: 1.9vw 13.6vw 7.8vw;
    // margin-left: -12px;
    // margin-right: -12px;
  }
}

.base-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: calc(100vw - 290px);
}

.base-navigation {
  margin-bottom: 50px;

  +mediaTablet() {
    margin-bottom: 5vw;
  }
}

.footer-wrap {
  padding: 20px;
}
</style>
