<template>
  <div class="attendance">
    <CommonComponentHeader title="Посещаемость" :items="breadcrumbsItems" />

    <div class="attendance__header">
      <CommonButton label="Скачать в xlsx" color-type="green-light" />
    </div>

    <div class="attendance__container">
      <AttendanceTable
        :title="title"
        :lectures="lectures"
        :students="students"
      ></AttendanceTable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import type { Lecture, Student } from "./components/AttendanceTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { AttendanceTable } from "./components/AttendanceTable";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "AttendanceTablePage",

  components: {
    CommonComponentHeader,
    CommonButton,
    AttendanceTable,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const programId = computed(() => route.query?.program_id);

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const groupName = ref<string>("Группа 1");
    const disciplineName = ref<string>(
      "Общественное здоровье и здравоохранение 1 курс/1 семестр "
    );
    const title = ref<string>("Журнал посещаемости: " + disciplineName.value);

    const lectures = reactive<Lecture[]>([
      { name: "Лекция 1", date: "02.09.2022" },
      { name: "Лекция 2", date: "03.09.2022" },
      { name: "Лекция 3", date: "06.09.2022" },
    ]);

    const students = reactive<Student[]>([
      { name: "Иванов Иван Иванович", attendance: ["П", "Н", "П"] },
      { name: "Петров Виктор Андреевич", attendance: ["П", "П", "П"] },
      { name: "Сидоров Алексей Иванович", attendance: ["Н", "П", "Н"] },
    ]);

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(
        programName.value,
        groupName.value,
        disciplineName.value
      )
    );

    return {
      breadcrumbsItems,
      programId,
      programName,
      groupName,
      title,
      lectures,
      students,
    };
  },
});
</script>

<style scoped lang="stylus">
.attendance {

  &__header {
    display: flex;
    justify-content:space-between;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
  }

  &__container {
    padding: 24px;
    background: #fff;
    margin: 20px;
    border-radius: 10px;
  }
}
</style>
