import { RouteName } from "@/constants";

export const breadcrumbsItems = [
  {
    title: "Программы",
    to: {
      name: RouteName.CURATOR_PROGRAMS,
    },
  },
  {
    title: "Календарный план",
    to: {
      name: RouteName.CURATOR_PROGRAMS_CALENDAR_PLAN,
    },
  },
];

export const tableHeadList = [
  {
    title: "Январь",
    list: ["29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01"],
  },
  {
    title: "Февраль",
    list: ["29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01"],
  },
  {
    title: "Март",
    list: ["29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01"],
  },
  {
    title: "Апрель",
    list: ["29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01"],
  },
  {
    title: "Май",
    list: ["29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01"],
  },
  {
    title: "Июнь",
    list: ["29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01"],
  },
  {
    title: "Июль",
    list: ["29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01"],
  },
  {
    title: "Август",
    list: ["29.12 - 04.01", "29.12 - 04.01", "29.12 - 04.01"],
  },
];

export const tableBodyList = [
  {
    title: "Программы дополнительного профессионального образования",
    list: [
      [
        "1",
        "Организация и проведение клинических исследований по международным правилам надлежащей клинической приктики GCP",
        "18",
        "",
        "",
        "",
        {
          color: "#FDF2E7",
          title: "Группа 1_GCP",
          colWidth: 1,
        },
        "",
        {
          color: "#E6F3E8",
          title: "Группа 1_GCP",
          colWidth: 3,
        },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      [
        "1",
        "Организация и проведение клинических исследований по международным правилам надлежащей клинической приктики GCP",
        "18",
        "",
        "",
        "",
        {
          color: "#FDF2E7",
          title: "Группа 1_GCP",
          colWidth: 1,
        },
        "",
        {
          color: "#E6F3E8",
          title: "Группа 1_GCP",
          colWidth: 3,
        },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    ],
  },

  {
    title: "Программы профессиональной переподготовки",
    list: [
      [
        "1",
        "Организация и проведение клинических исследований по международным правилам надлежащей клинической приктики GCP",
        "18",
        "",
        "",
        "",
        {
          color: "#FDF2E7",
          title: "Группа 1_GCP",
          colWidth: 1,
        },
        "",
        {
          color: "#E6F3E8",
          title: "Группа 1_GCP",
          colWidth: 10,
        },
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
    ],
  },
];
