<template>
  <LayoutBase>
    <div class="schedule">
      <CommonComponentHeader title="Расписание" />

      <div>
        <v-row>
          <v-col>
            <v-sheet>
              <div>
                <ScheduleXCalendar
                  :calendar-app="calendarApp"
                  :events="filteredEvents"
                />
              </div>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </div>
  </LayoutBase>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from "vue";
import { LayoutBase } from "@/layouts/NewLayouts/LayoutBase";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { ScheduleXCalendar } from "@schedule-x/vue";
import {
  createCalendar,
  createViewDay,
  createViewMonthAgenda,
  createViewMonthGrid,
  createViewWeek,
  viewMonthGrid,
} from "@schedule-x/calendar";

import "@schedule-x/theme-default/dist/index.css";

export default defineComponent({
  name: "SchedulePage",

  components: {
    LayoutBase,
    CommonComponentHeader,
    ScheduleXCalendar,
  },

  setup() {
    const events = ref([]);

    const calendarApp = createCalendar({
      locale: "ru-RU",
      dayBoundaries: {
        start: "07:00",
        end: "18:00",
      },
      calendars: {
        lecture: {
          colorName: "lecture",
          lightColors: {
            main: "#E37806",
            container: "#FDF2E7",
            onContainer: "#E37806",
          },
        },
        practicalLesson: {
          colorName: "practicalLesson",
          lightColors: {
            main: "#0049A0",
            container: "#E6EDF6",
            onContainer: "#0049A0",
          },
        },
        homework: {
          colorName: "homework",
          lightColors: {
            main: "#037265",
            container: "#EEFAFA",
            onContainer: "#037265",
          },
        },
        test: {
          colorName: "test",
          lightColors: {
            main: "#DD40DD",
            container: "#FBEBFB",
            onContainer: "#DD40DD",
          },
        },
        zachet: {
          colorName: "zachet",
          lightColors: {
            main: "#7D44DE",
            container: "#F6F5FF",
            onContainer: "#7D44DE",
          },
        },
        exam: {
          colorName: "exam",
          lightColors: {
            main: "#CA3C26",
            container: "#FEE6DF",
            onContainer: "#CA3C26",
          },
        },
        course: {
          colorName: "course",
          lightColors: {
            main: "#E24E25",
            container: "#FDF2E7",
            onContainer: "#E24E25",
          },
        },
        practice: {
          colorName: "practice",
          lightColors: {
            main: "#356044",
            container: "#E6F3E8",
            onContainer: "#356044",
          },
        },
      },
      views: [
        createViewDay(),
        createViewWeek(),
        createViewMonthGrid(),
        createViewMonthAgenda(),
      ],
      defaultView: viewMonthGrid.name,
      weekOptions: {
        /**
         * The total height in px of the week grid (week- and day views)
         * */
        gridHeight: 500,

        /**
         * The number of days to display in week view
         */
        // nDays: 5,

        /**
         * The width in percentage of the event element in the week grid
         * Defaults to 100, but can be used to leave a small margin to the right of the event
         */
        eventWidth: 95,

        /**
         * Intl.DateTimeFormatOptions used to format the hour labels on the time axis
         * Default: { hour: 'numeric' }
         */
        timeAxisFormatOptions: { hour: "2-digit", minute: "2-digit" },

        /**
         * Determines whether concurrent events can overlap.
         * Defaults to true. Set to false to disable overlapping.
         */
        eventOverlap: true,
      },
      events: [
        {
          id: 1,
          title: "Event 1",
          start: "2023-12-19",
          end: "2023-12-19",
        },
        {
          id: 2,
          title: "Event 2",
          start: "2023-12-20 12:00",
          end: "2023-12-20 13:00",
        },
        {
          title: "Лекция",
          start: "2025-03-13 10:15",
          end: "2025-03-13 13:15",
          id: "98d85d98541f",
          calendarId: "lecture",
        },
        {
          title: "Практическое задание",
          start: "2025-03-13 11:15",
          end: "2025-03-13 14:15",
          id: "98d85d98542f",
          calendarId: "practice",
        },
        {
          title: "Домашнее задание",
          start: "2025-03-17 11:15",
          end: "2025-03-18",
          id: "98d85d98543f",
          calendarId: "homework",
        },
        {
          title: "Практическое задание 2",
          start: "2025-03-20",
          end: "2025-03-20",
          id: "98d85d98543f",
          calendarId: "practice",
        },
      ],
    });

    const filteredEvents = computed(() => {
      return events.value.filter((event: any) => {
        const date = new Date(event.start);
        const day = date.getDay(); // 0 - Воскресенье, 6 - Суббота
        return day !== 0 && day !== 6; // Исключаем выходные
      });
    });

    return {
      filteredEvents,
      events,
      calendarApp,
    };
  },
});
</script>

<style scoped lang="stylus">
::v-deep {
  .sx__calendar {
    border-radius: 0;
    border: 1px solid $magistracy-medium-grey;
  }
  .sx__is-today {
    border-radius: 4px;
    color: $color-white!important;
    background-color: $as-active-green!important;
  }
  .sx__month-grid-day__header-day-name {
    padding: 8px;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $color-white;
    border-bottom: 1px solid #D9D9D9;
    text-transform: none;
  }
  .sx__month-grid-day__header-date {
    margin: 12px;
    width: 24px;
    height: 24px;
    align-self: end;
    color: $as-dark-grey;
    font-weight: 400;
  }

  .sx__month-grid-day {
    padding-top: 0;
    min-height: 272px;
  }

  .sx__date-grid-day {
    &:nth-child(6),
    &:nth-child(7) {
      display: none;
    }
  }

  .sx__saturday,
  .sx__sunday {
    display: none; /* Скрыть выходные дни */
  }

  .is-leading-or-trailing {
    background-color: $as-extra-light-bg;
  }

  .sx__week-grid__date-number {
    font-size: 16px;
    font-weight: 500;
  }

  .sx__week-grid__date--is-today {
    & .sx__week-grid__day-name {
      color: $as-active-green!important;
    }

    & .sx__week-grid__date-number {
      border-radius: 4px;
      color: $color-white!important;
      background-color: $as-active-green!important;
    }
  }

  .sx__forward-backward-navigation {
    display: flex;
    gap: 8px;
  }

  .sx__chevron-wrapper {
    border-radius: 6px;
    background-color: $color-white;
    border: 1px solid $magistracy-medium-grey;

    &:hover {
      background-color: $as-green-light;
    }
  }

  .sx__chevron {
    border-color: $as-active-green;
  }

  .sx__view-selection-item {
    &:hover {
      background-color: $as-active-green!important;
    }
  }
  .sx__view-selection-item.is-selected {
    background-color: $as-green-light;
  }

  .sx__date-picker__month-view-header__month-year {
    &:hover {
      color: $as-active-green;
    }
  }

  .sx__date-picker__day {
    &:hover {
      background-color: $as-green-light;
    }
  }

  .sx__date-picker__day.sx__date-picker__day--today {
    background-color: $as-active-green;
  }

  .sx__date-picker__years-accordion__expand-button {
    &:hover {
      background-color: $as-green-light;
    }
  }

  .sx__is-expanded .sx__date-picker__years-accordion__expand-button {
    background-color: $as-green-light;
  }

  .sx__date-picker__years-view-accordion__month {
    &:hover {
      background-color: $as-active-green;
    }
  }

  .sx__today-button {
    display: none;
  }

  .sx__event {
    border-left: none!important;
    padding-left: 0;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      margin: 9px 5px 9px 8px;
      border-radius: 100px;
      background-color: currentColor;
    }
  }
}

.schedule {
}
</style>
