import { RouteName } from "@/constants";

export const generateBreadcrumbs = (
  programName: string,
  groupName: string,
  userName: string,
  examName: string
) => [
  { title: "Программы", to: { name: RouteName.CURATOR_PROGRAMS } },
  { title: programName },
  {
    title: "Группы",
    to: { name: RouteName.CURATOR_PROGRAMS_GROUPS },
  },
  {
    title: groupName,
    to: { name: RouteName.CURATOR_PROGRAMS_GROUPS },
  },
  {
    title: userName,
    to: { name: RouteName.CURATOR_PROGRAMS_GROUPS_PARTICIPANTS_CONTROL },
  },
  { title: "Экзамены" },

  { title: examName },
];
