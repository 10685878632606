import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "testing" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonComponentHeader = _resolveComponent("CommonComponentHeader")!
  const _component_CommonTable = _resolveComponent("CommonTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CommonComponentHeader, {
      title: "Тестирования",
      items: _ctx.breadcrumbsItems
    }, null, 8, ["items"]),
    _createVNode(_component_CommonTable, {
      actions: _ctx.actions,
      "head-list": _ctx.headList,
      "body-list": _ctx.bodyList
    }, null, 8, ["actions", "head-list", "body-list"])
  ]))
}