<template>
  <CommonFormContainer title="Учетные данные">
    <div class="form-container">
      <CommonInput
        label="Фамилия"
        v-model="formModel.surname"
        placeholder="Введите фамилию"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        label="Имя"
        v-model="formModel.name"
        placeholder="Введите имя"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        label="Отчество"
        v-model="formModel.father_name"
        placeholder="Введите отчество"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonDatePicker
        label="Дата рождения"
        v-model="formModel.birth_date"
        placeholder="00/00/000"
      />

      <CommonInput
        label="Телефон"
        mask="+# (###) ### ## ##"
        v-model="formModel.phone"
        placeholder="Введите значение"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        label="E-mail"
        v-model="formModel.email"
        placeholder="Введите e-mail"
        @update:model-value="$emit('updateValues', formModel)"
      />
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonDatePicker } from "@/components/NewComponents/CommonDatePicker";

export default defineComponent({
  name: "PersonalDataForm",

  emits: ["updateValues"],

  props: ["form"],

  components: {
    CommonFormContainer,
    CommonInput,
    CommonDatePicker,
  },

  setup(props) {
    const formModel = reactive({
      name: "",
      surname: "",
      father_name: "",
      birth_date: "",
      phone: "",
      email: "",
    });

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
</style>
