<template>
  <CommonFormContainer title="Роль">
    <CommonSelect
      placeholder="Выберите из списка"
      multiple
      :options="roleOptions"
      v-model="formModel.roles"
    />
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";

export default defineComponent({
  name: "RoleForm",

  emits: ["updateValues"],

  components: {
    CommonFormContainer,
    CommonSelect,
  },

  setup() {
    const formModel = reactive({
      roles: [],
    });

    const roleOptions = reactive<string[]>([]);

    return {
      roleOptions,
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped></style>
