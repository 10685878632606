<template>
  <div class="attestation">
    <CommonComponentHeader title="Аттестации" :items="breadcrumbsItems" />

    <div class="attestation__header">
      <CommonButton
        label="Тест"
        color-type="green-light"
        @click="handleAddTest"
      >
        <template #icon>
          <img src="./icons/plus.svg" />
        </template>
      </CommonButton>

      <CommonButton
        label="Экзамен"
        color-type="green-light"
        @click="handleAddExam"
        ><template #icon> <img src="./icons/plus.svg" /> </template
      ></CommonButton>

      <CommonButton
        label="Зачет"
        color-type="green-light"
        @click="handleAddCredit"
        ><template #icon> <img src="./icons/plus.svg" /> </template
      ></CommonButton>

      <CommonButton
        label="Отчет по практике"
        color-type="green-light"
        @click="handleAddTest"
        ><template #icon> <img src="./icons/plus.svg" /> </template
      ></CommonButton>

      <CommonButton
        label="Защита курсовой работы"
        color-type="green-light"
        @click="handleAddTest"
        ><template #icon> <img src="./icons/plus.svg" /> </template
      ></CommonButton>
    </div>

    <div class="attestation__container">
      <CommonTable
        :actions="actions"
        :head-list="headList"
        :body-list="bodyList"
        @edit="editAttestation"
        @archive="deleteAttestation"
        @view="viewAttestation"
      />
    </div>

    <ModalCreateTest v-model="showCreateTest" @submitForm="saveTest" />
    <ModalCreateExam
      v-model="showCreateExam"
      @submitForm="saveExam"
      :type="formType"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, computed } from "vue";
import { useRoute } from "vue-router";
import type { ExamType } from "@/modals/ModalCreateExam/types";
import { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { ModalCreateTest } from "@/modals/ModalCreateTest";
import { ModalCreateExam } from "@/modals/ModalCreateExam";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "AttestationPage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonTable,
    ModalCreateTest,
    ModalCreateExam,
  },

  setup() {
    const route = useRoute();
    const programId = computed(() => route.query?.program_id);

    const showCreateTest = ref<boolean>(false);
    const showCreateExam = ref<boolean>(false);
    const formType = ref<ExamType>("exam");

    const actions = ["edit", "copy", "comments", "delete"];

    const headList = reactive([
      "Название",
      "Форма",
      "Формат",
      "После темы",
      "Преподаватель",
      "Статус",
      "Действия",
    ]);

    // тестовый набор данных
    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "Тестирование. Тема №3" },
        { title: "Тест" },
        { title: "Промежуточный" },
        { title: "№3" },
        { title: "Сидоров Сергей Сергеевич" },
        { title: "Опубликовано", hasStatus: true, statusColor: "green" },
        { title: "", hasAction: true, id: 1 },
      ],
      [
        { title: "Тестирование. Тема №7" },
        { title: "Тест" },
        { title: "Промежуточный" },
        { title: "№7" },
        { title: "Иванов Дмитрий Анатольевич" },
        { title: "Черновик", hasStatus: true, statusColor: "orange" },
        { title: "", hasAction: true, id: 2 },
      ],
      [
        { title: "Экзамен промежуточный" },
        { title: "Экзамен" },
        { title: "Онлайн" },
        { title: "№7" },
        { title: "Сидоров Сергей Сергеевич" },
        { title: "На модерации", hasStatus: true, statusColor: "blue" },
        { title: "", hasAction: true, id: 3 },
      ],
    ]);

    const handleAddTest = () => {
      showCreateTest.value = true;
    };

    const saveTest = () => {
      console.log("Тест сохранен");
      showCreateTest.value = false;
    };

    const handleAddExam = () => {
      formType.value = "exam";
      showCreateExam.value = true;
    };

    const saveExam = () => {
      console.log("Экзамен сохранен");
      showCreateExam.value = false;
    };

    const handleAddCredit = () => {
      formType.value = "credit";
      showCreateExam.value = true;
    };

    const saveCredit = () => {
      console.log("Зачёт сохранен");
      showCreateExam.value = false;
    };

    const editAttestation = (id: number) => {
      console.log(`Редактирование аттестации с id: ${id}`);
    };

    const deleteAttestation = (id: number) => {
      console.log(`Удаление аттестации с id: ${id}`);
    };

    const viewAttestation = (id: number) => {
      console.log(`Просмотр аттестации с id: ${id}`);
    };

    const breadcrumbsItems = computed(() => generateBreadcrumbs());

    return {
      actions,
      breadcrumbsItems,
      programId,
      headList,
      bodyList,
      showCreateTest,
      showCreateExam,
      formType,
      handleAddTest,
      saveTest,
      handleAddExam,
      saveExam,
      handleAddCredit,
      saveCredit,
      editAttestation,
      deleteAttestation,
      viewAttestation,
    };
  },
});
</script>

<style scoped lang="stylus">
.attestation {
  &__header {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
    background: $as-extra-light-bg;
  }
}
</style>
