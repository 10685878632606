<template>
  <CommonModal v-model="isVisible" :title="title">
    <template #content>
      <v-form class="form-container" @submit.prevent="submitForm">
        <CommonInput
          height="44px"
          placeholder="Название"
          label="Название"
          class="full-width"
          v-model="formModel.name"
        />

        <CommonTextEditor label="Описание" v-model="formModel.description" />

        <CommonSelect
          label="Преподаватель"
          v-model="formModel.teacher"
          :options="teachersOptions"
          placeholder="Выберите из списка"
        />

        <CommonSelect
          label="Статус"
          v-model="formModel.status"
          :options="statusesOptions"
          placeholder="Выберите из списка"
        />

        <CommonSelect
          label="Формат"
          v-model="formModel.format"
          :options="formatOptions"
          placeholder="Выберите из списка"
        />

        <!-- Появляется, если выбран формат "Тест"в поле "Формат"
     TO DO: доработать условие -->
        <CommonSelect
          v-if="formModel.format === 'тестирование'"
          label="Тестирование"
          v-model="formModel.test"
          :options="testOptions"
          placeholder="Выберите из списка"
        />

        <CommonInput
          height="44px"
          placeholder="Введите значение"
          type="number"
          label="Часы"
          v-model="formModel.hours"
        />

        <CommonButton
          class="form-container__button"
          type="submit"
          colorType="green-light"
          label="Сохранить"
          :disabled="isButtonDisabled"
        />
      </v-form>
    </template>
  </CommonModal>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, watch, PropType } from "vue";
import type { initialData } from "./types.ts";

import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";
import { CommonModal } from "@/components/NewComponents/CommonModal";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonTextEditor } from "@/components/NewComponents/CommonTextEditor";

export default defineComponent({
  name: "ModalCreateHomework",

  components: {
    CommonButton,
    CommonSelect,
    CommonModal,
    CommonInput,
    CommonTextEditor,
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    initialData: {
      type: Object as PropType<initialData>,
      default: () => ({}),
    }, // Поступающие данные для редактирования формы

    homeworkId: [String, Number],
  },

  emits: ["update:modelValue", "submitForm"],

  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const title = computed(() => {
      return Object.keys(props.initialData).length > 0
        ? `Редактирование Домашнего задания №${props.homeworkId}`
        : "Создание Домашнего задания";
    });

    const formModel = reactive({
      name: props.initialData.name || "",
      description: props.initialData.description || "",
      teacher: props.initialData.teacher || "",
      status: props.initialData.status || "",
      format: props.initialData.format || "",
      test: props.initialData.test || "",
      hours: props.initialData.hours || null,
    });

    watch(
      () => props.initialData,
      (newData) => {
        Object.assign(formModel, {
          name: newData.name || "",
          description: newData.description || "",
          teacher: newData.teacher || "",
          status: newData.status || "",
          format: newData.format || "",
          test: newData.test || "",
          hours: newData.hours || null,
        });
      },
      { deep: true }
    );

    //TO DO: доработать массивы под нужные типы
    const formatOptions = reactive<string[]>([]);
    const teachersOptions = reactive<string[]>([]);
    const statusesOptions = reactive<string[]>([]);
    const testOptions = reactive<string[]>([]);

    const submitForm = (): void => {
      emit("submitForm", formModel);
      isVisible.value = false;
    };

    const isButtonDisabled = computed(() => {
      return Object.entries(formModel).some(([key, value]) => {
        //TO DO: доработать условие для формата "тестирование"
        if (key === "test" && formModel.format !== "тестирование") {
          return false;
        }
        return value === "" || value === null;
      });
    });

    return {
      isVisible,
      formModel,
      formatOptions,
      teachersOptions,
      statusesOptions,
      testOptions,
      title,
      isButtonDisabled,

      submitForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;

  &__button {
    width: 130px;
  }
}
</style>
