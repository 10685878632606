<template>
  <v-layout>
    <v-overlay
      :value="modelValue"
      opacity="0.1"
      color="#000000"
      z-index="1"
      scrim
    ></v-overlay>

    <v-navigation-drawer
      temporary
      :model-value="modelValue"
      location="right"
      :width="width"
      z-index="2"
    >
      <slot :closeDrawer="closeDrawer"></slot>
    </v-navigation-drawer>
  </v-layout>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonDrawer",

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "460px",
    },
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const closeDrawer = () => {
      emit("update:modelValue", false);
    };

    return {
      closeDrawer,
    };
  },
});
</script>

<style scoped lang="stylus">
::v-deep {
  .v-navigation-drawer__scrim {
    opacity: 0.5 !important;
  }
}
</style>
