<template>
  <div class="attendance">
    <CommonComponentHeader title="Успеваемость" :items="breadcrumbsItems" />

    <div class="attendance__header">
      <CommonButton label="Скачать в xlsx" color-type="green-light" />
    </div>

    <div class="attendance__container">
      <PerfomanceTable
        :title="title"
        :students="students"
        :subjects="subjects"
      ></PerfomanceTable>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import { useRoute } from "vue-router";
import type { Subject, Student } from "./components/PerfomanceTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { PerfomanceTable } from "./components/PerfomanceTable";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "AttendanceTablePage",

  components: {
    CommonComponentHeader,
    CommonButton,
    PerfomanceTable,
  },

  setup() {
    const route = useRoute();

    const programId = computed(() => route.query?.program_id);

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const groupName = ref<string>("Группа 1");
    const title = ref<string>(programName.value + " " + groupName.value);

    const students = ref<Student[]>([
      { name: "Абдулапитова Л.К" },
      { name: "Вольтман Е.Н." },
      { name: "Ефимова М.С" },
      { name: "Колисниченко А.Ю." },
      { name: "Ефремова О.А." },
    ]);

    const subjects = ref<Subject[]>([
      {
        name: "Общественное здоровье и здравоохранение",
        semester: "1/1",
        control: "Зачет",
        grades: ["Зачтено", "Зачтено", "Зачтено", "Зачтено", "Зачтено"],
      },
      {
        name: "Информационные технологии в здравоохранении",
        semester: "1/1",
        control: "Зачет",
        grades: ["Зачтено", "Зачтено", "Зачтено", "Зачтено", "Зачтено"],
      },
      {
        name: "Общественное здоровье и здравоохранение",
        semester: "2/4",
        control: "Экзамен",
        grades: ["Хорошо", "Удовл", "Хорошо", "Отлично", "Отлично"],
      },
    ]);

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value, groupName.value)
    );

    return {
      breadcrumbsItems,
      programId,
      programName,
      groupName,
      title,
      subjects,
      students,
    };
  },
});
</script>

<style scoped lang="stylus">
.attendance {

  &__header {
    display: flex;
    justify-content:space-between;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
  }

  &__container {
    padding: 24px;
    background: #fff;
    margin: 20px;
    border-radius: 10px;
  }
}
</style>
