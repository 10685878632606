<template>
  <div class="commonFile">
    <div class="commonFile__upload">
      <p v-if="label" class="commonFile__upload__label">
        {{ label }}
      </p>
      <button
        class="commonFile__button"
        :style="`background-color: ${backgroundColor}`"
        @click="selectFiles"
        type="button"
      >
        <div class="commonFile__upload__logo">
          <img src="./icons/plus.svg" />
        </div>
        <div class="commonFile__upload__text" v-if="buttonText">
          {{ buttonText }}
        </div>
        <p v-if="errorMessage" class="commonFile__error">{{ errorMessage }}</p>

        <input
          ref="fileInput"
          type="file"
          class="commonFile__input"
          :accept="accept"
          multiple
          @change="onSelectFile"
        />
      </button>
    </div>

    <div class="commonFile__list" v-if="!hideFiles">
      <CommonFileItem
        v-for="(file, index) in showFiles"
        :key="index"
        :background-color="backgroundColor"
        :label="file"
        :height="height"
        delete-button
        @delete="deleteFile(index)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { CommonFileItem } from "../CommonFileItem";

export default defineComponent({
  name: "CommonFileUpload",
  components: { CommonFileItem },
  emits: ["update:modelValue", "fileUploaded"],

  props: {
    label: String,
    accept: { type: String, default: () => "" },
    buttonText: String,
    backgroundColor: { type: String, default: "#D9D9D9" },
    height: { type: String, default: "44px" },
    hideFiles: { type: Boolean, default: false },
    modelValue: { type: Array as () => string[], default: () => [] }, // Добавляем пропс modelValue
  },

  setup(props, { emit }) {
    const fileInput = ref<HTMLInputElement | null>(null);
    const errorMessage = ref("");

    const showFiles = computed(() => {
      return props.modelValue;
    });

    const selectFiles = () => {
      fileInput.value?.click();
    };

    const onSelectFile = (e: Event) => {
      const input = e.target as HTMLInputElement;
      const files = Array.from(input.files || []);
      handleFileSelection(files);
    };

    const handleFileSelection = (files: File[]) => {
      if (files.length) {
        uploadFiles(files);
      }
    };

    const formattedFileName = (fileName: string): string => {
      const extensionIndex = fileName.lastIndexOf(".");
      if (extensionIndex === -1) return fileName;

      const extension = fileName.slice(extensionIndex);
      const nameWithoutExtension = fileName.slice(0, extensionIndex);

      if (fileName.length <= 11) {
        return fileName;
      }

      const remainingLength = 11 - extension.length - 2;
      const startPart = nameWithoutExtension.slice(0, remainingLength);
      const endPart = nameWithoutExtension.slice(-1);

      return `${startPart}..${endPart}${extension}`;
    };

    const uploadFiles = (files: File[]) => {
      const newFiles = files.map((file) => file.name);
      const updatedFiles = [...props.modelValue, ...newFiles];
      emit("update:modelValue", updatedFiles); // Обновляем modelValue
      emit("fileUploaded", updatedFiles);
    };

    const deleteFile = (index: number) => {
      const updatedFiles = props.modelValue.filter((_, i) => i !== index);
      emit("update:modelValue", updatedFiles); // Обновляем modelValue
    };

    return {
      fileInput,
      showFiles,
      errorMessage,
      formattedFileName,
      selectFiles,
      onSelectFile,
      deleteFile,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonFile {
  display: flex;
  gap: 8px;

  &__button {
    border: 1px solid $magistracy-medium-grey;
    border-radius: 6px;
    text-align: center;
    transition: background-color 0.3s;
    color: $as-black;
    getFontSmall();
    line-height: 20px;
    cursor: pointer;
    display: flex;
    height: v-bind(height);
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
  }

  &__upload {
    &__label {
      font-size: 14px;
      margin-bottom: 4px;
    }

    &__text {
      padding: 10px 12px;
    }

    &__logo {
      border-radius: 6px;
      border-right: 1px solid $magistracy-medium-grey;
      background-color: $as-active-green;
      width: v-bind(height);
      height: v-bind(height);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
    }
  }

  &__input {
    display: none;
  }

  &__list {
    display: flex;
    gap: 8px;
    align-self: flex-end;
  }

  &__error {
    color: red;
    margin-top: 8px;
  }
}
</style>
