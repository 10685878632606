<template>
  <CommonModal v-model="isVisible" :title="title">
    <template #content>
      <v-form class="form-container" @submit.prevent="submitForm">
        <CommonInput
          height="44px"
          placeholder="Название"
          label="Название"
          v-model="formModel.name"
        />

        <CommonInput
          height="44px"
          type="number"
          placeholder="Код"
          label="Код"
          v-model="formModel.code"
        />

        <CommonSelect
          label="Программа"
          v-model="formModel.program"
          :options="programOptions"
          placeholder="Выберите из списка"
        />

        <CommonSelect
          label="Курс"
          v-model="formModel.course"
          :options="courseOptions"
          placeholder="Выберите из списка"
        />

        <CommonSelect
          label="Семестр"
          v-model="formModel.semester"
          :options="semesterOptions"
          placeholder="Выберите из списка"
        />

        <div class="form-container__dates">
          <CommonDatePicker
            label="Начало обучения"
            v-model="formModel.dateFrom"
          />

          <CommonDatePicker
            label="Окончание обучения"
            v-model="formModel.dateTo"
          />
        </div>

        <CommonSelect
          label="Статус"
          v-model="formModel.status"
          :options="statusOptions"
          placeholder="Выберите из списка"
        />

        <CommonInput
          height="44px"
          placeholder="https://"
          label="Ссылка на pruffme"
          v-model="formModel.link"
        />

        <CommonButton
          class="form-container__button"
          type="submit"
          colorType="green-light"
          label="Сохранить"
          :disabled="isButtonDisabled"
        />
      </v-form>
    </template>
  </CommonModal>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, watch, PropType } from "vue";
import type { initialData } from "./types";

import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";
import { CommonModal } from "@/components/NewComponents/CommonModal";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonDatePicker } from "@/components/NewComponents/CommonDatePicker";

export default defineComponent({
  name: "ModalCreateGroup",

  components: {
    CommonButton,
    CommonSelect,
    CommonModal,
    CommonInput,
    CommonDatePicker,
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    initialData: {
      type: Object as PropType<initialData>,
      default: () => ({}),
    },
  },

  emits: ["update:modelValue", "submitForm"],

  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const title = computed(() => {
      return Object.keys(props.initialData).length > 0
        ? `редактирование группы`
        : "Создание новой группы";
    });

    const formModel = reactive({
      name: props.initialData.name || "",
      code: props.initialData.code || null,
      program: props.initialData.program || null,
      semester: props.initialData.semester || null,
      course: props.initialData.course || null,
      dateFrom: props.initialData.dateFrom || new Date(),
      dateTo: props.initialData.dateTo || new Date(),
      status: props.initialData.status || "",
      link: props.initialData.link || null,
    });

    watch(
      () => props.initialData,
      (newData) => {
        Object.assign(formModel, {
          name: newData.name || "",
          code: newData.name || null,
          program: newData.name || null,
          semester: newData.name || null,
          course: newData.name || null,
          dateFrom: newData.name || new Date(),
          dateTo: newData.name || new Date(),
          status: props.initialData.status || "",
          link: newData.name || null,
        });
      },
      { deep: true }
    );

    //TO DO: доработать массивы под нужные типы
    const programOptions = reactive<string[]>([]);
    const semesterOptions = reactive<string[]>([]);
    const courseOptions = reactive<string[]>([]);
    const statusOptions = reactive<string[]>([]);

    const submitForm = (): void => {
      emit("submitForm", formModel);
      isVisible.value = false;
    };

    const isButtonDisabled = computed(() => {
      return Object.values(formModel).some(
        (value) => value === "" || value === null
      );
    });

    return {
      isVisible,
      formModel,
      programOptions,
      semesterOptions,
      courseOptions,
      statusOptions,
      title,
      isButtonDisabled,

      submitForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;

  & > :nth-child(1),
  & > :nth-child(2) {
    grid-column: span 1;
  }

  & > :nth-child(3) {
    grid-column: span 2;
  }

  & > :nth-child(4),
  & > :nth-child(5) {
    grid-column: span 1;
  }

  & > :nth-child(6) {
    grid-column: span 1;
  }
  & > :nth-child(7) {
    grid-column: span 1;
  }
  & > :nth-child(8) {
    grid-column: span 2;
  }

  & > :nth-child(9) {
    grid-column: span 2;
  }

  &__dates {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  }

  &__button {
    width: 130px;
  }
}
</style>
