<template>
  <div class="infoBanner">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommonInfoBanner",
});
</script>

<style lang="stylus" scoped>
.infoBanner {
  display: flex;
  background-color: $as-green-light;
  color: $as-active-green;
  getFontSmall();
  font-weight: 500;
  line-height: 18px;
  padding: 16px;
}
</style>
