<template>
  <div class="attendance">
    <table class="attendance__table">
      <thead>
        <tr>
          <th class="attendance__table__title" :colspan="lectures.length + 1">
            {{ title }}
          </th>
        </tr>
        <tr>
          <th class="attendance__table__student-column">Студент</th>
          <th
            class="attendance__table__lecture"
            v-for="(lecture, index) in lectures"
            :key="index"
          >
            <span>{{ lecture.name }}</span>
            <br />{{ lecture.date }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, sIndex) in students" :key="sIndex">
          <td class="attendance__table__student-column__name">
            {{ student.name }}
          </td>
          <td
            v-for="(status, index) in student.attendance"
            :key="index"
            :class="[
              'attendance__table__attendance-cell',
              { absent: status === 'Н' },
            ]"
          >
            <div>{{ status }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { Lecture, Student } from "./types";

export default defineComponent({
  name: "AttendanceTable",

  props: {
    lectures: {
      type: Array as PropType<Lecture[]>,
      required: true,
    },
    students: {
      type: Array as PropType<Student[]>,
      required: true,
    },
    title: String,
  },
});
</script>

<style scoped lang="stylus">
.attendance {
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ddd;

  &__table {
    width: 100%;
    border-collapse: collapse;
    getFontSmall();
    color: $as-dark-grey;
    line-height: 20px;

    &__title {
      background: $magistracy-light-grey;
      text-align: left;
      padding: 8px;
    }

    &__student-column {
      width: 250px;
      font-weight: 500;
      text-align: left;
      padding-left: 12px;
      color: $as-dark-grey;

      &__name {
        text-align: left;
        color: $as-active-green;
        padding-left: 12px;
      }
    }

    &__lecture {
      font-weight: 400;

      > span {
        font-weight: 500;
      }
    }

    &__attendance-cell {
      text-align: center;
      font-weight: 500;

      div {
        display: inline;
        padding: 6px 12px;
        background-color: $greenlight-homework;
        max-width: 34px;
        color: $green-homework;
        border-radius: 6px;
      }

      &.absent {
        text-align: center;

        div {
          background-color: $as-red-light;
          color: $active-orange;
        }
      }
    }
  }
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
</style>
