<template>
  <div class="library">
    <CommonComponentHeader title="Библиотека" />

    <div class="library__container">
      <div class="library__container__title">Профессиональные базы данных</div>
      <div
        class="library__container__card"
        v-for="library in dataBase"
        :key="library.id"
      >
        <img :src="require(`./icons/${library.icon}`)" />
        <div>{{ library.name }}</div>
      </div>
    </div>

    <div class="library__container">
      <div class="library__container__title">Профессиональные базы данных</div>
      <div
        class="library__container__card"
        v-for="library in innerLibraries"
        :key="library.id"
      >
        <img :src="require(`./icons/${library.icon}`)" />
        <div>{{ library.name }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";

export default defineComponent({
  name: "GroupsPage",

  components: {
    CommonComponentHeader,
  },

  setup() {
    const route = useRoute();
    const dataBase = ref([
      {
        id: 1,
        name: "Электронная библиотечная система",
        icon: "system.png",
      },
      {
        id: 2,
        name: "Научная электронная библиотека",
        icon: "elibrary.png",
      },
      {
        id: 3,
        name: "Научные информационные ресурсы",
        icon: "prof-rost.png",
      },
    ]);

    const innerLibraries = ref([
      {
        id: 4,
        name: "Библиотека НИИОЗМ",
        icon: "niiozm.png",
      },
    ]);

    return { dataBase, innerLibraries };
  },
});
</script>

<style scoped lang="stylus">
.library {
  &__container {
    display: flex;
    margin: 20px;
    background: $color-white;
    border-radius: 10px;
    gap: 20px;
    border: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
    flex-wrap: wrap;

    &__title {
      flex-basis: 100%;
      getFontH6();
    }

    &__card {
      border: 1px solid $magistracy-medium-grey;
      border-radius: 10px;
      width: calc(33% - 10px);
      color: $as-black;
      height: 200px;
      cursor: pointer;
      transition: 0.2s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      &:hover {
        border-color: $as-active-green;
        color: $as-active-green;
      }

      img {
        max-height: 88px;
        width: auto;
      }
    }
  }
}
</style>
