<template>
  <div :style="`background-color: ${backgroundColor}`" class="commonItem">
    <img class="commonItem__icon" src="./icons/document-text.svg" />
    <div>{{ formattedFileName }}</div>

    <button
      v-if="deleteButton"
      class="commonItem__delete"
      @click="deleteFile"
      type="button"
    >
      <img src="./icons/cancel.svg" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "CommonFileItem",
  emits: ["delete"],

  props: {
    label: {
      type: String,
      required: true,
      default: "",
    },
    deleteButton: { type: Boolean, default: false },
    height: { type: String, default: "44px" },
    backgroundColor: { String, default: "#D9D9D9" },
  },

  setup(props, { emit }) {
    const formattedFileName = computed(() => {
      const extensionIndex = props.label.lastIndexOf(".");
      if (extensionIndex === -1) return props.label;

      const extension = props.label.slice(extensionIndex);
      const nameWithoutExtension = props.label.slice(0, extensionIndex);

      if (props.label.length <= 11) {
        return props.label;
      }

      const remainingLength = 11 - extension.length - 2;
      const startPart = nameWithoutExtension.slice(0, remainingLength);
      const endPart = nameWithoutExtension.slice(-1);

      return `${startPart}..${endPart}${extension}`;
    });

    const deleteFile = () => {
      emit("delete");
    };

    return {
      formattedFileName,
      deleteFile,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonItem {
  display: inline-flex;
  gap: 8px;
  padding: 10px 16px;
  border: 1px solid $magistracy-medium-grey;
  border-radius: 6px;
  text-align: center;
  transition: background-color 0.3s;
  color: $as-black;
  getFontSmall();
  line-height: 20px;
  height: v-bind(height);
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;

  p {
    align-self: center;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__delete {
    align-items: center;
    display: flex;
    width: 16px;
    height: 16px;
  }
}
</style>
