<template>
  <div class="grades">
    <table class="grades__table">
      <thead>
        <tr>
          <th class="grades__table__title" :colspan="students.length + 3">
            {{ title }}
          </th>
        </tr>
        <tr>
          <th class="grades__table__discipline">Дисциплина/практика</th>
          <th>Курс/семестр</th>
          <th>Форма контроля</th>
          <th v-for="(student, index) in students" :key="index">
            {{ student.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(subject, sIndex) in subjects" :key="sIndex">
          <td class="grades__table__discipline">{{ subject.name }}</td>
          <td>{{ subject.semester }}</td>
          <td>{{ subject.control }}</td>
          <td v-for="(grade, index) in subject.grades" :key="index">
            <div class="grades__table__cell">
              {{ grade }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { Subject, Student } from "./types";
export default defineComponent({
  name: "PerfomanceTable",

  props: {
    subjects: {
      type: Array as PropType<Subject[]>,
      required: true,
    },
    students: {
      type: Array as PropType<Student[]>,
      required: true,
    },
    title: String,
  },
});
</script>

<style scoped lang="stylus">
.grades {
  border-radius: 10px;
  overflow: hidden;
  background-color: $color-white;
  border: 1px solid $magistracy-medium-grey;

  &__table {
    width: 100%;
    border-collapse: collapse;
    color: $as-dark-grey;

    &__title {
      background: $as-light-grey;
      text-align: left;
      padding: 8px;
    }

    &__discipline {
      width: 300px;
      font-weight: 500;
      text-align: left;
      padding-left: 12px;
    }

    &__cell {
      text-align: center;
      font-weight: 500;
      padding: 6px;
      border-radius: 6px;
      background-color: $greenlight-homework;
      color: $green-homework;
    }
  }
}

th,
td {
  border: 1px solid $magistracy-medium-grey;
  padding: 8px;
  text-align: center;
}
</style>
