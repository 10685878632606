import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9258f8a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notificationBox" }
const _hoisted_2 = {
  key: 0,
  class: "notification__date"
}
const _hoisted_3 = { class: "notification__wrap" }
const _hoisted_4 = {
  key: 0,
  class: "notification__viewedBox"
}
const _hoisted_5 = { class: "notification" }
const _hoisted_6 = { class: "notification__titleBox" }
const _hoisted_7 = { class: "notification__title" }
const _hoisted_8 = {
  key: 0,
  class: "notification__hasNotViewed"
}
const _hoisted_9 = { class: "notification__time" }
const _hoisted_10 = { class: "notification__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonDropdownActions = _resolveComponent("CommonDropdownActions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.date !== _ctx.prevDate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.date), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.hasShowNotificationsConstructor)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "checkbox", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (!_ctx.hasViewed)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
          ]),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.time), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_CommonDropdownActions, {
            list: ['processed', 'delete'],
            onDelete: _cache[0] || (_cache[0] = ($event: any) => (console.log(123)))
          })
        ])
      ])
    ])
  ]))
}