<template>
  <div class="homework">
    <CommonComponentHeader title="Домашние задания" :items="breadcrumbsItems" />
    <CommonTable
      :actions="actions"
      :head-list="headList"
      :body-list="bodyList"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from "vue";
import { useRoute } from "vue-router";

import { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "HomeworkPage",

  components: {
    CommonComponentHeader,
    CommonTable,
  },

  setup() {
    const route = useRoute();
    const programId = computed(() => route.query?.program_id);

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const groupName = ref<string>("Группа 1");
    const userName = ref<string>("Иванов Иван Иванович");

    const actions = ["copy", "viewing"];

    const headList = reactive([
      "Название",
      "Тип",
      "Дата выполнения",
      "Статус",
      "Действия",
    ]);

    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "Домашнее задание №1", isUser: true },
        { title: "Тест" },
        { title: "10.01.2024" },
        { title: "Выполнено", hasStatus: true, statusColor: "green" },
        {
          title: "",
          hasAction: true,
          id: 1,
        },
      ],
      [
        { title: "Домашнее задание №2", isUser: true },
        { title: "Ответ" },
        { title: "14.01.2024" },
        { title: "Не выполнено", hasStatus: true, statusColor: "red" },
        {
          title: "",
          hasAction: true,
          id: 2,
        },
      ],
      [
        { title: "Домашнее задание №3", isUser: true },
        { title: "Тест" },
        { title: "15.01.2024" },
        { title: "Назначено", hasStatus: true, statusColor: "orange" },
        {
          title: "",
          hasAction: true,
          id: 3,
        },
      ],
      [
        { title: "Домашнее задание №4", isUser: true },
        { title: "Тест" },
        { title: "19.01.2024" },
        { title: "Отправлено", hasStatus: true, statusColor: "purple" },
        {
          title: "",
          hasAction: true,
          id: 4,
        },
      ],
      [
        { title: "Домашнее задание №5", isUser: true },
        { title: "Ответ" },
        { title: "20.01.2024" },
        { title: "Проверка", hasStatus: true, statusColor: "blue" },
        {
          title: "",
          hasAction: true,
          id: 5,
        },
      ],
    ]);

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value, groupName.value, userName.value)
    );

    return {
      actions,
      breadcrumbsItems,
      programId,
      headList,
      bodyList,
      programName,
    };
  },
});
</script>

<style scoped lang="stylus"></style>
