<template>
  <div class="commonChat">
    <p class="commonChat__title" v-if="title">{{ title }}</p>

    <div class="commonChat__chat">
      <div v-for="(dialog, index) in chat" :key="index">
        <div class="commonChat__chat__divider">
          <span class="commonChat__chat__divider__line"></span>
          <span class="commonChat__chat__divider__date">
            {{ dialog.day }}
          </span>
          <span class="commonChat__chat__divider__line"></span>
        </div>
        <ChatMessage
          v-for="message in dialog.messages"
          :key="message.id"
          :message="message"
        >
        </ChatMessage>
      </div>
    </div>

    <div class="commonChat__form">
      <CommonTextEditor v-model="formModel.text" />

      <div class="commonChat__form__file">
        <CommonFileUpload
          background-color="#fff"
          height="44px"
          button-text="Выберите файл"
          v-model="formModel.file"
        />
      </div>

      <CommonButton
        :disabled="isButtonDisabled"
        class="commonChat__form__btn"
        label="Отправить"
        color-type="green-light"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, PropType } from "vue";
import { CommonTextEditor } from "../CommonTextEditor";
import { CommonFileUpload } from "../CommonFileUpload";
import { ChatMessage } from "./components/ChatMessage";
import { CommonButton } from "../CommonButton";
import type { ChatItemTypes } from "./types";

export default defineComponent({
  name: "CommonChat",

  components: {
    CommonTextEditor,
    CommonFileUpload,
    ChatMessage,
    CommonButton,
  },

  emits: ["update:modelValue", "submitForm"],

  props: {
    title: String,
    modalTitle: String,
    chat: {
      type: Array as PropType<ChatItemTypes[]>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const formModel = reactive({
      text: "",
      file: [],
    });

    const isButtonDisabled = computed(() => {
      return Object.values(formModel).every((value) => !value);
    });

    return {
      formModel,
      isButtonDisabled,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonChat {
  margin-top: 16px;

  &__title {
    getFontSmall();
    font-weight: 500;
    line-height: 20px;
  }

  &__chat {
    height: 360px;
    border: 1px solid $magistracy-medium-grey;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    margin-bottom: -4px;
    overflow-y: auto;
    padding: 20px;

    &__divider {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      font-size: 14px;
      font-weight: 400;
      color: $as-grey-system;
      margin: 24px 0;

      &__line {
        flex-grow: 1;
        height: 1px;
        background-color: #ddd;
      }
    }
  }

  &__form {

    &__file {
      padding: 8px;
      border-radius: 0 0 6px 6px;
      background-color: $as-extra-light-bg;
      border: 1px solid $magistracy-medium-grey;
      border-top: 0;
    }

    &__btn {
      margin-top: 16px;
    }
  }
}

::v-deep {
  .commonEditor__input {
    border-radius: 0 !important;
  }
}
</style>
