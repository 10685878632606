<template>
  <v-dialog
    v-model="isOpen"
    :max-width="width"
    :max-height="height"
    :persistent="persistent"
  >
    <div class="commonModal">
      <div class="commonModal__header">
        <span class="commonModal__header__title">{{ title }}</span>
        <button class="commonModal__header__close" @click="close">
          <img src="./icons/close.svg" alt="close" />
        </button>
      </div>
      <hr />
      <slot name="content"></slot>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from "vue";

export default defineComponent({
  name: "CommonModal",

  props: {
    title: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    width: {
      type: String,
      default: "778px",
    },
    height: {
      type: String,
      default: "auto",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "close"],

  setup(props, { emit }) {
    const isOpen = computed({
      get: () => props.modelValue,
      set: (val: boolean) => {
        emit("update:modelValue", val);
      },
    });

    const close = () => {
      isOpen.value = false;
    };

    watch(
      () => props.modelValue,
      (newVal) => {
        isOpen.value = newVal;
      },
      { immediate: true }
    );

    return {
      isOpen,
      close,
    };
  },
});
</script>

<style scoped lang="stylus">
.commonModal {
  border-radius 12px
  padding: 20px;
  background-color: #fff;
  border: 1px solid $magistracy-medium-grey;
  box-shadow 0px 4px 20px rgba(0, 0, 0, 0.2)

  &__header {
   display: flex;
   justify-content: space-between;

    &__title {
      color: $as-black;
      text-transform: uppercase;
      getFontText();
      font-weight: 500;
    }

    &__close {
      > img {
        width: 24px;
        height: 24px;
      }
    }
  }

  > hr {
    margin-top: 4px;
    margin-bottom: 4px;
    border: none;
    background-color: $magistracy-medium-grey;
    height: 1px;
    color: $magistracy-medium-grey;
    }
}

::v-deep {
  .v-overlay__scrim {
    background-color: #000000 !important;
    opacity: 0.6 !important;
  }
}
</style>
