<template>
  <div class="lecture">
    <CommonComponentHeader
      title="Создание новой темы/лекции"
      :items="breadcrumbsItems"
    />

    <div class="lecture__container">
      <div class="lecture__container__section">
        <FormDescription />
        <FormVideoTranscription />
        <CommonFormContainer title="123">
          <FormVideo />
        </CommonFormContainer>
      </div>

      <div class="lecture__container__section">
        <FormMaterial @open-chat="openChat" title="Презентации"></FormMaterial>
        <FormMaterial
          @open-chat="openChat"
          title="Дополнительные материалы"
        ></FormMaterial>
        <FormMaterial @open-chat="openChat" title="Литература"></FormMaterial>
      </div>
    </div>

    <CommonButton label="Сохранить" color-type="green-light" />
    <CommonModal v-model="showChat" title="КОММЕНТАРИИ">
      <CommonChat :chat="testChatData" title="Домашнее задание №1" />
    </CommonModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from "vue";
import { useRoute } from "vue-router";

import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { FormDescription } from "./components/FormDescription";
import { FormVideoTranscription } from "./components/FormVideoTranscription";
import { FormVideo } from "./components/FromVideo";
import { FormMaterial } from "./components/FormMaterial";
import { CommonChat } from "@/components/NewComponents/CommonChat";
import { CommonModal } from "@/components/NewComponents/CommonModal";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "LectureCreatePage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonFormContainer,
    FormDescription,
    FormVideoTranscription,
    FormVideo,
    FormMaterial,
    CommonChat,
    CommonModal,
  },

  setup() {
    const route = useRoute();
    const programId = computed(() => route.query?.program_id);

    const showChat = ref<boolean>(false);

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const themeName = ref<string>(
      "Общественное здоровье и здравоохранение (Б1.О.1)"
    );

    const testChatData = [
      {
        day: "Сегодня",
        messages: [
          {
            id: 1,
            author: "Модератор 1",
            text: "Расширьте описание задачи, пожалуйста",
            avatar: "",
            time: "00:12",
            is_sender: 1,
          },
          {
            id: 2,
            author: "Преподаватель",
            text: "Готово",
            avatar: "",
            time: "00:12",
            is_sender: 0,
          },
        ],
      },
      {
        day: "Вчера",
        messages: [
          {
            id: 1,
            author: "Модератор 1",
            text: "Не забудьте подготовить отчёт к завтрашнему дню.",
            avatar: "",
            time: "00:12",
            is_sender: 1,
          },
          {
            id: 2,
            author: "Преподаватель",
            text: "Хорошо, обязательно подготовим.",
            avatar: "",
            is_sender: 0,
            time: "18:30",
          },
        ],
      },
    ];

    const openChat = () => {
      showChat.value = true;
    };

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value, themeName.value)
    );

    return {
      breadcrumbsItems,
      showChat,
      programId,
      programName,
      themeName,
      testChatData,
      openChat,
    };
  },
});
</script>

<style scoped lang="stylus">
.lecture {
  &__container {
    display: flex;
    gap: 18px;
    padding: 20px;

    &__section {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-grow: 1;
      width: 50%;
    }
  }

  > button {
      margin-left: 20px;
    }
}
</style>
