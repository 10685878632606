export enum RouteName {
  HOME = "HOME",

  HOME_ORDINAT = "HOME_ORDINAT",
  HOME_ORDINAT_PLAN = "HOME_ORDINAT_PLAN",
  HOME_ORDINAT_CERT = "HOME_ORDINAT_CERT",
  HOME_ORDINAT_DOCS = "HOME_ORDINAT_DOCS",
  HOME_ORDINAT_FAQ = "HOME_ORDINAT_FAQ",
  HOME_ORDINAT_ADMISSION = "HOME_ORDINAT_ADMISSION",
  HOME_ORDINAT_REVIEWS = "HOME_ORDINAT_REVIEWS",

  HOME_ASPIRANT = "HOME_ASPIRANT",
  HOME_ASPIRANT_PLAN = "HOME_ASPIRANT_PLAN",
  HOME_ASPIRANT_CERT = "HOME_ASPIRANT_CERT",
  HOME_ASPIRANT_DOCS = "HOME_ASPIRANT_DOCS",
  HOME_ASPIRANT_FAQ = "HOME_ASPIRANT_FAQ",
  HOME_ASPIRANT_ADMISSION = "HOME_ASPIRANT_ADMISSION",
  HOME_ASPIRANT_REVIEWS = "HOME_ASPIRANT_REVIEWS",

  HOME_COURSES = "HOME_COURSES",
  HOME_COURSES_PLAN = "HOME_COURSES_PLAN",
  HOME_COURSES_CERT = "HOME_COURSES_CERT",
  HOME_COURSES_FAQ = "HOME_COURSES_FAQ",
  HOME_COURSES_ADMISSION = "HOME_COURSES_ADMISSION",
  HOME_COURSES_REVIEWS = "HOME_COURSES_REVIEWS",

  HOME_PROGRAMS_LIST = "HOME_PROGRAMS_LIST",

  HOME_MAGISTRACY = "HOME_MAGISTRACY",
  HOME_MAGISTRACY_ABOUT = "HOME_MAGISTRACY_ABOUT",
  HOME_MAGISTRACY_CONTACTS = "HOME_MAGISTRACY_CONTACTS",
  HOME_MAGISTRACY_DOCUMENTS = "HOME_MAGISTRACY_DOCUMENTS",
  HOME_MAGISTRACY__ENTRANCE = "HOME_MAGISTRACY_ENTRANCE",
  HOME_MAGISTRACY_PROGRAM = "HOME_MAGISTRACY_PROGRAM",

  CURATOR = "CURATOR",
  CURATOR_GENERAL = "CURATOR_GENERAL",
  CURATOR_PROGRAMS = "CURATOR_PROGRAMS",
  CURATOR_PROGRAMS_ARCHIVE = "CURATOR_PROGRAMS_ARCHIVE",
  CURATOR_PROGRAMS_CREATE = "CURATOR_PROGRAMS_CREATE",
  CURATOR_PROGRAMS_EDIT = "CURATOR_PROGRAMS_EDIT",
  CURATOR_PROGRAMS_USERS = "CURATOR_PROGRAMS_USERS",
  CURATOR_PROGRAMS_USERS_EDIT = "CURATOR_PROGRAMS_USERS_EDIT",
  CURATOR_PROGRAMS_LIBRARY = "CURATOR_PROGRAMS_LIBRARY",
  CURATOR_PROGRAMS_CALENDAR_PLAN = "CURATOR_PROGRAMS_CALENDAR_PLAN",
  CURATOR_PROGRAMS_GROUPS = "CURATOR_PROGRAMS_GROUPS",
  CURATOR_PROGRAMS_GROUPS_STATEMENTS = "CURATOR_PROGRAMS_GROUPS_STATEMENTS",
  CURATOR_PROGRAMS_GROUPS_ATTENDANCE = "CURATOR_PROGRAMS_GROUPS_ATTENDANCE",
  CURATOR_PROGRAMS_GROUPS_ATTENDANCE_TABLE = "CURATOR_PROGRAMS_GROUPS_ATTENDANCE_TABLE",
  CURATOR_PROGRAMS_GROUPS_PARTICIPANTS = "CURATOR_PROGRAMS_GROUPS_PARTICIPANTS",
  CURATOR_PROGRAMS_GROUPS_PARTICIPANTS_CONTROL = "CURATOR_PROGRAMS_GROUPS_PARTICIPANTS_CONTROL",
  CURATOR_PROGRAMS_GROUPS_PARTICIPANTS_CONTROL_USER = "CURATOR_PROGRAMS_GROUPS_PARTICIPANTS_CONTROL_USER",
  CURATOR_PROGRAMS_GROUPS_HOMEWORK = "CURATOR_PROGRAMS_GROUPS_HOMEWORK",
  CURATOR_PROGRAMS_GROUPS_HOMEWORK_EDIT = "CURATOR_PROGRAMS_GROUPS_HOMEWORK_EDIT",
  CURATOR_PROGRAMS_GROUPS_TESTING = "CURATOR_PROGRAMS_GROUPS_TESTING",
  CURATOR_PROGRAMS_GROUPS_TESTING_EDIT = "CURATOR_PROGRAMS_GROUPS_TESTING_EDIT",
  CURATOR_PROGRAMS_GROUPS_EXAMS = "CURATOR_PROGRAMS_GROUPS_EXAMS",
  CURATOR_PROGRAMS_GROUPS_EXAMS_EDIT = "CURATOR_PROGRAMS_GROUPS_EXAMS_EDIT",
  CURATOR_PROGRAMS_GROUPS_PERFOMANCE = "CURATOR_PROGRAMS_GROUPS_PERFOMANCE",
  CURATOR_PROGRAMS_EDUCATION_PLAN = "CURATOR_PROGRAMS_EDUCATION_PLAN",
  CURATOR_PROGRAMS_EDUCATION_PLAN_ATTESTATION = "CURATOR_PROGRAMS_EDUCATION_PLAN_ATTESTATION",
  CURATOR_PROGRAMS_EDUCATION_PLAN_TEACHERS = "CURATOR_PROGRAMS_EDUCATION_PLAN_TEACHERS",
  CURATOR_PROGRAMS_EDUCATION_PLAN_LESSONS = "CURATOR_PROGRAMS_EDUCATION_PLAN_LESSONS",
  CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE = "CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE",
  CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE_CREATE = "CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE_CREATE",
  CURATOR_CONTENT = "CURATOR_CONTENT",
  CURATOR_SCHEDULE = "CURATOR_SCHEDULE",

  NOTIFICATIONS = "NOTIFICATIONS",
  CHAT_NOTIFICATIONS = "CHAT_NOTIFICATIONS",

  PROFILE = "PROFILE",

  EDUCATION = "EDUCATION",
  HOMEWORKS = "HOMEWORKS",
  LESSON = "LESSON",
  TEST = "TEST",

  PORTFOLIO = "PORTFOLIO",

  EDUCATION_MATERIALS = "EDUCATION_MATERIALS",

  CHATS = "CHATS",

  INFORMATION = "INFORMATION",
  ADVERT = "ADVERT",
  DOCS = "DOCS",

  DPO = "DPO",
  PROFILE_DPO = "PROFILE_DPO",
  EDUCATION_DPO = "EDUCATION_DPO",
  LESSON_DPO = "LESSON_DPO",
  LECTION_DPO = "LECTION_DPO",
  HOMEWORK_DPO = "HOMEWORK_DPO",
  TEST_DPO = "TEST_DPO",
  COURSES_DPO = "COURSES_DPO",
  COURSES_ASPIRANT = "COURSES_ASPIRANT",

  TESTT = "TEST",
}
