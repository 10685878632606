<template>
  <CommonModal v-model="isVisible" :title="title">
    <template #content>
      <v-form class="form-container" @submit.prevent="submitForm">
        <CommonInput
          height="44px"
          placeholder="Название"
          label="Название"
          class="full-width"
          v-model="formModel.name"
        />

        <CommonTextEditor label="Описание" v-model="formModel.description" />

        <CommonSelect
          label="Формат"
          v-model="formModel.format"
          :options="formatOptions"
          placeholder="Выберите из списка"
        />

        <!-- Появляется, если выбран формат "Тест"в поле "Формат"
     TO DO: доработать условие -->
        <CommonSelect
          v-if="formModel.format === 'test'"
          label="Список тестов"
          v-model="formModel.test"
          :options="testOptions"
          placeholder="Выберите из списка"
        />

        <CommonSelect
          label="После темы/лекции"
          v-model="formModel.format"
          :options="lectureOptions"
          placeholder="Выберите из списка"
        />

        <CommonSelect
          label="Преподаватель"
          v-model="formModel.teacher"
          :options="teacherOptions"
          placeholder="Выберите из списка"
        />

        <CommonSelect
          label="Статус"
          v-model="formModel.status"
          :options="statuseOptions"
          placeholder="Выберите из списка"
        />

        <CommonInput
          type="number"
          height="44px"
          placeholder="Введите значение"
          label="Часы"
          v-model="formModel.hours"
        />

        <CommonButton
          class="form-container__button"
          type="submit"
          colorType="green-light"
          label="Сохранить"
        />
      </v-form>
    </template>
  </CommonModal>
</template>

<script lang="ts">
import { defineComponent, reactive, PropType, computed, watch } from "vue";
import type { initialData, ExamType } from "./types.ts";

import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonTextEditor } from "@/components/NewComponents/CommonTextEditor";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";
import { CommonModal } from "@/components/NewComponents/CommonModal/";

export default defineComponent({
  name: "ModalCreateExam",

  components: {
    CommonInput,
    CommonTextEditor,
    CommonButton,
    CommonSelect,
    CommonModal,
  },

  emits: ["update:modelValue", "submitForm"],

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },

    initialData: {
      type: Object as PropType<initialData>,
      default: () => ({}),
    }, // Поступающие данные для редактирования формы

    type: {
      type: String as PropType<ExamType>,
      required: true,
      default: "exam",
    },
  },

  setup(props, { emit }) {
    const isVisible = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    const title = computed(() => {
      const typeName = props.type === "exam" ? "экзамена" : "зачёта";
      return Object.keys(props.initialData).length > 0
        ? `Редактирование ${typeName}`
        : `Создание ${typeName}`;
    });

    const formModel = reactive({
      name: props.initialData.name || "",
      description: props.initialData.description || "",
      format: props.initialData.format || "",
      test: props.initialData.test || "",
      hours: props.initialData.hours || "",
      teacher: props.initialData.teacher || "",
      status: props.initialData.status || "",
    });

    watch(
      () => props.initialData,
      (newData) => {
        Object.assign(formModel, {
          name: newData.name || "",
          description: newData.description || "",
          format: newData.format || "",
          test: newData.test || "",
          hours: newData.hours || "",
          teacher: newData.teacher || "",
          status: newData.status || "",
        });
      },
      { deep: true }
    );

    //TO DO: доработать массивы под нужные типы
    const formatOptions = reactive<string[]>([]);
    const testOptions = reactive<string[]>([]);
    const teacherOptions = reactive<string[]>([]);
    const statuseOptions = reactive<string[]>([]);
    const lectureOptions = reactive<string[]>([]);

    const submitForm = (): void => {
      emit("submitForm", formModel);
    };

    return {
      formModel,
      formatOptions,
      teacherOptions,
      statuseOptions,
      lectureOptions,
      testOptions,
      isVisible,
      title,
      submitForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__button {
    width: 130px;
  }
}
</style>
