<template>
  <div class="lesson">
    <CommonComponentHeader title="Занятия" :items="breadcrumbsItems" />

    <div class="lesson__header">
      <CommonButton
        label="Домашнее задание"
        @click="showHomeworkModal = true"
        color-type="green-light"
      >
        <template #icon>
          <img src="./icons/plus.svg" />
        </template>
      </CommonButton>

      <CommonButton
        label="Практическое занятие"
        @click="showPracticeModal = true"
        color-type="green-light"
      >
        <template #icon>
          <img src="./icons/plus.svg" />
        </template>
      </CommonButton>

      <CommonButton
        label="Самостоятельная работа"
        @click="showIndIvidualWorkModal = true"
        color-type="green-light"
      >
        <template #icon>
          <img src="./icons/plus.svg" />
        </template>
      </CommonButton>

      <CommonButton
        label="Практика"
        @click="showIndIvidualWorkModal = true"
        color-type="green-light"
      >
        <template #icon>
          <img src="./icons/plus.svg" />
        </template>
      </CommonButton>
    </div>

    <div class="lesson__container">
      <CommonTable
        :head-list="headList"
        :body-list="bodyList"
        :actions="actions"
        @edit="editLesson"
        @view="opneLessonChat"
        @archive="deleteLesson"
      />
    </div>

    <ModalCreateHomework v-model="showHomeworkModal" />
    <ModalCreateIndIvidualWork v-model="showIndIvidualWorkModal" />
    <ModalCreatePractice v-model="showPracticeModal" />
    <CommonModal v-model="showChat" title="КОММЕНТАРИИ">
      <template #content>
        <CommonChat title="Домашнее задание №1" :chat="[]" />
      </template>
    </CommonModal>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";

import { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { ModalCreateHomework } from "@/modals/ModalCreateHomework";
import { ModalCreatePractice } from "@/modals/ModalCreatePractice";
import { ModalCreateIndIvidualWork } from "@/modals/ModalCreateIndIvidualWork";
import { CommonChat } from "@/components/NewComponents/CommonChat";
import { CommonModal } from "@/components/NewComponents/CommonModal";
import { generateBreadcrumbs } from "./constants";

export default defineComponent({
  name: "TeachersPage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonTable,
    ModalCreateHomework,
    ModalCreatePractice,
    ModalCreateIndIvidualWork,
    CommonChat,
    CommonModal,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const programId = computed(() => route.query?.program_id);

    const showHomeworkModal = ref<boolean>(false);
    const showPracticeModal = ref<boolean>(false);
    const showIndIvidualWorkModal = ref<boolean>(false);
    const showChat = ref<boolean>(false);

    const actions = ["edit", "comments", "delete"];

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const themeName = ref<string>(
      "Общественное здоровье и здравоохранение (Б1.О.1)"
    );

    const headList = reactive([
      "Название",
      "Тип работы",
      "Кол-во часов",
      "Преподаватель",
      "Статус",
      "Действия",
    ]);

    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "Домашнее задание №1" },
        { title: "ДЗ" },
        { title: "2" },
        { title: "Сидоров Сергей Сергеевич" },
        { title: "Опубликовано", hasStatus: true, statusColor: "green" },
        {
          title: "",
          hasAction: true,
          id: 1,
        },
      ],
      [
        { title: "Домашнее задание №2" },
        { title: "ДЗ" },
        { title: "3" },
        { title: "Петров Иван Сергеевич" },
        { title: "Опубликовано", hasStatus: true, statusColor: "green" },
        {
          title: "",
          hasAction: true,
          id: 2,
        },
      ],
      [
        { title: "Практическое занятие №1" },
        { title: "ПЗ" },
        { title: "2" },
        { title: "Иванов Дмитрий Анатольевич" },
        { title: "Черновик", hasStatus: true, statusColor: "orange" },
        {
          title: "",
          hasAction: true,
          id: 3,
        },
      ],
      [
        { title: "Самостоятельная работа №1" },
        { title: "СР" },
        { title: "10" },
        { title: "Сидоров Сергей Сергеевич" },
        { title: "На модерации", hasStatus: true, statusColor: "blue" },
        {
          title: "",
          hasAction: true,
          id: 4,
        },
      ],
    ]);

    const goToCreateLecture = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE_CREATE,
      });

    const editLesson = (id: number) => {
      console.log(`Редактирование аттестации с id: ${id}`);
    };

    const deleteLesson = (id: number) => {
      console.log(`Удаление аттестации с id: ${id}`);
    };

    const opneLessonChat = () => {
      showChat.value = true;
    };

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value, themeName.value)
    );

    return {
      actions,
      breadcrumbsItems,
      programId,
      headList,
      bodyList,
      programName,
      themeName,
      showHomeworkModal,
      showPracticeModal,
      showIndIvidualWorkModal,
      showChat,
      editLesson,
      deleteLesson,
      opneLessonChat,
      goToCreateLecture,
    };
  },
});
</script>

<style scoped lang="stylus">
.lesson {
  &__header {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
  }
}
</style>
