<template>
  <v-table>
    <thead>
      <tr class="commonTable__head">
        <th
          class="text-left commonTable__headItem"
          v-for="(item, i) in headList"
          :key="`head-item-${i}`"
        >
          {{ item }}
        </th>
      </tr>
    </thead>

    <tbody>
      <tr v-for="(item, i) in bodyList" :key="`body-item-${i}`">
        <td
          v-for="(ceil, index) in item"
          :key="`ceil-${i}-${index}`"
          class="commonTable__bodyItem"
        >
          <span
            v-if="
              !ceil.hasStatus &&
              !ceil.hasAction &&
              !ceil.hasDocument &&
              !ceil.hasLink &&
              !ceil.hasTags
            "
          >
            {{ ceil.title }}
          </span>

          <div v-if="ceil.hasStatus" class="commonTable__content">
            <div
              class="commonTable__circle"
              :style="{ backgroundColor: ceil.statusColor }"
            />
            {{ ceil.title }}
          </div>

          <div v-if="ceil.hasAction">
            <CommonDropdownActions
              :list="actions"
              @edit="$emit('edit', ceil.id)"
              @archive="$emit('archive')"
              @view="$emit('view')"
            />
          </div>

          <div v-if="ceil.hasDocument" class="commonTable__documentContainer">
            <div class="commonTable__document">
              <img
                class="commonTable__document__icon"
                src="./icons/document-text.svg"
              />
              <div>{{ formattedFileName(ceil.title) }}</div>
            </div>
            <span v-if="ceil.documentCount">({{ ceil.documentCount }})</span>
            <!-- <button
              v-if="ceil.documentCount > 1"
              @click="$emit('viewDocuments', ceil.id)"
            >
              Посмотреть
            </button> -->
          </div>

          <div v-if="ceil.hasLink">
            <a class="commonTable__link" :href="ceil.link">{{ ceil.title }}</a>
          </div>

          <div v-if="ceil.hasTags" class="commonTable__tags">
            <span
              v-for="(tag, index) in ceil.tags"
              :key="`tag-${index}`"
              :class="['commonTable__tag', { link: tag.routerName }]"
              @click="goToLink(tag.routerName)"
            >
              {{ tag.title }}
            </span>
          </div>

          <div
            v-if="ceil.badges && ceil.badges.length > 0"
            class="commonTable__tags"
          >
            <span v-for="(badge, index) in ceil.badges" :key="`badge-${index}`">
              <CommonBadge :text="badge.title" :color="badge.color" />
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </v-table>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { CommonTableProps } from "./types";
import { RouteName } from "@/constants";
import { router } from "@/router";
import { CommonDropdownActions } from "../CommonDropdownActions";
import { CommonBadge } from "../CommonBadge";

export default defineComponent({
  name: "CommonTable",

  components: {
    CommonDropdownActions,
    CommonBadge,
  },

  emits: ["edit", "archive", "view", "viewDocuments"],

  props: {
    headList: {
      type: Array as PropType<CommonTableProps["headList"]>,
      required: true,
    },
    bodyList: {
      type: Array as PropType<CommonTableProps["bodyList"]>,
      required: true,
    },
    actions: {
      type: Array as PropType<CommonTableProps["actions"]>,
      required: false,
    },
  },

  setup() {
    const formattedFileName = (fileName: string): string => {
      const extensionIndex = fileName.lastIndexOf(".");
      if (extensionIndex === -1) return fileName;

      const extension = fileName.slice(extensionIndex);
      const nameWithoutExtension = fileName.slice(0, extensionIndex);

      if (fileName.length <= 11) {
        return fileName;
      }

      const remainingLength = 11 - extension.length - 2;
      const startPart = nameWithoutExtension.slice(0, remainingLength);
      const endPart = nameWithoutExtension.slice(-1);

      return `${startPart}..${endPart}${extension}`;
    };

    const goToLink = (routerName?: string) => {
      if (!routerName) return;
      router.push({
        name: RouteName[routerName as keyof typeof RouteName],
      });
    };

    return {
      formattedFileName,
      goToLink,
    };
  },
});
</script>

<style lang="stylus" scoped>
.commonTable {
  &__head {
    background-color: $as-extra-light-bg;
  }

  &__headItem {
    color: $grey-sistems;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__bodyItem {
    font-weight: 500;
    color: $as-dark-grey;
  }

  &__content {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__circle {
    width: 8px;
    height: 8px;
    border-radius: 20px;
  }

  &__documentContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__document {
    display: flex;
    padding: 10px 16px;
    border-radius: 6px;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    border: 1px solid $magistracy-medium-grey;
  }

  &__document__icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  &__link {
    color: $as-dark-grey;
    font-weight: 400;
    font-size: 14px;
  }

  &__tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__tag {
    padding: 8px 12px;
    border-radius: 5px;
    border: 1px solid $magistracy-medium-grey;
    background-color: $grey-light;
    font-size: 14px;
    line-height: 16px;

    &.link {
      color: $as-active-green;
      cursor: pointer;

      &:hover {
        border-color: $as-green-light;
        background: $as-green-light;
      }
    }
  }

  &__tag.active {
    background-color: $blue-primary;
    color: white;
  }
}
</style>
