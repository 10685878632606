import { RouteName } from "@/constants";

export const generateBreadcrumbs = (programName: string, themeName: string) => [
  { title: "Программы", to: { name: RouteName.CURATOR_PROGRAMS } },
  { title: programName },
  {
    title: "Учебный план",
    to: { name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN },
  },
  { title: themeName },
  { title: "Темы/лекции" },
];
