import { RouteName } from "@/constants";

export const generateBreadcrumbs = () => [
  { title: "Программы", to: { name: RouteName.CURATOR_PROGRAMS } },
  {
    title: "Учебный план",
    to: { name: RouteName.CURATOR_PROGRAMS_GROUPS },
  },
  { title: "Аттестации" },
];
