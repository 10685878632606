<template>
  <div class="notificationBox">
    <div class="notification__date" v-if="date !== prevDate">{{ date }}</div>

    <div class="notification__wrap">
      <div
        class="notification__viewedBox"
        v-if="hasShowNotificationsConstructor"
      >
        <slot name="checkbox"></slot>
      </div>

      <div class="notification">
        <div class="notification__titleBox">
          <div class="notification__title">
            <div class="notification__hasNotViewed" v-if="!hasViewed"></div>

            {{ title }}
          </div>

          <div class="notification__time">{{ time }}</div>
        </div>

        <div class="notification__actions">
          <CommonDropdownActions
            :list="['processed', 'delete']"
            @delete="console.log(123)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CommonDropdownActions } from "@/components/NewComponents/CommonDropdownActions";

export default defineComponent({
  name: "NotificationsPage",

  components: {
    CommonDropdownActions,
  },

  props: {
    date: String || Number,
    prevDate: String,
    title: String,
    time: String,
    hasViewed: Boolean,
    hasChecked: Boolean,
    hasShowNotificationsConstructor: Boolean,
  },

  setup() {
    return {};
  },
});
</script>

<style lang="stylus" scoped>
.notification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__wrap {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px 20px;
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0 4px 12px 0 $color-shadow;
  }

  &__hasNotViewed {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: $as-active-orange;
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__date {
    margin-top: 30px;
    margin-bottom: 8px;
  }

  &__titleBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__time {
    color: $grey-sistems;
  }
}
</style>
