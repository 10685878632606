<template>
  <CommonFormContainer title="Документы">
    <div class="form-container">
      <div class="form-container__subttl">Паспорт</div>
      <CommonInput
        label="Серия"
        type="number"
        v-model="formModel.passport_series"
        placeholder="Введите значение"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        label="Номер"
        type="number"
        v-model="formModel.passport_number"
        placeholder="Введите значение"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        label="Кем выдан"
        v-model="formModel.issued_by"
        placeholder="Введите значение"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonDatePicker
        label="Дата выдачи"
        v-model="formModel.date"
        placeholder="00/00/000"
      />

      <div class="form-container__subttl">СНИЛС и ИНН</div>

      <CommonInput
        label="СНИЛС"
        type="number"
        v-model="formModel.snils"
        placeholder="Введите значение"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        label="ИНН"
        type="number"
        v-model="formModel.inn"
        placeholder="Введите значение"
        @update:model-value="$emit('updateValues', formModel)"
      />
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonDatePicker } from "@/components/NewComponents/CommonDatePicker";

export default defineComponent({
  name: "DocumentsForm",

  emits: ["updateValues"],

  props: ["form"],

  components: {
    CommonFormContainer,
    CommonInput,
    CommonDatePicker,
  },

  setup(props) {
    const formModel = reactive({
      passport_series: null,
      passport_number: null,
      issued_by: "",
      date: "",
      snils: null,
      inn: null,
    });

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  &__subttl {
    color: $as-black;
    width: 100%;
    position: relative;
    getFontSmall();
    line-height: 20px;
    font-weight: 500;
    grid-column: 1 / -1;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}
</style>
