<template>
  <CommonFormContainer :title="title">
    <div class="formMaterial">
      <slot v-if="$slots.default"></slot>
      <div v-else class="formMaterial__text">Материалы не добавлены</div>
      <button class="formMaterial__button" @click="openChat">
        <img src="./icons/chat.svg" alt="chat" />
      </button>
    </div>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";

export default defineComponent({
  name: "FormMaterial",

  emits: ["updateValues", "openChat"],

  props: {
    title: String,
    material: [Array, Object],
  },

  components: {
    CommonFormContainer,
  },

  setup(props, { emit }) {
    const formModel = reactive({
      file: "",
    });

    watch(
      () => props.material,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    const openChat = () => {
      emit("openChat");
    };

    return {
      formModel,
      openChat,
    };
  },
});
</script>

<style lang="stylus" scoped>
.formMaterial {
  display: flex;
  align-items: center;
  gap: 8px;

  slot {
    flex-grow: 1;
  }

  &__text {
    color: $as-grey-system;
    font-weight: 500;
  }

  &__button {
    width: 44px;
    height: 44px;
    border-radius: 5px;
    border: 1px solid $magistracy-medium-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    transition: background-color 0.2s, border 0.2s;
    flex-shrink: 0;
    margin-left: auto;

    &:hover {
      background-color: $as-green-light;
      border: none;
    }
  }
}
</style>
