import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonSelect = _resolveComponent("CommonSelect")!
  const _component_CommonList = _resolveComponent("CommonList")!
  const _component_CommonButton = _resolveComponent("CommonButton")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_CommonModal = _resolveComponent("CommonModal")!

  return (_openBlock(), _createBlock(_component_CommonModal, {
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isVisible) = $event)),
    title: "Изменение роли"
  }, {
    content: _withCtx(() => [
      _createVNode(_component_v_form, {
        class: "form-container",
        onSubmit: _withModifiers(_ctx.submitForm, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CommonSelect, {
            placeholder: "Выберите из списка",
            modelValue: _ctx.formModel.roles,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formModel.roles) = $event)),
            multiple: "",
            search: "",
            options: _ctx.roleOptions,
            label: "Роль"
          }, null, 8, ["modelValue", "options"]),
          (_ctx.formModel.roles && _ctx.formModel.roles.length > 0)
            ? (_openBlock(), _createBlock(_component_CommonList, {
                key: 0,
                label: "Выбранные роли",
                modelValue: _ctx.formModel.roles,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formModel.roles) = $event))
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true),
          _createVNode(_component_CommonButton, {
            class: "form-container__button",
            type: "submit",
            colorType: "green-light",
            label: "Сохранить",
            disabled: _ctx.isButtonDisabled
          }, null, 8, ["disabled"])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}