<template>
  <div class="formVideo">
    <CommonInput
      label="URL Rutube"
      v-model="formModel.url"
      placeholder="https://..."
      @update:model-value="$emit('updateValues', formModel)"
    />

    <CommonInput
      label="Заголовок"
      v-model="formModel.title"
      placeholder="Введите значение"
      @update:model-value="$emit('updateValues', formModel)"
    />

    <div class="formVideo__section">
      <CommonInput
        label="Время начала"
        v-model="formModel.time_start"
        placeholder="00:00:00"
        mask="##:##:##"
        @update:model-value="$emit('updateValues', formModel)"
      />

      <CommonInput
        label="Время окончания"
        v-model="formModel.time_end"
        placeholder="00:00:00"
        mask="##:##:##"
        @update:model-value="$emit('updateValues', formModel)"
      />
    </div>

    <CommonInput
      label="Текст"
      v-model="formModel.text"
      placeholder="Введите значение"
      @update:model-value="$emit('updateValues', formModel)"
    />

    <CommonTextEditor
      label="Конспект"
      v-model="formModel.abstract"
      @update:model-value="$emit('updateValues', formModel)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, reactive } from "vue";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { CommonTextEditor } from "@/components/NewComponents/CommonTextEditor";

export default defineComponent({
  name: "FormVideo",

  emits: ["updateValues"],

  props: ["form"],

  components: {
    CommonInput,
    CommonTextEditor,
  },

  setup(props) {
    const formModel = reactive({
      url: "",
      title: "",
      time_start: "",
      time_end: "",
      text: "",
      abstract: "",
    });

    watch(
      () => props.form,
      (newValue) => {
        Object.assign(formModel, newValue);
      },
      { deep: true }
    );

    return {
      formModel,
    };
  },
});
</script>

<style lang="stylus" scoped>
.formVideo {
  display: flex;
  row-gap: 16px;
  flex-direction: column;

  &__section {
    display: flex;
    column-gap: 16px;
  }
}
</style>
