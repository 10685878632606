<template>
  <span
    :class="['badge', color]"
    :style="{ backgroundColor: bgColor, color: textColor }"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  name: "CommonBadge",
  props: {
    text: {
      type: String,
      required: true,
    },
    color: String,
    bgColor: String,
    textColor: String,
  },
};
</script>

<style scoped lang="stylus">
.badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;

  &.blue {
    color: $as-blue;
    background: $as-light-blue;
  }

  &.orange {
    color: $as-gold;
    background: $as-light-gold;
  }

  &.red {
    color: $as-red-aspirant;
    background: $as-red-bg;
  }

  &.green {
    color: $as-green-magistr;
    background: $as-green-dark-bg;
  }
}
</style>
