<template>
  <div class="users">
    <CommonComponentHeader title="Пользователи" />

    <div class="users__header">
      <CommonInput
        backgroundColor="#fff"
        style="max-width: 620px"
        placeholder="Поиск"
        v-model="searchText"
      >
        <template #prefix>
          <svg class="users__header__icon">
            <use xlink:href="#search-icon--sprite" />
          </svg>
        </template>
      </CommonInput>

      <div class="users__header__btns">
        <CommonButton
          label="Фильтры"
          @click="showDrawer = true"
          color-type="white"
        >
          <template #post_icon>
            <img src="./icons/filter-search.svg" />
          </template>
        </CommonButton>
        <CommonButton
          label="Новый пользователь"
          @click="showDrawer = true"
          color-type="green-light"
        />
      </div>
    </div>

    <div class="users__container">
      <CommonTable
        :actions="actions"
        :head-list="headList"
        :body-list="bodyList"
      />
    </div>

    <CommonDrawer
      v-if="showDrawer"
      v-model="showDrawer"
      @update:modelValue="showDrawer = $event"
    >
      <template #default="{ closeDrawer }">
        <FilterForm @submit-form="closeDrawer" @close="showDrawer = false" />
      </template>
    </CommonDrawer>

    <ModalChangeRole v-model="showRoleModal" />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, ref } from "vue";
import { useRoute } from "vue-router";

import { BodyListTypes } from "@/components/NewComponents/CommonTable/types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonTable } from "@/components/NewComponents/CommonTable";
import { CommonInput } from "@/components/NewComponents/CommonInput";
import { FilterForm } from "./components/FilterForm";
import { ModalChangeRole } from "@/modals/ModalChangeRole";
import { CommonDrawer } from "@/components/NewComponents/CommonDrawer";

export default defineComponent({
  name: "AttendancePage",

  components: {
    CommonComponentHeader,
    CommonButton,
    CommonTable,
    CommonInput,
    CommonDrawer,
    FilterForm,
    ModalChangeRole,
  },

  setup() {
    const route = useRoute();
    const programId = computed(() => route.query?.program_id);

    const showDrawer = ref<boolean>(false);
    const searchText = ref<string>("");
    const showRoleModal = ref<boolean>(false);

    const actions = ["edit", "copy", "delete"];

    //Тестовые данные
    const programName = ref<string>(
      "Математическое и компьютерное моделирование"
    );
    const groupName = ref<string>("Группа 1");

    const headList = reactive(["ФИО", "E-MAIL", "Роль", "Действия"]);

    const bodyList = reactive<BodyListTypes[][]>([
      [
        { title: "Иванов Иван Иванович" },
        {
          title: "ivanov@gmail.com",
          hasLink: true,
          link: "mailto:ivanov@gmail.com",
        },
        {
          title: "",
          badges: [{ id: 1, title: "Пользователь", color: "blue" }],
        },
        { title: "", hasAction: true, id: 1 },
      ],
      [
        { title: "Григорьев Аркадий Петрович" },
        {
          title: "ivanov@gmail.com",
          hasLink: true,
          link: "mailto:ivanov@gmail.com",
        },
        {
          title: "",
          badges: [{ id: 2, title: "Обучающийся", color: "orange" }],
        },
        { title: "", hasAction: true, id: 2 },
      ],
      [
        { title: "Буренков Иосиф Витальевич" },
        {
          title: "ivanov@gmail.com",
          hasLink: true,
          link: "mailto:ivanov@gmail.com",
        },
        {
          title: "",
          badges: [
            { id: 3, title: "Куратор", color: "red" },
            { id: 4, title: "Обучающийся", color: "orange" },
          ],
        },
        { title: "", hasAction: true, id: 3 },
      ],
      [
        { title: "Зошов Анатолий Викторович" },
        {
          title: "ivanov@gmail.com",
          hasLink: true,
          link: "mailto:ivanov@gmail.com",
        },
        { title: "", badges: [{ id: 5, title: "Куратор", color: "red" }] },
        { title: "", hasAction: true, id: 4 },
      ],
      [
        { title: "Миронова Анастасия Игоревна" },
        {
          title: "ivanov@gmail.com",
          hasLink: true,
          link: "mailto:ivanov@gmail.com",
        },
        {
          title: "",
          badges: [{ id: 6, title: "Преподаватель", color: "green" }],
        },
        { title: "", hasAction: true, id: 5 },
      ],
      [
        { title: "Киримова Анастасия Валерьевна" },
        {
          title: "ivanov@gmail.com",
          hasLink: true,
          link: "mailto:ivanov@gmail.com",
        },
        {
          title: "",
          badges: [
            { id: 7, title: "Преподаватель", color: "green" },
            { id: 8, title: "Куратор", color: "red" },
          ],
        },
        { title: "", hasAction: true, id: 6 },
      ],
    ]);

    return {
      actions,
      programId,
      headList,
      bodyList,
      programName,
      groupName,
      searchText,
      showDrawer,
      showRoleModal,
    };
  },
});
</script>

<style scoped lang="stylus">
.users {

  &__header {
    display: flex;
    justify-content:space-between;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;

    &__btns {
      display: flex;
      gap: 8px;
    }

    &__icon {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
