<template>
  <CommonFormContainer title="Фильтры" class="form">
    <button class="form__close" @click="closeForm">
      <img src="./icons/close.svg" />
    </button>
    <v-form @submit.prevent="submitForm" class="form__container">
      <CommonSelect
        label="Дисциплина"
        v-model="formModel.discipline"
        :options="disciplineOptions"
        placeholder="Выберите из списка"
      />

      <CommonSelect
        label="Курс"
        v-model="formModel.course"
        :options="courseOptions"
        placeholder="Выберите из списка"
      />

      <CommonSelect
        label="Семестр"
        v-model="formModel.semester"
        :options="semesterOptions"
        placeholder="Выберите из списка"
      />

      <CommonSelect
        label="Преподаватель"
        v-model="formModel.teacher"
        :options="teachersOptions"
        placeholder="Выберите из списка"
      />

      <div class="form__container__btns">
        <CommonButton
          type="submit"
          :disabled="isButtonDisabled"
          class="form__container__btns__submit"
          colorType="green-light"
          label="Применить фильтры"
        />

        <button class="form__container__btns__delete" @click="clearForm">
          <img src="./icons/trash.svg" alt="chat" />
        </button>
      </div>
    </v-form>
  </CommonFormContainer>
</template>

<script lang="ts">
import { defineComponent, reactive, computed } from "vue";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonSelect } from "@/components/NewComponents/CommonSelect";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";

export default defineComponent({
  name: "FilterForm",

  components: {
    CommonButton,
    CommonSelect,
    CommonFormContainer,
  },

  emits: ["submitForm", "close"],

  setup(props, { emit }) {
    const formModel = reactive({
      teacher: "" as string,
      course: "" as string,
      semester: "" as string,
      discipline: "" as string,
    });

    //TO DO: доработать массивы под нужные типы
    const disciplineOptions = reactive<string[]>([]);
    const courseOptions = reactive<string[]>([]);
    const semesterOptions = reactive<string[]>([]);
    const teachersOptions = reactive<string[]>([]);

    const submitForm = (): void => {
      emit("submitForm", formModel);
    };

    const closeForm = (): void => {
      emit("close");
    };

    const isButtonDisabled = computed(() => {
      return Object.entries(formModel).some(([key, value]) => {
        return value === "" || value === null;
      });
    });

    const clearForm = (): void => {
      Object.assign(formModel, {
        teacher: "",
        course: "",
        semester: "",
        discipline: "",
      });
    };

    return {
      formModel,
      disciplineOptions,
      teachersOptions,
      semesterOptions,
      courseOptions,
      isButtonDisabled,
      submitForm,
      clearForm,
      closeForm,
    };
  },
});
</script>

<style lang="stylus" scoped>
.form {
  width: 460px;
  height: 100%;

  &__close {
    position: absolute;
    right: 20px;
    top: 16px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;

    &__btns {
      display: flex;
      width: 100%;
      gap: 16px;
      flex-direction: row;
      margin-top: auto;

      &__submit {
        width: 100%;
      }

      &__delete {
        width: 44px;
        height: 44px;
        border-radius: 5px;
        border: 1px solid $as-dark-grey;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        transition: background-color 0.2s, border 0.2s;
        flex-shrink: 0;
        margin-left: auto;

        &:hover {
          background-color: $as-green-light;
          border: none;
        }
      }
    }
  }
}

.commonFormContainer {
  border: none !important;
}
</style>
