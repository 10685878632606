<template>
  <div class="educationPlan">
    <CommonComponentHeader title="Учебный план" :items="breadcrumbsItems" />

    <div v-if="!syllabusList.length" class="educationPlan__header">
      <CommonFileUpload
        button-text="Выберите файл с учебным планом"
        v-model="formModel.file"
        backgroundColor="#fff"
      />
      <CommonButton label="Загрузить" color-type="green-light" />
    </div>

    <div v-else class="educationPlan__header">
      <CommonDatePicker
        paddingLeft
        background-color="#fff"
        label="Дата старта"
        v-model="formModel.dateFrom"
      />
      <CommonDatePicker
        paddingLeft
        background-color="#fff"
        label="Дата окончания"
        v-model="formModel.dateTo"
      />
    </div>

    <div class="educationPlan__container">
      <CommonFormContainer
        v-for="semester in syllabusList"
        :key="semester.id"
        :title="semester.name"
      >
        <ModuleContainer
          v-for="module in semester.modules"
          :key="module.id"
          :title="module.title"
        >
          <div class="educationPlan__container__module">
            <CommonFormContainer
              class="educationPlan__container__module__section"
              title="Темы/Лекции"
            >
              <div class="educationPlan__container__module__section__text">
                <div class="bold">Всего:</div>
                <div class="light">Часов:</div>
              </div>
              <CommonButton
                outline
                label="Перейти"
                @click="goToTheme"
                color-type="green-light"
              />
            </CommonFormContainer>

            <CommonFormContainer
              class="educationPlan__container__module__section"
              title="Аттестации"
            >
              <div class="educationPlan__container__module__section__text">
                <div class="bold">Экзамены:</div>
                <div class="bold">Зачеты:</div>
                <div class="bold">Тесты:</div>
              </div>
              <CommonButton
                outline
                label="Перейти"
                @click="goToAttestation"
                color-type="green-light"
              />
            </CommonFormContainer>

            <CommonFormContainer
              class="educationPlan__container__module__section"
              title="Преподаватели"
            >
              <div class="educationPlan__container__module__section__text">
                <div class="bold">Назначено:</div>
              </div>
              <CommonButton
                outline
                label="Перейти"
                @click="goToTeachers"
                color-type="green-light"
              />
            </CommonFormContainer>

            <CommonFormContainer
              class="educationPlan__container__module__section"
              title="Занятия"
            >
              <div class="educationPlan__container__module__section__text">
                <div class="bold">Практические занятия:</div>
                <div class="bold">Домашние задания:</div>
                <div class="light">Часов на практические занятия:</div>
                <div class="light">Часов на самостоятельную работу:</div>
              </div>
              <CommonButton
                outline
                @click="goToLessons"
                label="Перейти"
                color-type="green-light"
              />
            </CommonFormContainer>
          </div>
        </ModuleContainer>
        <button class="educationPlan__container__plus">
          <img src="./icons/plus.svg" />
        </button>
      </CommonFormContainer>
      <CommonButton label="Добавить семестр" color-type="green-light" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, onMounted } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import { RouteName } from "@/constants";
import type { ProgramData } from "./types";
import { CommonComponentHeader } from "@/components/NewComponents/CommonComponentHeader";
import { CommonFormContainer } from "@/components/NewComponents/CommonFormContainer";
import { CommonButton } from "@/components/NewComponents/CommonButton";
import { CommonFileUpload } from "@/components/NewComponents/CommonFileUpload";
import { CommonDatePicker } from "@/components/NewComponents/CommonDatePicker";
import { ModuleContainer } from "./components/ModuleContainer";
import { generateBreadcrumbs } from "./constants";

// Заглушка с тестовыми данными для одного семестра
const mockData: ProgramData = {
  name: "Тестовая программа",
  syllabus: [
    {
      id: 1,
      name: "1 семестр",
      modules: [
        {
          id: 101,
          title: "Основы программирования",
          sections: [
            {
              id: 1001,
              title: "Темы/Лекции",
              texts: [
                { id: 1, value: "Введение в язык", style: "bold" },
                { id: 2, value: "Часов: 10", style: "light" },
              ],
            },
            {
              id: 1002,
              title: "Аттестации",
              texts: [
                { id: 3, value: "Экзамен: 1", style: "bold" },
                { id: 4, value: "Зачеты: 2", style: "bold" },
                { id: 5, value: "Тесты: 3", style: "bold" },
              ],
            },
            {
              id: 1003,
              title: "Преподаватели",
              texts: [{ id: 6, value: "Назначено: 2", style: "bold" }],
            },
            {
              id: 1004,
              title: "Занятия",
              texts: [
                { id: 7, value: "Практика: 5 занятий", style: "bold" },
                { id: 8, value: "Домашние задания: 3", style: "bold" },
                { id: 9, value: "Часов на практику: 15", style: "light" },
                {
                  id: 10,
                  value: "Часов на самостоятельную работу: 10",
                  style: "light",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default defineComponent({
  name: "EducationPlan",

  components: {
    CommonComponentHeader,
    CommonFormContainer,
    CommonButton,
    CommonFileUpload,
    CommonDatePicker,
    ModuleContainer,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const programId = computed(() => route.query?.program_id);

    const formModel = reactive({
      file: [],
      dateFrom: new Date(),
      dateTo: new Date(),
    });

    const programName = computed(
      () => programData.value?.name || "Загрузка..."
    );
    const syllabusList = computed(() => programData.value?.syllabus || []);

    const programData = reactive<{ value: ProgramData | null }>({
      value: null,
    });

    const fetchProgramData = async () => {
      try {
        const response = await axios.get(`/api/programs/${programId.value}`);

        if (!response.data || !response.data.syllabus?.length) {
          console.warn("API вернул пустые данные. Используем тестовые данные.");
          programData.value = mockData;
        } else {
          programData.value = response.data;
        }
      } catch (error) {
        console.error("Ошибка загрузки программы:", error);
        programData.value = mockData;
      }
    };

    const goToTheme = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_LECTURE,
      });

    const goToAttestation = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_ATTESTATION,
      });

    const goToTeachers = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_TEACHERS,
      });

    const goToLessons = () =>
      router.push({
        name: RouteName.CURATOR_PROGRAMS_EDUCATION_PLAN_LESSONS,
      });

    onMounted(fetchProgramData);

    const breadcrumbsItems = computed(() =>
      generateBreadcrumbs(programName.value)
    );

    return {
      breadcrumbsItems,
      formModel,
      programName,
      syllabusList,
      goToTheme,
      goToAttestation,
      goToTeachers,
      goToLessons,
    };
  },
});
</script>

<style lang="stylus" scoped>
.educationPlan {
  &__header {
    display: flex;
    gap: 16px;
    border-bottom: 1px solid $magistracy-medium-grey;
    padding: 16px 20px;
  }

  &__container {
    padding: 20px;

    &__module {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;

      &__section {
        display: flex;
        flex-direction: column;
        height: 100%;

        &__text {
          margin-bottom: 20px;

          .bold {
            color: $dpo-black;
            font-weight: 500;
            getFontSmall()
            line-height: 20px;
          }

          .light {
            color: $as-dark-grey;
            font-size: 14px;
          }
        }

        button {
          margin-top: auto;
          width: 112px;
        }
      }
    }

    &__plus {
      width: 24px;
    }

    > button {
      margin-top: 20px;
    }
  }
}
</style>
